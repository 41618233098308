import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class PrivacyPolicy extends React.Component {
	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<div className="header-bg">
						<h1>เกี่ยวกับ Bridgestone</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">เกี่ยวกับ Bridgestone</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div style={{paddingTop: 25}}>
							<h3>เกี่ยวกับ Bridgestone</h3>
							<p>
								บริดจสโตนกรุ๊ปในประเทศไทย คือการประสานพลังของบริษัทในเครือ บริดจสโตน คอร์ปอเรชั่น
								ตั้งแต่การจัดการด้านวัตถุดิบ คือยางพารา เส้นลวดเหล็กกล้า ผง Carbon Black
								รวมทั้งการควบคุมคุณภาพของเครื่องจักรสำหรับการผลิตยางรถยนต์
								และแม่พิมพ์ที่ได้จากโรงงานผลิตแม่พิมพ์
								เข้าสู่กระบวนการผลิตที่ได้รับการศึกษาวิจัยจากศูนย์วิจัยทางเทคนิค รวมถึงสนามทดสอบ
								ผลิตภัณฑ์มาตรฐานสากล ผลิตสินค้าด้วยมาตรฐานที่เหนือกว่าของ บริดจสโตน
								ด้วยความมุ่งหวังที่จะให้การดำเนินธุรกิจในประเทศไทยเป็นศูนย์กลางในการดำเนินธุรกิจของบริดจสโตนในภูมิภาคเอเชียแฟซิฟิกได้อย่างสมบูรณ์
							</p>
							<h3>Serving Society with Superior Quality</h3>
							<p>
								“รับใช้สังคมด้วยคุณภาพที่เหนือกว่า”
								<br/><br/>
								คือพันธกิจของบริดจสโตนที่ยึดมั่นเป็นหนึ่งเดียวกันทั่วโลก
								เราริเริ่มสร้างสรรค์สิ่งใหม่สู่สังคมอย่างไม่หยุดยั้ง
								เพื่อสร้างความพึงพอใจให้กับลูกค้าและผู้ใช้บริการสูงสุดด้วย “คุณภาพ”
							</p>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}