import React from 'react';
import RelationshipAddress from '../modal_form/RelationshipAddress';
import ChangePassword from "../modal_form/ChangePassword";
import EditName from "../modal_form/EditName";
import EditEmail from "../modal_form/EditEmail";
// import EditPhone from "../modal_form/EditPhone";
import EditBillingManageMyAccount from "../modal_form/EditBillingManageMyAccount";
import AddEmail from "../modal_form/AddEmail";
import {Global2} from "./Global";
import {Modal} from "./Modal";

export default class ManageAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			edit_shipping: {
				title: false,
				type: false,
				selected_id: false,
			},
		}
	}

	componentDidMount() {
		this.fetchRelationShipAddress();
		this.fetchRelationShipAccount();
	}

	fetchRelationShipAddress() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/relationship_address',
			done: (response) => {
				this.setState({
					relationship_addresses: response.relationship_address,
					is_loading: false,
				});
			}
		})
	}

	fetchRelationShipAccount() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/me',
			data: {
				request_data_relationship_group: 1
			},
			done: (response) => {
				this.setState({
					relationship_account_selected_index: response.relationship_account.relationship.relationship_branch.length > 0 ? 0 : false,
					relationship_account: response.relationship_account || false,
					is_loading: false,
				}, () => {
					Global2.updateUserData(response);
				});
			}
		})
	}


	getDefaultRelationshipAddress() {
		if (!this.state.relationship_addresses) {
			return false;
		}
		let _relationship_address = false;
		this.state.relationship_addresses.forEach((relationship_address) => {
			if (relationship_address.is_default) {
				_relationship_address = relationship_address;
			}
		});
		return _relationship_address;
	}

	render() {
		return (
			<>
				<div className="ui manage-my-account space">
					<div className="ui segments horizontal grid" style={{height: '100%', marginTop: 0, width: '100%'}}>
						<div className="ui segment custom-segment sixteen wide tablet five wide computer column">
							<h3 className="ui header dividing">ข้อมูลส่วนตัว</h3>
							{
								this.state.relationship_account
								&& this.state.relationship_account.relationship
								&& this.state.relationship_account.relationship.name ?

									<h4 className="ui header" style={{marginTop: 5, marginBottom: 5}}>
										{this.state.relationship_account.relationship.name}
										<span className="edit-btn">
                                            <span onClick={() => {
	                                            window.Global.reactToDom(
		                                            window.$('body'),
		                                            <EditName
			                                            data={{
				                                            name: this.state.relationship_account.relationship.name,
				                                            tax_number: this.state.relationship_account.relationship.tax_number,
			                                            }}
			                                            onSuccess={(response) => {
				                                            this.setState({
					                                            relationship_account: response.relationship_account
				                                            }, () => {
					                                            Global2.updateUserData(response);
					                                            window.location.reload();
				                                            })
			                                            }}
		                                            />
	                                            )
                                            }}>แก้ไข</span>
                                         </span>
									</h4>
									: null
							}
							{
								this.state.relationship_account
								&& this.state.relationship_account.relationship
								&& this.state.relationship_account.relationship.tax_number ?
									<div style={{marginBottom: 5}}>
										<strong>เลขประจำตัวผู้เสียภาษี</strong> {this.state.relationship_account.relationship.tax_number}
									</div>
									: null
							}
							{
								this.state.relationship_account
								&& this.state.relationship_account
								&& this.state.relationship_account.email
								&& this.state.relationship_account.is_verify_email
									?
									<div style={{marginBottom: 5}}>
										<strong>อีเมล์</strong> {this.state.relationship_account.email}
										<span className="edit-btn">
	                                            <span onClick={() => {
		                                            window.Global.reactToDom(
			                                            window.$('body'),
			                                            <EditEmail
				                                            email={this.state.relationship_account.email}
				                                            onChanged={() => {
					                                            // console.log("Changed");?
				                                            }}
			                                            />
		                                            )
	                                            }}>แก้ไข</span>
	                                        </span>
										<br/>
										<div className="ui label green">ยืนยันอีเมล์แล้ว</div>
									</div>
									:
									null
							}
							{
								this.state.relationship_account
								&& this.state.relationship_account
								&& this.state.relationship_account.email
								&& !this.state.relationship_account.is_verify_email ?
									<div style={{marginBottom: 5}}>
										โปรดตรวจสอบอีเมล {this.state.relationship_account.email} เพื่อยืนยันอีเมล์
									</div> : null
							}
							{
								this.state.relationship_account
								&& this.state.relationship_account
								&& !this.state.relationship_account.email ?
									<div style={{marginBottom: 5}}>
										ไม่มีอีเมล์
									</div>
									: null
							}
							{
								(this.state.relationship_account
									&& !this.state.relationship_account.email) ||
								(this.state.relationship_account
									&& this.state.relationship_account.email
									&& !this.state.relationship_account.is_verify_email) ?
									<button className={"ui button basic"} style={{margin: '5px 0'}}
									        onClick={() => {
										        window.Global.reactToDom(
											        window.$('body'),
											        <AddEmail
												        title={
													        (this.state.relationship_account
														        && this.state.relationship_account.email
														        && !this.state.relationship_account.is_verify_email) ?
														        'แก้ไขอีเมล์' : false
												        }
												        email={this.state.relationship_account.email}
												        onChanged={(relationship_account) => {
													        this.setState({
														        relationship_account: relationship_account,
													        })
												        }}
												        fetchRelationShipAccount={() => {
													        this.fetchRelationShipAccount();
												        }}
											        />
										        )
									        }}
									>
										{
											(this.state.relationship_account
												&& !this.state.relationship_account.email) ?
												'เพิ่มอีเมล์'
												:
												'แก้ไขอีเมล์'
										}

									</button>
									: null
							}

							<div style={{marginBottom: 5}}>
								<strong>เบอร์โทรศัพท์ </strong>
								{this.state.relationship_account && this.state.relationship_account.phone_number ? this.state.relationship_account.phone_number : 'ไม่มีเบอร์โทรศัพท์'}
								{/*<span className="edit-btn">*/}
								{/*	<span onClick={() => {*/}
								{/*        window.Global.reactToDom(*/}
								{/*            window.$('body'),*/}
								{/*            <EditPhone*/}
								{/*                phone_number={this.state.relationship_account.phone_number}*/}
								{/*                onChanged={() => {*/}
								{/*                    console.log("Changed");*/}
								{/*                }}*/}
								{/*            />*/}
								{/*        )*/}
								{/*    }}>แก้ไข</span>*/}
								{/*</span>*/}
							</div>
							<div style={{marginTop: 5, marginBottom: 5}}>
								<button type={"button"}
								        ref={(ref) => {
									        this.changepwd_btn = ref
								        }}
								        className={"ui button basic"}
								        onClick={() => {
									        window.$(this.changepwd_btn).addClass('loading');
									        window.$(this.changepwd_btn).attr('disabled', true);
									        window.Global.reactToDom(
										        window.$('body'),
										        <ChangePassword
											        onSuccess={() => {
												        window.$(this.changepwd_btn).attr('disabled', false);
												        window.$(this.changepwd_btn).removeClass('loading');
											        }}
											        onDeny={() => {
												        window.$(this.changepwd_btn).attr('disabled', false);
												        window.$(this.changepwd_btn).removeClass('loading');
											        }}
										        />
									        )
								        }}
								>
									เปลี่ยนรหัสผ่าน
								</button>
							</div>
							{
								this.state.relationship_account
								&& this.state.relationship_account.relationship
								&& this.state.relationship_account.relationship.code ?
									<div style={{marginBottom: 5}}>
										<strong>เลขที่สมาชิก</strong> {this.state.relationship_account.relationship.code}
									</div>
									: null
							}
							{
								this.state.relationship_account
								&& this.state.relationship_account.relationship
								&& this.state.relationship_account.relationship.relationship_group
								&& this.state.relationship_account.relationship.relationship_group.length > 0 ?
									<div>
										<strong>กลุ่ม</strong>
										<ul className={"ui bulleted list"}
										    style={{paddingLeft: 10, marginTop: 5}}>
											{this.state.relationship_account.relationship.relationship_group.map((_relationship_group, _relationship_group_i) => {
												return (
													<li key={_relationship_group_i}
													    style={_relationship_group_i === 0 ? {padding: 0} : {paddingTop: 5}}
													>
														{_relationship_group.name}
													</li>
												);
											})}
										</ul>
									</div>
									: null
							}
						</div>
						<div className="ui segment sixteen wide tablet six wide computer column">
							<h3 className="ui header dividing">
								ที่อยู่จัดส่ง
								<span className="edit-btn">
												<span onClick={() => {
													window.Global.reactToDom(
														window.$('body'),

														<div>
															<Modal className="ui modal"
															       closable={false}
															       disable_close_button={true}
															       onShowToSetupForm={($modal) => {

															       }}
															       onDeny={() => {
																       window.location.reload();
															       }}
															>
																<RelationshipAddress
																	mode={'list'}
																	onDeny={(relationship_addresses) => {
																		this.setState({
																			relationship_addresses: relationship_addresses
																		})
																	}}
																/>
															</Modal>
														</div>
													)
												}}>แก้ไข</span>
											</span>
							</h3>
							{
								this.getDefaultRelationshipAddress()
									?
									<div>
										<h4 className="ui header" style={{marginTop: 5, marginBottom: 5}}>
											{this.getDefaultRelationshipAddress().name}
										</h4>
										<div>
											{this.getDefaultRelationshipAddress().address_line_1}
										</div>
										{
											this.getDefaultRelationshipAddress().phone_number
												?
												<div>
													โทร {this.getDefaultRelationshipAddress().phone_number.replace('+66', '0')}
												</div>
												: null
										}
									</div>
									: null
							}
						</div>
						<div className="ui segment sixteen wide tablet five wide computer column">
							<h3 className="ui dividing header">
								ที่อยู่ใบเสร็จรับเงิน
								<span className="edit-btn">
                                    	<span onClick={() => {
		                                    window.Global.reactToDom(
			                                    window.$('body'),
			                                    <EditBillingManageMyAccount
				                                    relationship_account={this.state.relationship_account}
				                                    onSuccess={(response) => {
					                                    this.setState({
						                                    relationship_account: response.relationship_account
					                                    }, () => {
						                                    Global2.updateUserData(response);
					                                    })
				                                    }}
			                                    />
		                                    )
	                                    }}>แก้ไข</span>
                                    </span>
							</h3>
							{
								this.state.relationship_account
								&& this.state.relationship_account.relationship.relationship_address
								&& this.state.relationship_account.relationship.relationship_address.length
									?
									<>
										<h4 className="ui header" style={{marginTop: 5, marginBottom: 5}}>
											{this.state.relationship_account.relationship.relationship_address[0].name}
										</h4>
										<div>โทร {this.state.relationship_account.relationship.relationship_address[0].phone_number.replace('+66', '0')}</div>
										<div>{this.state.relationship_account.relationship.relationship_address[0].address_line_1}</div>
									</>
									: <div className={'py-2'}>ไม่มีข้อมูลที่อยู่ใบเสร็จรับเงิน</div>
							}
						</div>
					</div>
				</div>

			</>
		);
	}
}
