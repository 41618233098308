import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";
import ProductTabs from "../component/ProductTabs";
import Banner from "../component/Banner";
import Slider from "../Slider";
import PopUp from "../include/PopUp";
// import PopUp from "../include/PopUp";

export default class Index extends React.Component {
	componentDidMount() {
		let diffDays = 0;
		if (window.Global.getCookie('popup')) {
			let date_1 = new Date(JSON.parse(window.Global.getCookie('popup')).date);
			let date_2 = new Date();
			let diffTime = Math.abs(date_2 - date_1);
			diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		}
		// console.log(diffDays);
		if (diffDays > 1
			|| window.Global.getCookie('popup') === ''
			|| !window.Global.getCookie('popup')
			|| this.props.is_close_website
		) {
			if (this.props.e_commerce.site_config.image_popup_file_upload.length
				|| this.props.is_close_website
			) {
				window.Global.reactToDom(
					window.$('body'),
					<PopUp
						{...this.props}
					/>
				);
			}
		}
	}


	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<Slider/>
					<ProductTabs/>
					<Banner/>
				</div>
				<Footer/>
			</>
		);
	}
}
