import React from 'react';
import {Modal} from "../component/Modal";

export default class AddEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			otp_form: false,
			message_form: false,
		};
	}


	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       closable={false}
				       onShowToSetupForm={($modal) => {
					       $modal.find('form').form2('setup');
					       $modal.find('.close.icon').remove();
					       $modal.find('form').form2('submit', {
						       method: 'POST',
						       url: '/api/v1/e-commerce/new_email',
						       beforeSent: (object) => {
							       // console.log(object);
							       return object;
						       },
						       loadingButton: $modal.find('.button.approve'),
						       onSuccess: (response) => {
							       if (response.success) {
								       this.setState({
									       message_form: response.message
								       });
							       }
						       }
					       });
				       }}
				>
					<div className="header">
						<div className="flex flex-row">
							{this.props.title ? this.props.title : 'เพิ่มอีเมล์'}
						</div>
					</div>
					<div className="content">
						<form className="ui form">
							<div className="field">
								<label>กรอกอีเมล์</label>
								<input type={"text"} name={"email"} placeholder={"กรอกอีเมล"}
								       disabled={this.state.message_form}/>
							</div>
							<div className="field GoogleRecaptcha_root"/>
							<div className={"ui error message"}/>
							{
								this.state.message_form
									?
									<div className="ui icon message">
										<i className="paper plane icon"/>
										<div className="content">
											<div className="header">
												{this.state.message_form}
											</div>
										</div>
									</div>
									: null
							}
						</form>
					</div>
					<div className="actions">
						<button className="ui deny basic button"
						        type={"button"}>
							{
								!this.state.message_form
									?
									'ยกเลิก'
									: 'ปิด'
							}
						</button>
						{
							!this.state.message_form ?
								<button className="ui approve blue button "
								        type={"button"}
								        style={{marginLeft: 15}}
								>
									ตกลง
								</button>
								: null
						}
					</div>
				</Modal>
			</div>
		);
	}
}
