import React from 'react';
import Header from "../include/Header";
import Cart from "../component/Cart";
import Footer from "../include/Footer";
import {Global2} from "../component/Global";

export default class CartPage extends React.Component {
	constructor() {
		super();
		this.state = {};
		this.Header = React.createRef();
	}

	componentDidMount() {
		if (!Global2.getMyProfile()) {
			window.location = window.location.origin;
		}
	}

	render() {
		return (
			<>
				<Header hide_cart={true}
				        {...this.props}
				        ref={this.Header}
				/>
				<div className="body-content">
					{/*BREADCRUMB*/}
					<div className="header-bg">
						<h1>ตะกร้าสินค้า</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<a className="section" href={window.location.origin + '/shop'}>รายการสินค้า</a>
								<i className="right chevron icon divider"/>
								<div className="active section">ตะกร้าสินค้า</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0}}/>
					<Cart theme={'fluid'} show_cart={true} Header={this.Header}/>
				</div>
				<Footer/>
			</>
		);
	}
}