import React from 'react';
import {Modal} from "../component/Modal";

export default class PopUp extends React.Component {
	constructor() {
		super();
		this.state = {
			is_stop_today: false,
		}
	}

	onClosePopUp() {
		// console.log(this.state.is_stop_today);
		if (this.state.is_stop_today) {
			let _now = {};
			_now.date = new Date();
			// console.log(_now);
			window.Global.createCookie('popup', JSON.stringify(_now), 1);
		}
	}

	render() {
		return (
			<div>
				<Modal className="ui modal"
				       style={{width: 'auto'}}
				       closable={false}
				       disable_close_button={this.props.is_close_website}
				       onShowToSetupForm={($modal) => {
					       this.$modal = $modal;

					       this.$modal.closest('.ui.modals')
						       .addClass('scroll start-xs');
					       this.$modal
						       .css({
							       marginTop: '3rem'
						       });
				       }}
				       onHide={() => {
					       this.onClosePopUp();
				       }}
				>
					<div className="content" style={{position: 'relative'}}>
						{
							this.props.e_commerce.site_config.image_popup_file_upload.map((image_popup_file_upload, i) => {
								return (
									<>
										<img key={i}
										     src={image_popup_file_upload.url}
										     alt={"popup"}
										     style={{maxWidth: '100%', margin: 'auto', display: 'block'}}
										/>
									</>
								)
							})
						}
						{
							this.props.e_commerce.site_config.image_popup_file_upload.length === 0
							&& this.props.is_close_website
							&&
							<div className="ui message info">ขออภัย เนื่องจากเว็ปไซด์กำลังปิดปรับปรุง</div>
						}
					</div>
					{
						!this.props.is_close_website
						&&
						<div className="actions">
							<div className={"flex flex-row middle-xs"}>
								<div>
									<div className="ui checkbox"
									     ref={(ref) => {
										     window.$(ref).checkbox({
											     onChecked: () => {
												     this.setState({
													     is_stop_today: true,
												     })
											     },
											     onUnchecked: () => {
												     this.setState({
													     is_stop_today: false,
												     })
											     }
										     })
									     }}
									>
										<input type="checkbox" tabIndex="0" id={"stop-to-day"} className="hidden"/>
										<label id={"stop-to-day"}>หยุดแสดงสำหรับวันนี้</label>
									</div>
								</div>
								<div className={"right floated"}>
									<button className={"ui button popup-btn black"}
									        onClick={() => {
										        this.onClosePopUp();
										        this.$modal.modal('hide');
									        }}
									>
										ปิด
									</button>
								</div>
							</div>
						</div>
					}
				</Modal>
			</div>
		);
	}
}
