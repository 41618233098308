import React from 'react';
import CartFunction from "../Function";

export default class Card extends React.Component {

	productGallery() {
		let product_image = [];
		if (this.props.product_display) {
			this.props.product_display.product_display_link.forEach((product_display_link, index) => {
				if (product_display_link.product) {
					product_image.push(product_display_link.product.product_image_file_upload)
				}

			});
		}
		return product_image;
	}

	renderTheme1() {
		return (
			<>
				<div className="card">
					<a className="card_image"
					   href={window.location.origin + '/product?product_id=' + this.props.product_display.id}>
						{/*<div className="card_fev">*/}
						{/*<i className="heart outline icon"/>*/}
						{/*</div>*/}
						{/*<div className="ui label">*/}
						{/*สินค้า*/}
						{/*</div>*/}
						{
							this.productGallery().length > 0 ?
								<img alt={"Product"}
								     src={this.productGallery()[0].url}/>
								: null
						}
					</a>
					<div className="card_details">
						{
							this.props.product_display.product_display_using_filter
							&& this.props.product_display.product_display_using_filter.length > 0 ?
								<a className="card_category_title"
								   href={window.location.origin + '/shop?filter_id=' + this.props.product_display.product_display_using_filter[0].filter.root_parent_filter_id}>
									{this.props.product_display.product_display_using_filter[0].filter.name}
								</a>
								: null
						}
						<a className="card_pd_title"
						   href={window.location.origin + '/product?product_id=' + this.props.product_display.id}>
							{this.props.product_display.name}
						</a>
						<div className="card_price brown">
							<span
								className="discount-price">{CartFunction.getPrice(this.props.product_display.product_display_link)}</span>
							{/*<span className="was-price">฿{(1000).format(2, 3, {pretty: false}).toString()}</span>*/}
						</div>
						<ProductType product_display={this.props.product_display}/>
					</div>
				</div>
			</>
		);
	}

	renderThemeHorizontal() {

		return (
			<>
				<div className="ui grid horizontal_card">
					<div className="six wide mobile four wide tablet  four wide computer column">
						<a className="card_image"
						   href={window.location.origin + '/product?product_id=' + this.props.product_display.id}
						>
							{/*<div className="card_fev">*/}
							{/*<i className="heart outline icon"/>*/}
							{/*</div>*/}
							{/*<div className="ui label">*/}
							{/*สินค้า*/}
							{/*</div>*/}
							{
								this.productGallery().length > 0 ?
									<img alt={"Product"}
									     src={this.productGallery()[0].url}/>
									: null
							}
						</a>
					</div>
					<div className="ten wide mobile twelve wide tablet twelve wide computer column  flex row middle-xs">
						<div className="card_details text-left">
							{
								this.props.product_display.product_display_using_filter
								&& this.props.product_display.product_display_using_filter.length > 0 ?
									<a className="card_category_title"
									   href={window.location.origin + '/shop?filter_id=' + this.props.product_display.product_display_using_filter[0].filter.root_parent_filter_id}>
										{this.props.product_display.product_display_using_filter[0].filter.name}
									</a>
									: null
							}
							<a className="card_pd_title brown"
							   href={window.location.origin + '/product?product_id=' + this.props.product_display.id}
							>
								{this.props.product_display.name}
							</a>
							<div className="product-description">
								{this.props.product_display.product_display_link[0].product.description}
							</div>
							<div className="card_price brown">
								<span
									className="discount-price">{CartFunction.getPrice(this.props.product_display.product_display_link)}</span>
							</div>
							<ProductType product_display={this.props.product_display}/>
						</div>
					</div>
				</div>
				<div className="ui divider" style={{marginBottom: 0}}/>
			</>
		);
	};

	render() {
		if (!this.props.product_display) {
			return null;
		}

		if (this.props.theme === 'horizontal') {
			return this.renderThemeHorizontal();
		}
		return this.renderTheme1();
	}
}

class ProductType extends React.Component {
	render() {
		let product_gallery = [];
		if (this.props.product_display) {
			this.props.product_display.product_display_link.forEach((product_display_link) => {
				if (product_display_link.product
					&& product_display_link.product.product_gallery_file_upload
					&& product_display_link.product.product_gallery_file_upload.length > 0) {
					product_display_link.product.product_gallery_file_upload.forEach((product_gallery_file_upload) => {
						product_gallery.push(product_gallery_file_upload);
					})
				}
			});
		}
		return (
			<>
				<div className="product-type-selected">
					{
						product_gallery.length > 0 ?
							product_gallery.forEach((image, index) => {
								if (index < 3) {
									return (
										<div
											className={index === 0 ? "product-thumbnail selected" : "product-thumbnail"}
											key={index}
										>
											<img alt={"Product thumbnail"} src={image.file_upload.url}/>
										</div>
									);
								}
							}) : null
					}
				</div>
			</>
		);
	}
}
