import React from 'react';

export default class ManageMenu extends React.Component {
	title() {
		if (this.props.page === 'manage-my-account') {
			return 'บัญชีของฉัน';
		}
		if (this.props.page === 'my-purchase') {
			return 'การซื้อของฉัน';
		}
	}

	render() {
		return (
			<>
				<button className={"ui button fluid brown"} id={"show-responsive-menu"}><i
					className={"icon bars"}/> Menu
				</button>
				<div className="manage manage-responsive-menu">
					{/*<h3 className={"ui header dividing"}>*/}
					{/*{this.title()}*/}
					{/*</h3>*/}
					<div className="ui vertical menu secondary pointing" style={{width: '100%'}}>
						<a className={window.segment[1] === 'manage-my-account' ? "active item" : "item"}
						   href={window.location.origin + '/manage-my-account'}>
							<i className={"icon id card"}
							   style={{float: 'none', marginLeft: 0, marginRight: 10, fontSize: '1.2em'}}/>
							บัญชีของฉัน
						</a>
						<a className={window.segment[1] === 'my-purchase' || window.segment[1] === 'order-detail' ? "active item" : "item"}
						   href={window.location.origin + '/my-purchase?status=all'}>
							<i className={"icon shopping bag"}
							   style={{float: 'none', marginLeft: 0, marginRight: 10, fontSize: '1.2em'}}/>
							การซื้อของฉัน
						</a>
					</div>

				</div>
			</>
		);
	}
}