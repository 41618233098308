import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show_message: false,
		};
	}

	render() {
		return (
			<>
				<Header hide_cart={false} {...this.props}/>
				<div className="body-content" style={{paddingBottom: 0}}>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">
									ลืมรหัสผ่าน
								</div>
							</div>
						</div>
					</div>
					<div className={"login-bg"}
					     style={{backgroundImage: "url('./login-bg.jpg')", backgroundColor: '#f4f4f4'}}>
						<div className="ui container">
							<div className={"ui grid"}>
								<div className={"row centered"}>
									<div
										className={"sixteen wide mobile eleven wide tablet six wide computer column"}
										style={this.state.show_message ? {paddingBottom: 75} : null}
									>
										{
											this.state.show_message ?
												<div className="ui icon message green">
													<i className="paper plane icon"/>
													<div className="content">
														<div className="header">
															{this.state.show_message}
														</div>
													</div>
												</div>
												:
												<div className={"ui segment sign-in"}>
													<form className={"ui large form"}
													      ref={(ref) => {
														      window.$(ref).form2('setup');
														      window.$(ref).form2('submit', {
															      method: 'POST',
															      url: '/api/v1/e-commerce/request-forgot-password',
															      beforeSent: (object) => {
																      return object;
															      },
															      loadingButton: window.$(ref).find('.button.approve'),
															      onSuccess: (response) => {
																      if (response.success) {
																	      window.Global.messageTop({
																		      type: 'positive',
																		      message: response.message
																	      });
																	      // this.setState({show_message: response.message});
																	      let settime = window.setTimeout(() => {
																		      window.location = window.origin + '/';
																		      clearTimeout(settime);
																	      }, 2000)
																      }
															      }
														      })
													      }}
													>
														<div className="field">
															{/*<h3 className={"ui header dividing"}>ลืมรหัสผ่าน</h3>*/}
															<p>ระบุชื่อผู้ใช้งานหรืออีเมล์เพื่อตั้งค่ารหัสผ่านใหม่</p>
														</div>
														<div className="field">
															<label>ชื่อผู้ใช้งาน/อีเมล์</label>
															<input type={"text"} name={"username"}
															       disabled={this.state.show_message}/>
														</div>
														<div className="field GoogleRecaptcha_root"/>
														<div className="ui error message"/>
														<div className={"field flex row middle-xs wrap"}>
															<div className="flex-shrink">
																<button
																	className="ui button brown right labeled icon approve"
																	type="submit">
																	<i className="icon arrow right"/>
																	ยืนยัน
																</button>
															</div>
														</div>
													</form>
												</div>
										}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}