import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";
import ManageMenu from "../component/ManageMenu";
import ManageAccount from "../component/ManageAccount";
import {Global2} from "../component/Global";

export default class ManageMyAccount extends React.Component {
	constructor() {
		super();
		this.state = {}
	}

	componentDidMount() {
		if (!Global2.getMyProfile()) {
			window.location = window.location.origin;
		}
	}

	render() {
		return (
			<>
				<Header hide_cart={false} {...this.props}/>
				<div className="body-content">
					{/*BREADCRUMB*/}
					{/*<div className="header-bg computer-only">*/}
					{/*<h1>บัญชีของฉัน</h1>*/}
					{/*</div>*/}
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">บัญชีของฉัน</div>
							</div>
						</div>
					</div>
					<div className="ui container">
						<div className="ui grid">
							<div className="row">
								<div className="sixteen wide mobile four wide tablet three wide computer column">
									<ManageMenu/>
								</div>
								<div className="sixteen wide mobile twelve wide tablet thirteen wide computer column">
									<ManageAccount shipping={this.state.shipping}/>
								</div>
							</div>
						</div>

					</div>
				</div>
				<Footer/>
			</>
		);
	}
}