import React from 'react';
import MessageConfirm from './MessageComfirm';
import CartFunction from '../Function';

export default class Cart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_loading_cart: true,
            sale_order_via_e_commerce: false,
            message_form: false,
            shipping: [
                {title: 'Free Shipping', price: 0},
                {title: 'Standart', price: 10},
                {title: 'Express', price: 20},
            ]
        };
    }

    componentDidMount() {
        this.fetchCart()
    }

    fetchCart() {
        window.Global.ajax({
            method: 'GET',
            url: '/api/v1/sale_order/cart',
            done: (response) => {
                let sale_order_via_e_commerce = response.sale_order_via_e_commerce;
                if (response.sale_order_via_e_commerce) {
                    response.sale_order_via_e_commerce.sale_order.sale_order_item.forEach((sale_order_item, sale_order_item_index) => {
                        sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_item_index].selected = true;
                    });
                }
                this.setState({
                    sale_order_via_e_commerce: sale_order_via_e_commerce,
                    is_loading_cart: false,
                })
            }
        });
    }

    subTotal() {
        let total = 0;
        if (this.state.sale_order_via_e_commerce && this.state.sale_order_via_e_commerce.sale_order) {
            total = this.state.sale_order_via_e_commerce.sale_order.summary.total_price_before_discount;
        }
        return total
    }

    grandTotal() {
        let total = 0;
        if (this.state.sale_order_via_e_commerce && this.state.sale_order_via_e_commerce.sale_order) {
            this.state.sale_order_via_e_commerce.sale_order.sale_order_item.forEach((sale_order_item) => {
                let qty = parseFloat(sale_order_item.sale_order_item_product_display_link._quantity);
                if (sale_order_item.discount_price > 0) {
                    total = total + (qty * sale_order_item.discount)
                } else {
                    total = total + (qty * sale_order_item.price)
                }
            });
        }
        return total
    }

    checkedGrandTotal() {
        let total = 0;
        if (this.state.sale_order_via_e_commerce && this.state.sale_order_via_e_commerce.sale_order) {
            total = this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price;
        }
        return total
    }

    updateQuantity(type, sale_order_items_index, event) {
        let _event = event;
        // window.$(_event).find('button#minus,button#plus,input').attr('disabled', true);
        window.$('button#minus,button#plus,input').attr('disabled', true);
        let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
        let amount = parseFloat(sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index].sale_order_item_product_display_link._quantity);
        if (type === 'plus') {
            amount++;
        }
        if (type === 'minus') {
            amount--;
        }
        if (amount === 0) {
            window.Global.reactToDom(
                window.$('body'),
                <MessageConfirm
                    item_name={this.state.sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index].product.name}
                    onApprove={() => {
                        this.removeItem(this.state.sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index], this['remove_button_' + sale_order_items_index]);
                    }}
                    onDeny={() => {
                        // this.cancleMessage();
                        window.$(this['remove_button_' + sale_order_items_index]).attr('disabled', false);
                        window.$(this['remove_button_' + sale_order_items_index]).removeClass('loading');
                    }}
                />
            )
        }
        if (amount > sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index].sale_order_item_product_display_link.product_display_link.product.ready_to_sale.maximum_quantity) {
            amount = sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index].sale_order_item_product_display_link.product_display_link.product.ready_to_sale.maximum_quantity;
        }
        sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index].sale_order_item_product_display_link._quantity = amount < 1 ? 1 : amount;
        this.setState({
            sale_order_via_e_commerce: sale_order_via_e_commerce,
        }, () => {
            this.updateToAPI(this.state.sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index], _event);
        })
    }

    updateToAPI(sale_order_items, event) {
        // console.log(sale_order_items);
        let data = {};
        data.sale_order_item = {};
        data.sale_order_item.sale_order_item_product_display_link = {};
        data.sale_order_item.sale_order_item_product_display_link.product_display_link_id = sale_order_items.sale_order_item_product_display_link.product_display_link_id;
        data.sale_order_item.sale_order_item_product_display_link._quantity = sale_order_items.sale_order_item_product_display_link._quantity;
        // console.log(data);
        window.Global.ajax({
            method: 'POST',
            url: '/api/v1/sale_order/manage_item_cart',
            data: data,
            done: (response) => {
                if (response.success) {
                    let sale_order_via_e_commerce = response.sale_order_via_e_commerce;
                    if (response.sale_order_via_e_commerce.sale_order) {
                        response.sale_order_via_e_commerce.sale_order.sale_order_item.forEach((sale_order_item, sale_order_item_index) => {
                            sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_item_index].selected = true;
                        });
                    }
                    this.setState({
                        sale_order_via_e_commerce: sale_order_via_e_commerce
                    },()=> {
                        this.props.Header.current.getSaleOrderViaEcomerce(response.sale_order_via_e_commerce);
                    });
                }
                window.$('button,input').attr('disabled', false);
            }
        });
    }

    removeItem(sale_order_items, event) {
        if (!sale_order_items) {
            return;
        }
        let data = {};
        data.sale_order_item = {};
        data.sale_order_item.sale_order_item_product_display_link = {};
        data.sale_order_item.sale_order_item_product_display_link.product_display_link_id = sale_order_items.sale_order_item_product_display_link.product_display_link_id;
        data.sale_order_item.sale_order_item_product_display_link._quantity = 0;
        // console.log(data);
        window.Global.ajax({
            method: 'POST',
            url: '/api/v1/sale_order/manage_item_cart',
            data: data,
            done: (response) => {
                let sale_order_via_e_commerce = response.sale_order_via_e_commerce;
                response.sale_order_via_e_commerce.sale_order.sale_order_item.forEach((sale_order_item, sale_order_item_index) => {
                    sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_item_index].selected = true;
                });
                this.props.Header.current.getSaleOrderViaEcomerce(response.sale_order_via_e_commerce);
                this.setState({
                    sale_order_via_e_commerce: sale_order_via_e_commerce
                }, () => {
                    window.$(event).removeClass('loading');
                    window.$(event).parent().find('button,input').attr('disabled', false);
                });

            }
        });
    }

    // renderFixed() {
    //     return (
    //         <>
    //             <div className={this.props.show_cart ? "cart fixed_right active" : "cart fixed_right"}>
    //                 <div className="cart-info">
    //                     <div className="ui middle aligned divided list selection">
    //                         {
    //                             this.state.cart_items
    //                             && this.state.cart_items.length > 0 ?
    //                                 this.state.cart_items.map((cart_item, cart_item_i) => {
    //                                     return (
    //                                         <div className="item" key={cart_item_i}>
    //                                             <div className="right floated content">
    //                                                 <button className="circular ui icon  button"
    //                                                         onClick={() => {
    //                                                             let _confirm = window.confirm('ยืนยันการลบ');
    //                                                             // console.log(_confirm);
    //                                                         }}
    //                                                 >
    //                                                     <i className="icon trash alternate"/>
    //                                                 </button>
    //                                             </div>
    //                                             <img className="ui avatar image" src={"./product-1-1.jpg"}
    //                                                  alt={"Product"}/>
    //                                             <div className="content">
    //                                                 <div className="header" style={{marginBottom: 5}}>
    //                                                     <div className="ui tiny header"
    //                                                          style={{margin: 0}}>
    //                                                         {cart_item.product_name}
    //                                                         <b> (
    //                                                             {
    //                                                                 cart_item.discount_price ?
    //                                                                     '฿' + (cart_item.discount_price.format(2, 3, {pretty: true}).toString())
    //                                                                     :
    //                                                                     '฿' + (cart_item.price.format(2, 3, {pretty: true}).toString())
    //                                                             }
    //                                                             )
    //                                                         </b>
    //                                                         {
    //                                                             cart_item.options
    //                                                             && cart_item.options.length > 0 ?
    //                                                                 <div
    //                                                                     className="sub header custom-sub-header">
    //                                                                     {
    //                                                                         cart_item.options.map((option, option_i) => {
    //                                                                             return (
    //                                                                                 <span
    //                                                                                     key={option_i}>{option.title} : {option.selected}</span>
    //                                                                             );
    //                                                                         })
    //                                                                     }
    //                                                                 </div>
    //                                                                 : null
    //                                                         }
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="description">
    //                                                     <div>
    //                                                         <b>จำนวน :</b> {cart_item.qty}
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     )
    //                                 })
    //                                 : null
    //                         }
    //                     </div>
    //                     <div className="ui divider"/>
    //                     <div className="cart-footer">
    //                         <div className="content" style={{marginBottom: 10}}>
    //                             <strong>รวมทั้งหมด </strong>
    //                             <div
    //                                 className="description"> ฿{this.grandTotal().format(2, 3, {pretty: true}).toString()}
    //                             </div>
    //                         </div>
    //                         <div className={"cart-btn"}>
    //                             <a className="ui labeled icon button small"
    //                                href={window.location.origin + '/?p=cart'}
    //                             >
    //                                 <i className={"icon shopping cart"}/>
    //                                 ตะกร้าสินค้า
    //                             </a>
    //                             <a className="ui right labeled icon button green small"
    //                                href={window.location.origin + '/?p=checkout'}
    //                             >
    //                                 <i className={"icon arrow right"}/>
    //                                 ชำระเงิน
    //                             </a>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             {
    //                 this.state.message_form
    //             }
    //         </>
    //     );
    // }

    render() {
        if (this.props.theme && this.props.theme === 'fixed_right') {
            // return this.renderFixed();
            return null;
        }
        return (
            <>
                <div className="cart-body" ref={(ref) => this.$parent = ref}>
                    <div className="ui container">
                        <div className="ui grid">
                            <div className="sixteen wide mobile sixteen wide tablet eleven wide computer column">
                                <div className={"cart"}>
                                    <table className="ui very basic table">
                                        <thead>
                                        <tr>
                                            <th style={{paddingLeft: 12, paddingRight: 12}}>
                                                สินค้า
                                            </th>
                                            <th>ราคาต่อชิ้น</th>
                                            <th>จำนวน</th>
                                            <th>ราคารวม</th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.is_loading_cart ?
                                                <tr>
                                                    <td colSpan="5">
                                                        <div className="flex center-xs">
                                                            <div>
                                                                <div className="ui active mini inline loader"
                                                                     style={{marginRight: 10}}/>
                                                                กำลังโหลดข้อมูล
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            !this.state.is_loading_cart
                                            && ((this.state.sale_order_via_e_commerce
                                                    &&this.state.sale_order_via_e_commerce.sale_order
                                                    && this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length === 0)
                                                || !this.state.sale_order_via_e_commerce
                                            ) ?
                                                <tr>
                                                    <td colSpan="4">
                                                        <div className="flex center-xs">
                                                            <div>
                                                                <h2 className={"ui header"}>ไม่มีสินค้าในตะกร้า</h2>
                                                                <button className="ui button basic"
                                                                        onClick={(e) => {
                                                                            window.$(e).addClass('loading');
                                                                            window.location.href = window.location.origin + '/?p=shop'

                                                                        }}
                                                                >
                                                                    <i className="cart alternate icon"/> เลือกสินค้า
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            this.state.sale_order_via_e_commerce.sale_order ?
                                                this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, sale_order_items_index) => {
                                                    return (
                                                        <tr key={sale_order_items_index} style={{position: 'relative'}}>
                                                            <td>
                                                                <div className={"cart-pd"}>
                                                                    {
                                                                        sale_order_item.sale_order_item_product_display_link.product_display_link.product.product_image_file_upload ?
                                                                            <div className="cart-image">
                                                                                <img
                                                                                    src={sale_order_item.sale_order_item_product_display_link.product_display_link.product.product_image_file_upload.url}
                                                                                    alt={"Product"}/>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    <div className="ui tiny header"
                                                                         style={{margin: 0, width: '100%'}}>
                                                                        <div><a href={"/product?product_id=" + sale_order_item.sale_order_item_product_display_link.product_display_link.product_display_id}>{sale_order_item.product.name}</a></div>
                                                                        <div
                                                                            className="sub header custom-sub-header">
                                                                            {
                                                                                CartFunction.showProductDisplayLinkDetail(sale_order_item.sale_order_item_product_display_link.product_display_link.product_display_link_detail)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    parseFloat(sale_order_item.discount) !== 0
                                                                       ?
                                                                        <div>
                                                                            <span className={"show-on-mobile"}>ราคาต่อชิ้น</span>{'฿' + (sale_order_item.summary.price_after_discount.format(2, 3, {pretty: true}).toString())}
                                                                            <div
                                                                                className={"discount-line"}>{'฿' + (sale_order_item.price.format(2, 3, {pretty: true}).toString())}</div>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div className={"show-on-mobile"}>ราคาต่อชิ้น</div>
                                                                            ฿ {(sale_order_item.price.format(2, 3, {pretty: true}).toString())}
                                                                        </div>
                                                                }
                                                            </td>
                                                            <td ref={(ref) => this['tr_' + sale_order_items_index] = ref}>
                                                                <div className="field qty-input">
                                                                    <div className="ui left action input">
                                                                        <button className="ui icon button brown"
                                                                                id={"minus"}
                                                                                onClick={() => {
                                                                                    this.updateQuantity('minus', sale_order_items_index, this['tr_' + sale_order_items_index]);
                                                                                }}
                                                                        >
                                                                            <i className="minus icon"/>
                                                                        </button>
                                                                        <input type="text" name="quantity"
                                                                               placeholder="Quantity"
                                                                               value={parseFloat(sale_order_item.sale_order_item_product_display_link._quantity) || 0}
                                                                               onChange={(e) => {
                                                                                   let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
                                                                                   let qty = e.target.value;
                                                                                   if (qty > sale_order_item.sale_order_item_product_display_link.product_display_link.product.ready_to_sale.maximum_quantity) {
                                                                                       qty = sale_order_item.sale_order_item_product_display_link.product_display_link.product.ready_to_sale.maximum_quantity;
                                                                                   }
                                                                                   if (qty < 1) {
                                                                                       qty = 1;
                                                                                   }
                                                                                   sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index].sale_order_item_product_display_link._quantity = qty;
                                                                                   this.setState({
                                                                                       sale_order_via_e_commerce: sale_order_via_e_commerce,
                                                                                   })
                                                                               }}
                                                                               onBlur={() => {
                                                                                   window.$(this['tr_' + sale_order_items_index]).find('button,input').attr('disabled', true);
                                                                                   this.updateToAPI(this.state.sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index], this['tr_' + sale_order_items_index]);
                                                                               }}
                                                                        />
                                                                        <button className="ui right icon button brown"
                                                                                id={"plus"}
                                                                                onClick={() => {
                                                                                    this.updateQuantity('plus', sale_order_items_index, this['tr_' + sale_order_items_index]);
                                                                                }}
                                                                        >
                                                                            <i className="plus icon"/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={"show-on-mobile"}>ราคารวม </div>
                                                                {'฿' + (sale_order_item.summary.total_net_price.format(2, 3, {pretty: true}).toString())}
                                                            </td>
                                                            <td>
                                                                <button className="circular ui icon button basic remove-item-button"
                                                                        ref={(ref) => this['remove_button_' + sale_order_items_index] = ref}
                                                                        onClick={(event) => {
                                                                            window.$(this['remove_button_' + sale_order_items_index]).addClass('loading');
                                                                            window.$(this['remove_button_' + sale_order_items_index]).attr('disabled', true);
                                                                            window.Global.reactToDom(
                                                                                window.$('body'),
                                                                                <MessageConfirm
                                                                                    item_name={sale_order_item.product.name}
                                                                                    onApprove={() => {
                                                                                        this.removeItem(this.state.sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index], this['remove_button_' + sale_order_items_index]);
                                                                                    }}
                                                                                    onDeny={() => {
                                                                                        // this.cancleMessage();
                                                                                        window.$(this['remove_button_' + sale_order_items_index]).attr('disabled', false);
                                                                                        window.$(this['remove_button_' + sale_order_items_index]).removeClass('loading');
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }}
                                                                >
                                                                    <i className="icon trash alternate"/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : null
                                        }
                                        </tbody>
                                    </table>
                                    <div className="ui divider" style={{marginTop: 0}}/>
                                    {/*<div className="ui divider" style={{marginTop: 0}}/>*/}
                                    {/*<div className={"cart-summary"}>*/}
                                    {/*    <div className="content" style={{marginBottom: 10}}>*/}
                                    {/*        <div className="ui action input">*/}
                                    {/*            <input type="text" placeholder="Coupon Code"/>*/}
                                    {/*            <button className="ui button icon">*/}
                                    {/*                <i className={"icon arrow right"}/>*/}
                                    {/*            </button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {
                                        !this.state.is_loading_cart
                                        && (this.state.sale_order_via_e_commerce.sale_order
                                            && this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length > 0
                                        ) ?
                                            <button className="ui button brown "
                                                    onClick={() => {
                                                        window.location.href = '/shop'
                                                    }}
                                            >
                                                <i className="sync alternate icon"/> เลือกสินค้าเพิ่ม
                                            </button>
                                            : null
                                    }

                                </div>
                            </div>


                            <div className="sixteen wide mobile sixteen wide tablet five wide computer column">
                                <div className="ui segment">
                                    <h3 className={"ui header dividing"}>ยอดรวมสินค้า</h3>
                                    <div className={"cart-summary"}>
                                        <div className="content">
                                            <div className="ui action input">
                                                <input type="text" placeholder="รหัสส่วนลด" disabled={true}/>
                                                <button className="ui button icon" disabled={true}>
                                                    <i className={"icon arrow right"}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ui divider"/>
                                    {
                                        this.state.sale_order_via_e_commerce
                                        && this.state.sale_order_via_e_commerce.sale_order
                                        && this.state.sale_order_via_e_commerce.sale_order.summary ?
                                            <>
                                                <div className={"flex flex-row"}>
                                                    <div>ราคารวม :</div>
                                                    <div className={"right floated"}>
                                                        <div>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_price_exclude_vat.format(2, 3, {pretty: false}).toString()} </div>
                                                    </div>
                                                </div>
                                                <div className={"flex flex-row"}>
                                                    <div>ภาษีมูลค่าเพิ่ม :</div>
                                                    <div className={"right floated"}>
                                                        <div>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_tax_price.format(2, 3, {pretty: false}).toString()} </div>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                    {
                                        this.state.sale_order_via_e_commerce
                                        && this.state.sale_order_via_e_commerce.sale_order
                                        && this.state.sale_order_via_e_commerce.sale_order.summary ?
                                            <>
                                                <div className="ui divider"/>
                                                <div className={"flex flex-row cart-total-price cart-total"}
                                                     style={{marginBottom: 15}}
                                                >
                                                    <h3>รวมทั้งหมด :</h3>
                                                    <div className={"right floated"}>
                                                        <h3>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price.format(2, 3, {pretty: false}).toString()} </h3>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                    <button className="ui button brown fluid"
                                            onClick={() => {
                                                window.location.href = '/checkout';
                                            }}
                                            disabled={!this.state.sale_order_via_e_commerce || (this.state.sale_order_via_e_commerce.sale_order && this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length === 0)}
                                    >
                                        ดำเนินการชำระเงิน
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.message_form}
            </>
        );
    }
}

