import React from 'react';

export default class Banner extends React.Component {
	render() {
		return (
			<>
				<div className="banner-section">
					<div className="ui container">
						<div className="ui grid">
							<div className="ui row three column">
								<div className="ui column">
									<div className="banner-block">
										<div className="banner-info">
											<img src={'./payment-secure.png'} alt={"logo"}/>
											<div className="title">
												ปลอดภัย
											</div>
											<div>
												ชำระเงินอย่างปลอดภัย<br/>
												ด้วยระบบที่ได้รับการรับรอง
											</div>
										</div>
									</div>
								</div>
								<div className="ui column">
									<div className="banner-block">
										<div className="banner-info">
											<img src={'./free-shipping.png'} alt={"logo"}/>
											<div className="title">
												บริการจัดส่ง
											</div>
											<div>
												สินค้าถึงมืออย่างปลอดภัย <br/>
												ด้วย SCG Express
											</div>
										</div>
									</div>
								</div>
								<div className="ui column">
									<div className="banner-block">
										<div className="banner-info">
											<img src={'./support.png'} alt={"logo"}/>
											<div className="title">
												สนับสนุน
											</div>
											<div>
												หากมีข้อสงสัย สามารถสอบถาม<br/>
												ข้อมูลเพิ่มเติมได้เสมอ
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}