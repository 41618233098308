import React from 'react';
import {Modal} from "./Modal";

export default class MessageConfirm extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        //console.log(this.props);
        return (
            <div>
                <Modal className="ui tiny modal"
                       onApprove={() => {
                           this.props.onApprove();
                       }}
                       onDeny={() => {
                           this.props.onDeny();
                       }}
                       onHide={() => {
                           this.props.onDeny();
                       }}
                       onShow={(ref) => {
                           this.$modal = ref;
                           window.$(this.$modal).find('.close.icon').remove();
                       }}
                >
                    <div className="content">
                        <h3 className={"ui header"}>ยกเลิกสินค้า{this.props.item_name ? ' "' + this.props.item_name + '" ' : ''}หรือไม่</h3>
                    </div>
                    <div className="actions">
                        <button className="ui black deny button" type={"button"}>
                            ไม่ดำเนินการ
                        </button>
                        <button className="ui approve blue button " type={"button"}>
                            ยกเลิกสินค้า
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}