import React from 'react';
import Card from "./component/Card";
import SlickSlider from "react-slick/lib/index";

export default class PreviousLook extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			product_displays: [],
			is_loading: true,
		};
		let products = JSON.parse(window.localStorage.getItem('products'));
		products = products.filter((product) => {
			return parseFloat(product) !== parseFloat(props.current_product_id)
		});
		products = products.reverse();
		this.state.product_displays = products;
	}

	componentDidMount() {
		this.fetch();
		// console.log(this.props.current_product_id);
	}

	fetch(index) {
		let products = JSON.parse(window.localStorage.getItem('products'));
		products = products.filter((product) => {
			return parseFloat(product) !== parseFloat(this.props.current_product_id)
		});
		products = products.reverse();
		let product_index = 0;
		if (index) {
			product_index = index;
		}
		let max_index = products.length;
		if (max_index === 0) {
			this.setState({
				is_loading: false
			});
		}
		if (product_index === max_index) {
			return false
		}
		window.Global.ajax({
			method: 'GET',
			url: '/api/e-commerce/v1/product_display',
			data: {
				product_display_id: products[product_index],
				request_data_product_display_using_filter: true,
			},
			done: (response) => {
				let product_displays = [...this.state.product_displays];
				product_displays[product_index] = response.product_display[0];
				this.setState({
					count: response.count,
					product_displays: product_displays,
					is_loading: false,
				}, () => {
					this.fetch(parseFloat(product_index) + 1);
				});
			}
		});
	}

	render() {
		return (
			<>
				{
					!this.state.is_loading
					&& this.state.product_displays.length > 0 ?
						<h2 className={"ui center aligned header"}>สินค้าที่ดูก่อนหน้า</h2>
						: null
				}
				{
					!this.state.is_loading
					&& this.state.product_displays.length > 0 ?
						<SlickSlider dots={false}
						             infinite={false}
						             speed={500}
						             slidesToShow={4}
						             slidesToScroll={1}
						             arrows={true}
						             responsive={[
							             {
								             breakpoint: 600,
								             settings: {
									             slidesToShow: 3,
									             dots: true,
									             arrows: false,
								             }
							             },
							             {
								             breakpoint: 480,
								             settings: {
									             slidesToShow: 2,
									             dots: true,
									             arrows: false,
								             }
							             },
						             ]}
						>
							{
								this.state.product_displays.map((product_display, index) => {
									if (!product_display.id) {
										return (
											<div className="card-list"
											     style={{minHeight: 230, position: 'relative'}}>
												<div className="ui active inverted dimmer">
													<div className="ui large text loader">กำลังโหลดข้อมูล</div>
												</div>
											</div>
										);
									}
									return (
										<div className="card-list" key={index}>
											<Card product_display={product_display} theme={2}/>
										</div>
									)
								})
							}
						</SlickSlider>
						: null
				}
			</>
		);
	}
}