import React from 'react';
// import {country} from "../country_db";

export default class RelationshipAddress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			search_input: true,
			mode: this.props.mode,
			add_mode: 'country_address',
			add_address: {
				add_address: false
			},
			selected_country: "th",
			relationship_addresses: this.props.mode === 'list' ? [] : {
				type: 'home'
			},
			relationship_address: this.props.relationship_address || {},
			selected_relationship_address_id: this.props.selected_relationship_address_id || false,
		}
	}

	componentDidMount() {
		// console.log(this.props);
		if (this.state.mode === 'list') {
			this.fetch()
		}
		if (this.state.mode === 'create' || this.state.mode === 'edit') {
			this.setup();
		}
	}

	setup() {
		// console.log(this.$modal);
		window.$(this.$modal).find('form').form2('setup');
		window.$(this.$modal).find('form').form2('submit', {
			method: this.state.mode === 'create' ? 'POST' : 'PUT',
			// url: '/api/v1/relationship_address',
			url: '/api/v1/e-commerce/relationship_address',
			beforeSent: function (object) {
				//console.log(object);
				return object;
			},
			loadingButton: window.$(this.$modal).find('.button.approve'),
			submitButton: window.$(this.$modal).find('.button.approve'),
			onSuccess: (response) => {
				window.Global.messageTop({
					type: 'positive',
					message: 'บันทึกข้อมูลสำเร็จ'
				});
				this.setState({
					mode: 'list',
					selected_relationship_address_id:
						this.state.is_can_select
							? response.relationship_address.id
							: false,
				}, () => {
					this.fetch();
				});
			}
		});
		window.$.Thailand({
			database: './libs/spaceship-web/jquery_Thailand/db.json',
			database_type: 'json',
			autocomplete_size: 20,
			$district: window.$('#district'),
			$amphoe: window.$('#city'),
			$province: window.$('#province'),
			$zipcode: window.$('#zipcode'),
		});
	}

	fetch() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/relationship_address',
			done: (response) => {
				let selected_relationship_address_id = {...this.state.selected_relationship_address_id};
				if (response.relationship_address && response.relationship_address.length > 0 && this.props.is_can_select) {
					response.relationship_address.forEach((relationship_address) => {
						if (!this.props.selected_id && relationship_address.is_default) {
							selected_relationship_address_id = relationship_address.id;
						}
						if (this.props.selected_id && this.props.selected_id === relationship_address.id) {
							selected_relationship_address_id = relationship_address.id;
						}
					})
				}
				this.setState({
					selected_relationship_address_id: selected_relationship_address_id,
					relationship_addresses: response.relationship_address,
					is_loading: false,
				});
			}
		})
	}

	removeRelationshipAddress(id) {
		let data = {};
		data.relationship_address_id = id;
		// console.log(data);
		window.Global.ajax({
			method: 'DELETE',
			// url: '/api/v1/relationship_address',
			url: '/api/v1/e-commerce/relationship_address',
			data: data,
			done: (response) => {
				// console.log(response);
				if (response.success) {
					window.Global.messageTop({
						type: 'positive',
						message: 'ลบข้อมูลสำเร็จ'
					});
					this.setState({
						mode: 'list'
					}, () => {
						this.fetch();
					})
				}
			}
		});
	}

	render() {
		return (
			<>
				<div className="header">
					{
						this.state.mode === 'list'
						&&
						<div className="flex flex-row">
							เลือกที่อยู่จัดส่ง
							<div className="right floated">
								<button className={"ui green button"}
								        onClick={() => {
									        this.setState({
										        mode: 'create',
										        relationship_address: {
											        is_default: true
										        }
									        }, () => {
										        this.setup()
									        })
								        }}
								>
									<i className={"icon plus"}/>
									เพิ่มที่อยู่
								</button>
							</div>
						</div>
					}
					{
						(
							this.state.mode === 'create'
							|| this.state.mode === 'edit'
						)
						&&
						<div className="flex flex-row">
							ที่อยู่จัดส่ง
						</div>
					}
					{
						this.state.mode === 'delete'
						&&
						<h3 className={"ui header"}>ยืนยันการลบข้อมูล</h3>
					}
				</div>
				<div className="content"
				     ref={(ref) => {
					     if (!ref) return
					     this.$modal = window.$(ref).closest('.modal');
				     }}
				>
					{
						this.state.mode === 'list'
						&&
						<table className="ui very single line table selectable custom-table">
							<thead>
							<tr>
								<th/>
								<th>ชื่อ - นามสกุล</th>
								<th>ประเภทที่อยู่</th>
								<th>ที่อยู่</th>
								<th>เบอร์โทรศัพท์</th>
								<th style={{textAlign: 'center'}}>
									{
										!this.props.is_can_select
										&&
										"ที่อยู่เริ่มต้น"
									}
								</th>
							</tr>
							</thead>
							<tbody>
							{
								this.state.is_loading ?
									<tr>
										<td colSpan="4">
											<div className="flex center-xs">
												<div>
													<div className="ui active mini inline loader"
													     style={{marginRight: 10}}/>
													กำลังโหลดข้อมูล
												</div>
											</div>
										</td>
									</tr>
									: null
							}
							{
								!this.state.is_loading
								&& this.state.relationship_addresses.length > 0 ?
									this.state.relationship_addresses.map((relationship_address, index) => {
										let is_selected = relationship_address.id === this.state.selected_relationship_address_id;
										return (
											<tr key={index}
											    className={
												    is_selected
													    ? 'positive'
													    : null
											    }
											    onClick={(e) => {

												    if (window.$(e.target).closest('.edit-button').length) {
													    this.setState({
														    mode: 'edit',
														    relationship_address: relationship_address
													    }, () => {
														    this.setup();
													    });
													    return false;
												    }
												    if (!this.props.is_can_select) {
													    return;
												    }
												    this.setState({
													    selected_relationship_address_id: relationship_address.id
												    })
											    }}
											>
												<td>
													<div className="flex flex-row center-xs">
														{
															this.props.is_can_select
																?
																is_selected
																	?
																	<div className={'flex flex-row middle-xs center-xs'}>
																		<i className={"icon check green"}/> เลือกที่อยู่นี้
																	</div>
																	:
																	null
																:
																relationship_address.is_default ?
																	<i className={"icon check green"}/>
																	:
																	null
														}
													</div>
												</td>
												<td>
													{relationship_address.name}
												</td>
												<td>
													{relationship_address.type === 'work' ? 'ที่ทำงาน' : null}
													{relationship_address.type === 'home' ? 'บ้าน' : null}
												</td>
												<td>
													{relationship_address.address_line_1}
												</td>
												<td>
													{relationship_address.phone_number}
												</td>
												<td>
													<button className={"ui icon button mini basic edit-button"}>
														<i className={"icon edit"}/> แก้ไข
													</button>
												</td>
											</tr>
										);
									})
									: null
							}
							{
								!this.state.is_loading
								&& this.state.relationship_addresses.length === 0 ?
									<tr>
										<td colSpan="4">
											<div className="flex center-xs">
												<div>
													ไม่มีข้อมูลที่อยู่ในการจัดส่ง
												</div>
											</div>
										</td>
									</tr>
									: null
							}
							</tbody>
						</table>
					}
					{
						(
							this.state.mode === 'create'
							|| this.state.mode === 'edit'
						)
						&&
						<form className="ui form"
						      style={{margin: 0}}
						      autoComplete={'off'}
						>
							<div className="flex row padded two column computer">
								<input type="hidden"
								       name="relationship_address_id"
								       defaultValue={this.state.relationship_address.id}/>
								<div className="column field">
									<label>ชื่อ - นามสกุล</label>
									<input name={"name"}
									       placeholder={"กรอกชื่อ - นามสกุล"}
									       defaultValue={this.state.relationship_address.name}/>
								</div>
								<div className="column field">
									<label>เบอร์โทรศัพท์</label>
									<input name={"phone_number"}
									       placeholder={"กรอกเบอร์โทรศัพท์"}
									       defaultValue={this.state.relationship_address.phone_number ? this.state.relationship_address.phone_number.replace('+66', '0') : ''}/>
								</div>
								<div className="column field">
									<label>ประเภทที่อยู่</label>
									<div className="ui radio checkbox"
									     style={{marginRight: 15}}>
										<input type="radio"
										       id={"home"}
										       name="type"
										       defaultChecked={this.state.relationship_address.type === 'home'}
										       value={"home"}
										/>
										<label htmlFor={"home"}>บ้าน</label>
									</div>
									<div className="ui radio checkbox">
										<input type="radio"
										       id={"work"}
										       name="type"
										       defaultChecked={this.state.relationship_address.type === 'work'}
										       value={"work"}
										/>
										<label htmlFor={"work"}>ที่ทำงาน</label>
									</div>
								</div>
								<div className="column field">
									<div className="ui checkbox"
									     data-form2_setup="">
										<input type="checkbox"
										       name="is_default"
										       id={"is_default"}
										       value={1}
										       defaultChecked={this.state.relationship_address.is_default}
										/>
										<label htmlFor={"is_default"}>
											<b>ตั้งเป็นที่อยู่เริ่มต้น</b>
										</label>
									</div>
								</div>
								<div className="column field" style={{display: "none"}}>
									<label>ประเทศ</label>
									<select className="ui dropdown search"
									        ref={(ref) => {
										        window.$(ref).dropdown()
									        }}
									        name="country"
									        data-form2_setup="true"
										// defaultValue={this.state.relationship_address.country || 'TH'}
										     defaultValue={'TH'}
									>
										<option value="RU">Russian Federation</option>
										<option value="US">United States</option>
										<option value="ES">Spain</option>
										<option value="FR">France</option>
										<option value="UK">United Kingdom</option>
										<option value="BR">Brazil</option>
										<option value="IL">Israel</option>
										<option value="NL">Netherlands</option>
										<option value="CA">Canada</option>
										<option value="IT">Italy</option>
										<option value="CL">Chile</option>
										<option value="UA">Ukraine</option>
										<option value="PL">Poland</option>
										<option value="AU">Australia</option>
										<option value="DE">Germany</option>
										<option value="BE">Belgium</option>
										<option value="">-------- Country &amp; Territories (A-Z) --------</option>
										<option value="AF">Afghanistan</option>
										<option value="ALA">Aland Islands</option>
										<option value="AL">Albania</option>
										<option value="GBA">Alderney</option>
										<option value="DZ">Algeria</option>
										<option value="AS">American Samoa</option>
										<option value="AD">Andorra</option>
										<option value="AO">Angola</option>
										<option value="AI">Anguilla</option>
										<option value="AG">Antigua and Barbuda</option>
										<option value="AR">Argentina</option>
										<option value="AM">Armenia</option>
										<option value="AW">Aruba</option>
										<option value="ASC">Ascension Island</option>
										<option value="AU">Australia</option>
										<option value="AT">Austria</option>
										<option value="AZ">Azerbaijan</option>
										<option value="BH">Bahrain</option>
										<option value="GGY">Guernsey</option>
										<option value="BD">Bangladesh</option>
										<option value="BB">Barbados</option>
										<option value="BY">Belarus</option>
										<option value="BE">Belgium</option>
										<option value="BZ">Belize</option>
										<option value="BJ">Benin</option>
										<option value="BM">Bermuda</option>
										<option value="BT">Bhutan</option>
										<option value="BO">Bolivia</option>
										<option value="BA">Bosnia and Herzegovina</option>
										<option value="BW">Botswana</option>
										<option value="BR">Brazil</option>
										<option value="VG">Virgin Islands (British)</option>
										<option value="BG">Bulgaria</option>
										<option value="BF">Burkina Faso</option>
										<option value="BI">Burundi</option>
										<option value="KH">Cambodia</option>
										<option value="CM">Cameroon</option>
										<option value="CA">Canada</option>
										<option value="CV">Cape Verde</option>
										<option value="BQ">Caribbean Netherlands</option>
										<option value="KY">Cayman Islands</option>
										<option value="CF">Central African Republic</option>
										<option value="TD">Chad</option>
										<option value="CL">Chile</option>
										<option value="CX">Christmas Island</option>
										<option value="CC">Cocos (Keeling) Islands</option>
										<option value="CO">Colombia</option>
										<option value="KM">Comoros</option>
										<option value="CK">Cook Islands</option>
										<option value="CR">Costa Rica</option>
										<option value="CI">Cote D'Ivoire</option>
										<option value="HR">Croatia (local name: Hrvatska)</option>
										<option value="CW">Curacao</option>
										<option value="CY">Cyprus</option>
										<option value="CZ">Czech Republic</option>
										<option value="DK">Denmark</option>
										<option value="DJ">Djibouti</option>
										<option value="DM">Dominica</option>
										<option value="DO">Dominican Republic</option>
										<option value="EC">Ecuador</option>
										<option value="EG">Egypt</option>
										<option value="SV">El Salvador</option>
										<option value="GQ">Equatorial Guinea</option>
										<option value="ER">Eritrea</option>
										<option value="EE">Estonia</option>
										<option value="ET">Ethiopia</option>
										<option value="FK">Falkland Islands (Malvinas)</option>
										<option value="FO">Faroe Islands</option>
										<option value="FJ">Fiji</option>
										<option value="FI">Finland</option>
										<option value="FR">France</option>
										<option value="PF">French Polynesia</option>
										<option value="GA">Gabon</option>
										<option value="GM">Gambia</option>
										<option value="GE">Georgia</option>
										<option value="DE">Germany</option>
										<option value="GH">Ghana</option>
										<option value="GI">Gibraltar</option>
										<option value="GR">Greece</option>
										<option value="GL">Greenland</option>
										<option value="GD">Grenada</option>
										<option value="GP">Guadeloupe</option>
										<option value="GU">Guam</option>
										<option value="GT">Guatemala</option>
										<option value="GN">Guinea</option>
										<option value="GW">Guinea-Bissau</option>
										<option value="GY">Guyana</option>
										<option value="GF">French Guiana</option>
										<option value="HT">Haiti</option>
										<option value="HN">Honduras</option>
										<option value="HK">Hong Kong,China</option>
										<option value="HU">Hungary</option>
										<option value="IS">Iceland</option>
										<option value="IN">India</option>
										<option value="ID">Indonesia</option>
										<option value="IQ">Iraq</option>
										<option value="IE">Ireland</option>
										<option value="IL">Israel</option>
										<option value="IT">Italy</option>
										<option value="JM">Jamaica</option>
										<option value="JP">Japan</option>
										<option value="JEY">Jersey</option>
										<option value="JO">Jordan</option>
										<option value="KZ">Kazakhstan</option>
										<option value="KE">Kenya</option>
										<option value="KI">Kiribati</option>
										<option value="KR">Korea</option>
										<option value="KS">Kosovo</option>
										<option value="KW">Kuwait</option>
										<option value="KG">Kyrgyzstan</option>
										<option value="LA">Lao People's Democratic Republic</option>
										<option value="LV">Latvia</option>
										<option value="LB">Lebanon</option>
										<option value="LS">Lesotho</option>
										<option value="LR">Liberia</option>
										<option value="LY">Libya</option>
										<option value="LI">Liechtenstein</option>
										<option value="LT">Lithuania</option>
										<option value="LU">Luxembourg</option>
										<option value="MO">Macau,China</option>
										<option value="MG">Madagascar</option>
										<option value="MW">Malawi</option>
										<option value="MY">Malaysia</option>
										<option value="MV">Maldives</option>
										<option value="ML">Mali</option>
										<option value="MT">Malta</option>
										<option value="MQ">Martinique</option>
										<option value="MR">Mauritania</option>
										<option value="MU">Mauritius</option>
										<option value="YT">Mayotte</option>
										<option value="MX">Mexico</option>
										<option value="FM">Micronesia</option>
										<option value="MC">Monaco</option>
										<option value="MN">Mongolia</option>
										<option value="MNE">Montenegro</option>
										<option value="MS">Montserrat</option>
										<option value="MA">Morocco</option>
										<option value="MZ">Mozambique</option>
										<option value="MM">Myanmar</option>
										<option value="NA">Namibia</option>
										<option value="NR">Nauru</option>
										<option value="BN">Negara Brunei Darussalam</option>
										<option value="NP">Nepal</option>
										<option value="NL">Netherlands</option>
										<option value="AN">Netherlands Antilles</option>
										<option value="NC">New Caledonia</option>
										<option value="NZ">New Zealand</option>
										<option value="NI">Nicaragua</option>
										<option value="NE">Niger</option>
										<option value="NG">Nigeria</option>
										<option value="NU">Niue</option>
										<option value="NF">Norfolk Island</option>
										<option value="MK">Macedonia</option>
										<option value="MP">Northern Mariana Islands</option>
										<option value="NO">Norway</option>
										<option value="OM">Oman</option>
										<option value="PK">Pakistan</option>
										<option value="PW">Palau</option>
										<option value="PS">Palestine</option>
										<option value="PA">Panama</option>
										<option value="PG">Papua New Guinea</option>
										<option value="PY">Paraguay</option>
										<option value="PE">Peru</option>
										<option value="PH">Philippines</option>
										<option value="PL">Poland</option>
										<option value="PT">Portugal</option>
										<option value="PR">Puerto Rico</option>
										<option value="QA">Qatar</option>
										<option value="MD">Moldova</option>
										<option value="RE">Reunion</option>
										<option value="RO">Romania</option>
										<option value="RU">Russian Federation</option>
										<option value="RW">Rwanda</option>
										<option value="BLM">Saint Barthelemy</option>
										<option value="KN">Saint Kitts and Nevis</option>
										<option value="LC">Saint Lucia</option>
										<option value="MAF">Saint Martin</option>
										<option value="PM">St. Pierre and Miquelon</option>
										<option value="VC">Saint Vincent and the Grenadines</option>
										<option value="WS">Samoa</option>
										<option value="SM">San Marino</option>
										<option value="ST">Sao Tome and Principe</option>
										<option value="SA">Saudi Arabia</option>
										<option value="SN">Senegal</option>
										<option value="SRB">Serbia</option>
										<option value="SC">Seychelles</option>
										<option value="SL">Sierra Leone</option>
										<option value="SG">Singapore</option>
										<option value="SX">Sint Maarten (Netherlands)</option>
										<option value="SK">Slovakia (Slovak Republic)</option>
										<option value="SI">Slovenia</option>
										<option value="SB">Solomon Islands</option>
										<option value="SO">Somalia</option>
										<option value="ZA">South Africa</option>
										<option value="SGS">South Georgia and the South Sandwich Islands</option>
										<option value="SS">South Sudan</option>
										<option value="ES">Spain</option>
										<option value="LK">Sri Lanka</option>
										<option value="SR">Suriname</option>
										<option value="SZ">Swaziland</option>
										<option value="SE">Sweden</option>
										<option value="CH">Switzerland</option>
										<option value="TW">Taiwan,China</option>
										<option value="TJ">Tajikistan</option>
										<option value="TH">Thailand (ประเทศไทย)</option>
										<option value="BS">Bahamas</option>
										<option value="ZR">Congo, The Democratic Republic Of The</option>
										<option value="CG">Congo, The Republic of Congo</option>
										<option value="MH">Marshall Islands</option>
										<option value="VA">Vatican City State (Holy See)</option>
										<option value="TLS">Timor-Leste</option>
										<option value="TG">Togo</option>
										<option value="TO">Tonga</option>
										<option value="TT">Trinidad and Tobago</option>
										<option value="TN">Tunisia</option>
										<option value="TR">Turkey</option>
										<option value="TM">Turkmenistan</option>
										<option value="TC">Turks and Caicos Islands</option>
										<option value="TV">Tuvalu</option>
										<option value="VI">Virgin Islands (U.S.)</option>
										<option value="UG">Uganda</option>
										<option value="UA">Ukraine</option>
										<option value="AE">United Arab Emirates</option>
										<option value="UK">United Kingdom</option>
										<option value="TZ">Tanzania</option>
										<option value="US">United States</option>
										<option value="UY">Uruguay</option>
										<option value="UZ">Uzbekistan</option>
										<option value="VU">Vanuatu</option>
										<option value="VE">Venezuela</option>
										<option value="VN">Vietnam</option>
										<option value="WF">Wallis And Futuna Islands</option>
										<option value="YE">Yemen</option>
										<option value="ZM">Zambia</option>
										<option value="EAZ">Zanzibar</option>
										<option value="ZW">Zimbabwe</option>
										<option value="OTHER">Other Country</option>
										<option value="">-------- Country &amp; Territories (A-Z) --------</option>
									</select>
								</div>
								<div className="column field">
									<label>รายละเอียดที่อยู่ (บ้านเลขที่ ชื่ออาคาร ฯลฯ)</label>
									<input name={"street"}
									       id={"street"}
									       placeholder={"กรอกที่อยู่"}
									       defaultValue={this.state.relationship_address.street}/>
								</div>
								<div className="column field">
									<label>ตำบล/แขวง</label>
									<input name={"district"} id={"district"}
									       placeholder={"กรอกตำบล"}
									       defaultValue={this.state.relationship_address.district}/>
								</div>
								<div className="column field">
									<label>อำเภอ/เขต</label>
									<input name={"city"} id={"city"}
									       placeholder={"กรอกอำเภอ"}
									       defaultValue={this.state.relationship_address.city}/>
								</div>
								<div className="column field">
									<label>จังหวัด</label>
									<input name={"province"} id={"province"}
									       placeholder={"กรอกจังหวัด"}
									       defaultValue={this.state.relationship_address.province}/>
								</div>
								<div className="column field">
									<label>รหัสไปรษณีย์</label>
									<input name={"zip_code"} id={"zipcode"}
									       placeholder={"กรอกรหัสไปรษณีย์"}
									       defaultValue={this.state.relationship_address.zip_code}/>
								</div>
							</div>
							<div className="ui error message"/>
						</form>
					}
				</div>
				<div className="actions">
					{
						this.state.mode === 'list'
						&&
						<>
							<div className="ui deny button">
								ปิดหน้าต่าง
							</div>
							{
								this.props.is_can_select
									?
									<div className="ui blue button"
									     style={{marginLeft: 15}}
									     onClick={() => {
										     let selected_relationship_address = this.state.relationship_addresses.filter((relationship_address) => {
											     return this.state.selected_relationship_address_id === relationship_address.id;
										     });
										     //console.log(this.props);
										     this.props.onSelectedRelationshipAddress(
											     selected_relationship_address.length ? selected_relationship_address[0] : false
										     );
										     window.$(this.$modal).modal('hide');
									     }}
									>
										ตกลง
									</div>
									: null
							}
						</>
					}
					{
						(
							this.state.mode === 'create'
							|| this.state.mode === 'edit'
						)
						&&
						<div className={"flex row"}
						     style={{marginBottom: '1rem'}}>
							{
								this.state.mode === 'edit' ?
									<div className="ui buttons">
										<button
											className={"ui button red"}
											id={"remove_btn"}
											onClick={() => {
												this.setState({
													mode: 'delete'
												});
												// this.removeRelationshipAddress(this.state.relationship_address.id)
											}}
										>
											ลบ
										</button>
									</div>
									: null
							}
							<div className="right floated">
								<button className="ui deny basic button"
								        type={"button"}>
									ยกเลิก
								</button>
								<button className="ui approve blue button "
								        type={"button"}
								        style={{marginLeft: 15}}
								>
									ตกลง
								</button>
							</div>
						</div>
					}
					{
						this.state.mode === 'delete'
						&&
						<div>
							<button className="ui basic button"
							        onClick={() => {
								        this.setState({
									        mode: 'edit',
								        })
							        }}
							        type={"button"}>
								ยกเลิก
							</button>
							<button className="ui approve blue button "
							        onClick={() => {
								        this.removeRelationshipAddress(this.state.relationship_address.id);
							        }}
							        type={"button"}
							>
								ตกลง
							</button>
						</div>
					}
				</div>
			</>
		);
	}
}
