import React from 'react';
import Index from './page/Index';
import Product from './page/Product';
import Cart from './page/Cart';
import Shop from "./page/Shop";
import Checkout from "./page/Checkout";
import ManageMyAccount from "./page/ManageMyAccount";
import OrderDetails from "./page/OrderDetails";
import Login from "./page/Login";
import MyPurchase from "./page/MyPurchase";
import PrivacyPolicy from "./page/PrivacyPolicy";
import About from "./page/About";
import FAQ from "./page/FAQ";
import HowToBuy from "./page/HowToBuy";
import HowToUse from "./page/HowToUse";
import WareHouseMap from "./page/WareHouseMap";
import ForgotPassword from "./page/ForgotPassword";
import PasswordReset from "./page/PasswordReset";
import ChangeEmail from "./page/ChangeEmail";
import ConfirmEmail from "./page/ConfirmEmail";
import {Global2} from "./component/Global";

window.Global.api_host = window.location.hostname === 'www.b-give.com' || window.location.hostname === 'b-give.com' ? '' : 'https://pre-production.b-give.com';
window.Global.ajax = function (object) {
	if (typeof (object.method) == 'undefined')
		object.method = 'POST';
	if (typeof (object.dataType) == 'undefined')
		object.dataType = 'json';
	if (typeof (object.contentType) == 'undefined')
		object.contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
	if (object.data) {
		//object.data.api_key = '3WEgGuFJpPedJX84sbAzaWxv';
	} else {
		object.data = {};
	}
	//if (object.data && localStorage.user) {
	//	var user = JSON.parse(localStorage.user);
	//	object.data.access_token = user.user.access_token;
	//}
	//if (localStorage.user) {
	//	object.data.users_id = Global.user.user.id;
	//	object.data.access_token = Global.user.user.access_token;
	//}

	let headers = object.headers ? object.headers : {};
	if (Global2.getMyProfile()) {
		headers['X-B49-CLIENT'] = Global2.getMyProfile().token;
	}

	return window.$.ajax({
		type: object.method,
		data: object.data,
		contentType: object.contentType,
		url: window.Global.api_host + object.url,
		headers: headers,
		beforeSend: function () {
			if (typeof (object.beforeSend) != 'undefined') {
				object.beforeSend();
			}
		},
		dataType: object.dataType
	}).done(function (response, textStatus, jqXHR) {
		// console.log(response);
		if (response.error && response.message) {
			window.$.each(response.message, function (i, message) {
				if (message['is_require_authentication']) {
					window.location = '/signout';
					return false;
				}
			})
		}
		if (typeof object.done == 'function')
			object.done(response);
	}).fail(function (jqXHR, textStatus, errorThrown) {
		//console.log(jqXHR, typeof object.fail == 'function');
		if (typeof object.fail == 'function')
			object.fail(jqXHR);
	});
};
let url = window.location.pathname;
window.segment = url.split('/');

export default class App extends React.Component {
	constructor() {
		super();
		this.state = {
			is_loading: true,
		}
	}

	componentDidMount() {
		// if(window.segment[1] !== '') {
		// 	window.location = '/'
		// }
		window.Global.google_recaptcha_key = '6LeZEUMUAAAAAI24cCUQrhhGGEkFH2EvWTiMK5xf';

		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/info',
			data: {},
			done: (response) => {
				this.setState({
					is_loading: false,
					e_commerce: response.e_commerce[0]
				});
			}
		});
	}

	render() {
		if (this.state.is_loading) {
			return null;
		}

		let is_close_website = false;

		this.state.e_commerce.site_config.close_date.forEach((close_date) => {
			// console.log(close_date);
			if (new Date() >= new Date(close_date.start_close_date)
				&& new Date() <= new Date(close_date.end_close_date)
			) {
				is_close_website = true;
			}
		})

		if (this.state.e_commerce.site_config.is_close_website) {
			is_close_website = true;
		}

		let data = {
			...window.Global.getAllQueryStrings(),
			e_commerce: this.state.e_commerce,
			is_close_website: is_close_website
		}

		localStorage.setItem('how_display_price', this.state.e_commerce.site_config.how_display_price);

		if (is_close_website) {
			return <Index {...data}/>;
		}

		if (!window.segment[1]) {
			return <Index {...data}/>;
		}

		if (window.segment[1] === 'product' && window.Global.getAllQueryStrings().product_id) {
			return <Product {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'cart') {
			return <Cart {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'shop') {
			return <Shop {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'checkout') {
			return <Checkout {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'manage-my-account') {
			return <ManageMyAccount {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'my-purchase') {
			return <MyPurchase {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'order-detail') {
			return <OrderDetails {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'login') {
			return <Login {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'forgot-password') {
			return <ForgotPassword {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'privacy-policy') {
			return <PrivacyPolicy {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'about') {
			return <About {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'faq') {
			return <FAQ {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'how-to-buy') {
			return <HowToBuy {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'how-to-use') {
			return <HowToUse {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'warehouse-map') {
			return <WareHouseMap {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'password-reset') {
			return <PasswordReset {...data}/>;
		}
		if ((window.segment[1] && window.segment[1] === 'change-email')
			|| (window.segment[1] && window.segment[1] === 'comfirm-change-email')) {
			return <ChangeEmail {...data}/>;
		}
		if (window.segment[1] && window.segment[1] === 'confirm-email') {
			return <ConfirmEmail {...data}/>;
		}

		return (<></>);
	}
}
