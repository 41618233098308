import React from 'react';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from './Card';
import {Modal} from "./Modal";

export default class ProductTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: [],
			selected_tab_index: 0,
			product_displays: [],
			is_loading: true
		}
	}

	componentDidMount() {
		this.fetchTabs();
	}

	fetchTabs() {
		let data = {};
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/filter',
			data: data,
			done: (response) => {
				let filters = [{name: 'ทั้งหมด',id: ''},...response.filter];

				this.setState({
					filter: filters
				},()=> {
					this.fetchProduct();
				})
			}
		});
	}

	fetchProduct() {
		let data = {};
		data.filter_id = this.state.filter[this.state.selected_tab_index].id;
		data.request_data_product_display_using_filter = true;
		data.limit = 24;
		// console.log(data);
		window.Global.ajax({
			method: 'GET',
			url: '/api/e-commerce/v1/product_display',
			data: data,
			done: (response) => {
				let product_displays = [];
				if (response.product_display) {
					product_displays = response.product_display;
				}
				// console.log(product_displays);
				this.setState({
					product_displays: product_displays,
					is_loading: false,
				});
			}
		});
	}

	render() {
		return (
			<>
				<div className="ui container">
					{
						this.state.filter
						&& this.state.filter.length > 0 ?
							<div className={"visible-xs"} style={{marginTop: 15}}>
								{/*<div className={"flex row center-xs"} style={{marginBottom: 5}}>*/}
								{/*	<strong>หมวดหมู่</strong>*/}
								{/*</div>*/}
								<button className={"ui button fluid brown"}
								        onClick={() => {
									        window.Global.reactToDom(
										        window.$('body'),
										        <ModalTabs
											        filter={this.state.filter}
											        selected_tab_index={this.state.selected_tab_index}
											        onClick={(tabs_index) => {
												        this.setState({
													        selected_tab_index: tabs_index,
													        is_loading: true,
												        }, () => {
													        this.fetchProduct()
												        });
											        }}
										        />
									        )
								        }}
								>{this.state.filter[this.state.selected_tab_index].name}
								</button>
							</div>
							: null
					}
					<div className="tabs-title">
						{
							this.state.filter.length > 0 ?
								this.state.filter.map((filter, tabs_index) => {
									return (
										<div key={tabs_index}>
											<button
												className={this.state.selected_tab_index === tabs_index ? 'selected' : null}
												onClick={() => {
													this.setState({
														selected_tab_index: tabs_index,
														is_loading: true,
													}, () => {
														this.fetchProduct()
													});
												}}
											>
												{filter.name}
											</button>
										</div>
									);
								})
								: null
						}
					</div>
					<div
						className={"tabs-list ui segment"}>
						{
							this.state.product_displays
							&& this.state.product_displays.length !== 0
							&& this.state.is_loading ?
								<div className="ui active inverted dimmer">
									<h2 className={"ui header"}>
										<div className="ui active inline loader" style={{marginRight: 10}}/>
										กำลังโหลดข้อมูล
									</h2>
								</div>
								: null
						}
						{
							this.state.product_displays
							&& this.state.product_displays.length === 0
							&& this.state.is_loading ?
								<div className={"flex row middle-xs center-xs"}
								     style={{paddingTop: 20, paddingBottom: 20}}>
									<h2 className={"ui header"} style={{padding: '82px 0 81px'}}>
										<div className="ui active inline loader" style={{marginRight: 10}}/>
										กำลังโหลดข้อมูล
									</h2>
								</div>
								: null
						}
						{
							this.state.product_displays
							&& this.state.product_displays.length > 0 ?
								<SlickSlider dots={false}
								             infinite={false}
								             speed={500}
								             slidesToShow={1}
								             slidesToScroll={1}
								             arrows={false}
								             rows={2}
								             slidesPerRow={4}
								             responsive={[
									             {
										             breakpoint: 600,
										             settings: {
											             slidesPerRow: 3,
											             rows: 1,
											             dots: true,
										             }
									             },
									             {
										             breakpoint: 480,
										             settings: {
											             slidesPerRow: 2,
											             rows: 1,
											             dots: true,
										             }
									             },
								             ]}
								>
									{
										this.state.product_displays
										&& this.state.product_displays.map((product_display, index) => {
											return (
												<div className="card-list" key={index}>
													<Card product_display={product_display}/>
												</div>
											)
										})
									}
								</SlickSlider>
								: null

						}
						{
							!this.state.is_loading
							&& this.state.product_displays.length === 0 ?
								<h1 className={"ui header"} style={{textAlign: 'center', padding: '100px 0'}}>
									ไม่มีสินค้า
								</h1>
								: null
						}
						{
							this.state.product_displays.length !== 0 ?
								<div className="flex center-xs middle-xs" style={{marginTop: 15, marginBottom: 15}}>
									<a className="view-more-button icon right"
									   href={window.Global.getQueryStringLinkFromObject(
										   {
											   filter_id: this.state.filter[this.state.selected_tab_index].id
										   }, '/shop'
									   )}
									>
										ดูเพิ่มเติม
										<i className="right arrow icon"/>
									</a>
								</div>
								: null
						}
					</div>
				</div>
			</>
		);
	}
}

class ModalTabs extends React.Component {
	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       closable={false}
				       onShowToSetupForm={($modal) => {
					       this.modal = $modal;
				       }}
				>
					<div className="header">
						<div className="flex flex-row">
							หมวดหมู่
						</div>
					</div>
					<div className="content">
						<div className={"resp_tabs_modal"}>
							{
								this.props.filter.length > 0 ?
									this.props.filter.map((filter, tabs_index) => {
										return (
											<div key={tabs_index}>
												<button
													className={this.props.selected_tab_index === tabs_index ? 'ui button fluid selected' : 'ui button fluid'}
													onClick={() => {
														this.props.onClick(tabs_index);
														this.modal.modal('hide');
													}}
												>
													{filter.name}
												</button>
											</div>
										);
									})
									: null
							}
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}