import React from 'react';

export class DownloadReportModal extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div>
				<Modal onApprove={() => false}
				       closable={false}
				       onDeny={() => {
					       this.ajax.abort();
				       }}
				       onHide={() => {
					       this.ajax.abort();
				       }}
				       onShow={(ref) => {
					       this.$modal = ref;
					       this.ajax = window.Global.ajax({
						       method: 'GET',
						       url: this.props.url,
						       data: this.props.data,
						       done: (response) => {
							       if (!response.success) {
								       return false;
							       }
							       window.$('body').append('<iframe src="' + response['download_url'] + '" style="width: 0;height: 0;"></iframe>');
							       window.$(this.$modal).modal('hide');
						       },
						       fail: () => {

						       }
					       });
				       }}
				>
					<div className="header">กำลังเตรียมรายงานสำหรับคุณ</div>
					<div className="content">
						<form ref={e => this.$form = e} className="ui form">
							<div className="ui indicating progress active" data-percent="100">
								<div className="bar" style={{width: '100%'}}/>
								<div className="label">กำลังโหลด ไม่ต้องปิดหน้าต่าง...</div>
							</div>
							<div className="ui error message"/>
						</form>
					</div>
					{/*<div className="actions"></div>*/}
				</Modal>
			</div>
		);
	}
}

export class Modal extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div ref={(ref) => {
				window.$(ref).modal({
					closable: this.props.closable === undefined ? true : this.props.closable,
					autofocus: this.props.autofocus === undefined ? false : this.props.autofocus,
					observeChanges: this.props.observeChanges === undefined ? true : this.props.observeChanges,
					onShow: () => {
						if (this.props.onShowToSetupForm) {
							return this.props.onShowToSetupForm(window.$(ref));
						}
						if (this.props.onShow) {
							return this.props.onShow(ref);
						}
					},
					onApprove: ($element) => {
						if (this.props.onShowToSetupForm) {
							window.$(ref).find('form').trigger('submit');
							return false;
						}
						if (this.props.onApprove) {
							let $button = window.$(ref).find('.approve.button');
							if ($button.hasClass('loading')) return false;
							$button.addClass('loading');
							return this.props.onApprove(ref, $element);
						}
					},
					onDeny: ($element) => {
						if (this.props.onDeny) {
							return this.props.onDeny(ref, $element);
						}
					},
					onHide: ($element) => {
						setTimeout(function () {
							window.$(ref).remove();
						}, 1500);
						if (this.props.onHide) {
							return this.props.onHide(ref, $element);
						}
					}
				}).modal('show');
			}}
			     className={!this.props.className ? "ui tiny modal" : this.props.className}>
				{
					!this.props.disable_close_button ? <i className="close icon"/> : null
				}

				{this.props.children}
			</div>
		);
	}
}

export class Form2FileUpload extends React.Component {
	// constructor(props) {
	// 	super(props);
	// }

	render() {
		return (
			<div ref={(ref) => {
				let options2 = window.$.extend({
					upload_type: 'upload',
					input_name: 'file_upload',
					width: 40,
					height: 40,
					single_file_upload: false
				}, this.props);

				let url = options2.url ? options2.url : '/api/file/upload?type=upload';

				switch (options2.upload_type) {
					case 'single_image':
					case 'gallery':
						let isGallery = false;
						if (options2.upload_type === 'gallery') {
							isGallery = true;
						}
						window.Global.makeUploadButton({
							singleImage: {
								css: {
									width: options2.width,
									height: options2.height
								},
								input: {
									name: options2.input_name,
									value: options2.value || false,
									url: options2.image_url || false
								},
								isGallery: isGallery
							},
							element: window.window.$(ref).find('#upload'),
							url: url,
							onUploadDone: (response) => {
								//console.log(response);

								// if (response.error
								// 	&& response.message
								// 	&& response.message.length > 0
								// ) {
								// 	window.Global.messageTop({
								// 		type: 'negative',
								// 		message: response.message[0]['error_message']
								// 	});
								// 	return false;
								// }
								// if (!response.result.success) {
								// 	window.Global.messageTop({
								// 		type: 'negative',
								// 		message: 'อัพโหลดไม่สำเร็จ ขนาดไฟล์ไม่เกิน 30Mb'
								// 	});
								// 	return false;
								// }

								if (response.result
									&& response.result.files.length === 1
									&& response.result.files[0].name
								) {
									window.Global.messageTop({
										message: 'อัพโหลดสำเร็จ'
									});
									// $.each(response.result.file_uploads, function (index, file) {
									// 	console.log(file);
									// });
									if (this.props.onImageUploadSuccess) {
										this.props.onImageUploadSuccess(response.result.files[0]);
									}
								}

								return true;
							}
						});
						break;
					case 'upload':
						window.Global.makeUploadButton({
							uploadFile: {
								input: {
									name: options2.input_name
								},
								textOnButton: 'อัพโหลด',
								showImagePreview: true
							},
							singleFileUploads: options2.single_file_upload,
							element: window.window.$(ref).find('#upload'),
							url: url
						});
						break;
					default:
				}
			}}
			     className="">
				<div id="upload"></div>
				{this.props.children}
				{
					this.props.onRemove
						?
						<div className="link"
						     onClick={() => {
							     this.props.onRemove();
						     }}>ลบ
						</div>
						: null
				}
			</div>
		);
	}
}