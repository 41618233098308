import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class PrivacyPolicy extends React.Component {
	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<div className="header-bg">
						<h1>นโยบายความเป็นส่วนตัว</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">นโยบายความเป็นส่วนตัว</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div style={{paddingTop: 25}}></div>
						<p>
							<strong>Thai Bridgestone Co., Ltd.,</strong> a company incorporated under Thai law, having
							itsregistered offices at 14/3 Phaholyothin Rd., Khlong Nueng, Khlong Luang, Pathumthani
							12120 and with the companyregistrationnumber 0105510002629, and its subsidiaries, having its
							registered officesin Thailand
							(<strong>“we”</strong>,<strong>“us”</strong> or <strong>“our”</strong>), process Personal
							Data that we collect from orabout <strong>“you”</strong>.
							<br/><br/>
							Since we are based in Thailand, we process your Personal Data in compliance with applicable
							data protection laws of Thailand, including the Personal Data Protection Act B.E. 2562
							(2019) (<strong>“PDPA”</strong>) and other applicable laws.
							<br/><br/>
							This Privacy Policy (<strong>“Policy”</strong>) informs you how we collect and use your
							Personal Data, howyou can control its use and describes our practices concerning the data
							collected from you that link or refer to this Policy (collectively
							the <strong>“Services”</strong>).
							<br/><br/>
							<strong>1. What is Personal Data?</strong>
							<br/><br/>
							Personal Data is information relating to you that directly or indirectly identifies you (the
							user of the Service)as an individual, indirectly meaning when combined with other
							information, for example, yourname, username, postal address, email address and phone
							number, a unique device identifiersuch as the IMEI or the MAC-address or the IP address,
							etc. by which such interpretation shall be consistent with the meanings set forth in the
							PDPA.
							<br/><br/>
							<strong>2. How and why do we collect Personal Data?</strong>
							<br/><br/>
							<strong>a) Information that you give us</strong>
							<br/><br/>
							Through your use of the Services, we may collect your Personal Data. In any case, you willbe
							either asked to explicitly consent to collection and further processing of your Personal
							Data or at least be informed that such processing is based on another lawful basis. Wewill
							use your Personal Data for the purposes as described below or described when we areseeking
							your consent. We do not collect and process more or other types of Personal Datathan
							necessary to fulfill the respective purposes. We will only use Personal Data as set forthin
							this Policy, unless you have specifically provided your consent to another use of
							yourPersonal Data. If we intend to use your Personal Data for purposes other than we
							originallycollected them for, we will inform you in advance and, in cases where the
							processing is basedon your consent, use your Personal Data for a different purpose only with
							your permission.
							<br/><br/>
							In case we rely on your consent to process your Personal Data, you will have the right
							towithdraw it at any time, unless there is a restriction on the withdrawal of consent by law
							or pursuant to a contract which gives benefits to you. The withdrawal of your consent will
							not affect the lawfulness of processing based on consentbefore such withdrawal.
							<br/><br/>
							Each registration form will indicate what kind of Personal Data we collect - various
							purposesmay require collection of various Personal Data, for instance:
							<br/>
							<ul>
								<li><strong>Basic Personal information </strong> e.g. name, gender, date of birth,
									passport or other personal identification numbers, images, photographs, videos,
									biography, resume or CV, etc.
								</li>
								<li><strong>Contact information </strong> e.g., address, phone number, email address,
									etc.
								</li>
								<li><strong>Payment information </strong>e.g. credit or debit card information,
									including the name of cardholder, card number, billing address and expiry date, etc.
								</li>
								<li><strong>Sensitive Data </strong>e.g. medical records, allergies, health conditions,
									etc.
								</li>
								<li><strong>Feedback information </strong>e.g., feedback, complaint, survey, etc.</li>
								<li><strong>Log data and device information </strong>e.g., IP address, cookies, etc.
								</li>
								<li><strong>Tyre services information </strong>e.g., driving license, driver-behavioral
									data, etc.
								</li>
								<li><strong>Membership information </strong>e.g., username, password, etc.</li>
								<li><strong>Others </strong>e.g. any Personal Data stipulated or controlled by the laws
									of Thailand
								</li>
							</ul>
							<strong>b) Information we get from your use of our Services</strong>
							<br/><br/>
							We are constantly seeking to improve your experience when you visit our websites or whenyou
							are interacting with us through other means.
							Through your interaction with us and your use of the Services, we may collect Personal
							Data.Personal Data we collect may include, but not be limited to:
							<br/>
							<ul>
								<li><strong>Your activity on our website and mobile apps. </strong> This is data about
									your browsingactivity (the pages you visited and the time of your visit, the
									preferred language,what items were clicked on a page, how much time you spent on a
									page, what itemsyou place in your shopping basket in our webstore, what products and
									Services youpurchased, and how much was paid).
								</li>
								<li><strong>Device and browser information. </strong>This is technical data about the
									device orbrowser you use to access our website (e. g. IP address, HTTP headers and
									otherinternet transfer protocol signals, user-agent strings, device type, screen
									resolution,device geolocation, operating system version and device identifiers, such
									as AppleIDFA or Android Advertising ID).
								</li>
								<li><strong>Cookies, web beacons and similar technologies. </strong> Cookies are small,
									usually randomly encoded, text files placed on your computer or device that help
									your browser navigate through our website. Web beacons are tiny images or
									objectsembedded in a web page or email and are usually invisible to you, but allow
									us toverify whether you have viewed the web page. Web beacons generally function
									incombination with cookies and we use the two in the same manner. Please also
									referto our Cookies Policy for full details regarding cookies and web beacons used
									at ourwebsite, including their management, specific purposes, categories of data
									they helpto store and periods they are active for.
								</li>
								<li><strong>Your activity on customer service centers. </strong> This is data about your
									contact for our assistance. We may collect and process information about your
									enquiry during the telephone discussion, including your name, contact information
									(including email address and/or telephone number), the name of your organisation and
									any personal information you volunteer that is relevant to your enquiry, the
									product(s) you bought, the reason to why you contacted us and the advice we gave
									you.
								</li>
								<li><strong>Your activity when interacting with us through other means. </strong>This
									includes Personal Data we collect and process when you submit your Personal Data to
									us or agree to our usage terms and conditions through any other means for any
									purposes for which we wish to use your Personal Data that are not listed above.
								</li>
							</ul>
							<strong>3. What do we do with your Personal Data?</strong>
							<br/><br/>
							Depending on your use of the Services, we may collect and use your Personal Data for the
							following purposes (<strong>“Purposes”</strong>):
							<br/>
							<ul>
								<li>Registering you as a user of the Services and providing you with the
									contractedServices;
								</li>
								<li>Complying with laws and regulations;</li>
								<li>Pursuing our legitimate business interests;</li>
								<li>Communicating and responding to your requests and inquiries to us;</li>
								<li>Delivering functionality on our website and for its technical and functional
									management;
								</li>
								<li>Ensuring that the Services’ content is presented to you in the most effective
									mannerfor you and your device;
								</li>
								<li>Engaging in transactions with customers, suppliers and business partners and
									toprocess orders for our products and services;
								</li>
								<li>Processing and handling complaints or requests;</li>
								<li>Researching and analyzing the market as well as customers’ use of our productsand
									services (e.g. sensor-based analytics of tyre and vehicle data; asking youropinion
									on our products and services or by asking you to complete a survey orquestionnaire);
								</li>
								<li>Helping us in evaluating, correcting and improving our products and services;</li>
								<li>Internal filing;</li>
								<li>Marketing our products and services or related products, including providing youwith
									information from us (see section below on <strong>direct marketing</strong>);
								</li>
								<li>Analyzing, developing, improving and optimizing the use, function and performance of
									our Services;
								</li>
								<li>Customer profiling and segmentation based on demographic, geographic andbehavioral
									characteristics, in order to better understand the interests andpreferences of our
									(potential) customers, and so communicate with them moreeffectively;
								</li>
								<li>Managing the security of websites, network and system;</li>
								<li>Interacting with you on third party social media;</li>
								<li>Through the use of cookies and web beacons in connection with some of our webpages,
									we and our website service providers compile aggregate statistics about yourwebsite
									usage, in order to serve you with relevant re-targeted advertising on thirdparty
									platforms and websites about products and services you have demonstratedinterest in.
									If you do not wish to receive re-targeted advertising, please consult ourCookies
									Policy to learn about re-targeted advertising.
								</li>
								<li>Organizing contests, competitions and/or other promotional activities;</li>
								<li>Recruitment (if you have provided us information in this regard);</li>
								<li>Notifying you about certain changes to our Services;</li>
								<li>Upholding our security standard when you enter into our premises and for any other
									security purposes which we may have; and
								</li>
								<li>Sharing your Personal Data between Bridgestone Group members located both
									domestically and internationally and to any other organizations to the extent where
									necessary or permissible by the laws of Thailand.
								</li>
							</ul>
							We shall not collect Personal Data that are not relevant for the purposes as set out aboveor
							otherwise notified to you when we seek your consent for the processing of your Personal
							Data, and shall not retain the data longer than necessary for those purposes or, as the
							casemay be, for the period as determined in an agreement or by law.
							<br/><br/>
							This means that we use your Personal Data:
							<br/>
							<ul>
								<li>until you, as the data subject, objects to such processing and the option to object
									willbe provided in each communication - in case we process your Personal Data
									basedon our legitimate interest in cases where the objection is justified (e.g.
									direct marketingactivities if applicable);
								</li>
								<li>until the expiration of our legitimate interest - in case we process your Personal
									Datain order to secure our legitimate interest in case the objection is not
									justified, e.g.to secure execution of possible obligations and to prevent a fraud;
								</li>
								<li>as long as the legal obligation is in place - in case we process your Personal
									Databased on the legal obligation;
								</li>
								<li>as long as the service and/or action requested by you is completed or you
									withdrawfrom the service/action - in case we process data in connection with
									services werender to you and there is no other purpose for keeping the data; and
								</li>
								<li>until the consent for processing is withdrawn – in case we rely only on your
									consentfor processing the user’s Personal Data and there is no legal interest for
									keeping Personal Data (and the option to withdraw the consent will be provided in
									eachcommunication).
								</li>
							</ul>
							In general, we will delete the Personal Data we collected from you if it is no longer
							necessary to keep such Personal Data to achieve the purposes for which they were originally
							collected. However, we may be required to store your Personal Data for a longer period due
							to requirements by law.
							<br/><br/>
							<strong>4. The confidentiality and security of your Personal Data</strong>
							<br/><br/>
							All Personal Data we are collecting from you is stored within a secured infrastructure under
							our management, with support of external suppliers as described in section 5 of this Policy.
							<br/><br/>
							We take data security very seriously. We apply an appropriate level of security and have
							therefore implemented reasonable physical, electronic, and administrative procedures to
							safeguard the information we collect from accidental or unlawful destruction, loss,
							alteration, unauthorized disclosure of, or access to Personal Data transmitted, stored or
							otherwise processed. Our information security policies and procedures are closely aligned
							with widely accepted international standards and are reviewed regularly and updated as
							necessary to meet our business needs, changes in technology, and regulatory requirements.
							Access to your Personal Data is granted only to those personnel, service providers or our
							affiliates with a business need-to-know or who require it in order to perform their duties.
							<br/><br/>
							Among other things, we optimize the security of your Personal Data by:
							<br/>
							<ul>
								<li>using encryption where appropriate;</li>
								<li>using password protection;</li>
								<li>requesting contractual guaranties and/or any other means of safeguard declaration
									from third parties;
								</li>
								<li>limiting the access to your Personal Data according to the need-to-know principle
									(e.g., only employees who need your Personal Data for the Purposes as described
									above shall receive permission to access them); and
								</li>
								<li>taking all reasonable precautionary measures to ensure that our employees and
									associates who have access to your Personal Data will be trained in data protection
									requirements and will process your Personal Data exclusively in accordance with this
									statement and our obligations under applicable privacy laws.
								</li>
							</ul>
							<br/>
							In the event of a data breach containing Personal Data, we will follow all applicable data
							breach notification laws.
							<br/><br/>
							<strong>5. To whom do we disclose your Personal Data?</strong>
							<br/><br/>
							We will disclose your Personal Data only for the purposes and to those third parties, as
							described below. We will take appropriate steps to ensure that your Personal Data is
							processed, secured, and transferred according to applicable law.
							<br/><br/>
							<strong>a) Within Bridgestone Group</strong>
							<br/><br/>
							We are part of a global organization (the “<strong>Bridgestone Group</strong>”) consisting
							of several companies in Thailand and abroad. Your Personal Data may be transferred to one or
							more Bridgestone Group affiliated companies located in or outside Thailand as needed for
							data processing and storage, providing you with access to our Services, providing customer
							support, making decisions about Service improvements, content development and for other
							Purposes as described in this Policy.
							<br/><br/>
							The above will be strictly connected with:
							<ul>
								<li>any kind of service that is rendered by one Bridgestone company to another (under
									relevant processing agreement); or
								</li>
								<li>the fact that more than one Bridgestone entity decides on how your Personal Data is
									used (under a relevant joint controllership agreement); or
								</li>
								<li>the fact that another Bridgestone entity becomes a separate controller of your data
									for a given Purpose (e.g. based on your specific consent).
								</li>
							</ul>
							<br/>
							<strong>b) External service providers</strong>
							<br/><br/>
							Where necessary, we will commission other companies and individuals to perform certain tasks
							contributing to our services on our behalf within the framework of data processing
							agreements. We may, for example, provide Personal Data to agents, contractors or partners
							for data processing services or to send you information that you requested. We will only
							share or make accessible such information with external service providers to the extent
							required to process your requests. This information may not be used by them for any other
							purposes, in particular not for their own or third party purposes. Our external service
							providers are contractually bound to respect the confidentiality of your Personal Data.
							<br/>
							<strong>c) Business transfers</strong>
							<br/><br/>
							In connection with any reorganization, restructuring, merger or sale, or other transfer of
							assets (collectively "<strong>Business Transfer</strong>"), we will transfer information,
							including Personal Data, in a reasonable scale and as necessary for the Business Transfer,
							and provided that the receiving party agrees to respect your Personal Data in a manner that
							is consistent with applicable data protection laws. We will continue to ensure the
							confidentiality of any Personal Data and give affected users notice before Personal Data
							becomes subject to a different privacy statement.
							<br/><br/>
							<strong>d) Public budies</strong>
							<br/><br/>
							We will only disclose your Personal Data to public bodies where this is required by law. We
							will for example respond to requests from courts, law enforcement agencies, regulatory
							agencies, and other public and government authorities, which may include such authorities
							outside your country of residence.
							<br/><br/>
							<strong>e) On other legal grounds</strong>
							<br/><br/>
							Further, we may disclose your Personal Data in order to protect our legitimate interests or
							if it is required or permitted by law or if you give your explicit consent for such transfer
							of your Personal Data.
							<br/><br/>
							<strong>f) International transfers of Personal Data</strong>
							<br/><br/>
							Under specific circumstances, it will also be necessary for us to transfer your Personal
							Data to foreign countries (“<strong>Third Countries</strong>"). Transfers to Third Countries
							may refer to all processing activities described in this Policy.
							<br/><br/>
							When transferring your Personal Data outside Thailand, we will comply with our legal and
							regulatory obligations in relation to your Personal Data, including having a lawful basis
							for transferring Personal Data and putting appropriate safeguards in place to ensure an
							adequate level of protection for the Personal Data. We shall make sure that the destination
							which your Personal Data has been sent to shall at least committed to the standard of the
							Thai laws.
							<br/><br/>
							Our lawful basis for the transfer will be either consent (i.e. we may ask for your consent
							to transfer your Personal Data outside Thailand at the time you provide your Personal Data)
							or one of the safeguards permissible by laws.
							<br/><br/>
							<strong>6. Direct Marketing</strong>
							<br/><br/>
							While using our Services, you may be asked to indicate whether you wish to receive certain
							marketing information by phone, text message, email and/or mail. If you do so, you thereby
							agree that we may use your Personal Data to provide you with information about its products,
							promotional activities and special offers as well as with any other information about our
							products or services.
							<br/><br/>
							At any given time, you may change your preferences regarding Direct Marketing by using the
							opt-out option contained in every direct marketing mailing, contacting us using the contact
							details provided below or, if applicable, by adapting your account information.
							<br/><br/>
							<strong>7. User rights with regard to Personal Data</strong>
							<br/><br/>
							As a data subject you have specific legal rights relating to the Personal Data we collect
							from you. This applies to all processing activities stipulated in this Policy. We will
							respect your individual rights and will deal with your concerns adequately.
							<br/><br/>
							The following list contains information on your legal rights which arise from applicable
							data protection laws:
							<ul>
								<li><strong>Right to be informed:</strong> you have the right to be informed about the
									collection and use of your Personal Data including our purposes for processing your
									Personal Data, retention periods for that Personal Data, and who we will share your
									Personal Data with.
								</li>
								<li><strong>Right to rectification:</strong> you may obtain from us rectification of
									Personal Data concerning you. We make reasonable efforts to keep Personal Data in
									our possession or control which are used on an ongoing basis, accurate, complete,
									current and relevant, based on the most recent information available to us. In
									appropriate cases, we provide self-service internet portals where users have the
									possibility to review and rectify their Personal Data.
								</li>
								<li><strong>Right to restriction:</strong> you may obtain from us restriction of
									processing of your Personal Data, if:
									<ul>
										<li>you contest the accuracy of your Personal Data for the period we need to
											verify the accuracy;
										</li>
										<li>the processing is unlawful and you request the restriction of processing
											rather than erasure of your Personal Data;
										</li>
										<li>we no longer need your Personal Data but you require such data for the
											establishment, exercise or defense of legal claims; or
										</li>
										<li>you object to the processing while we verify whether our legitimate grounds
											override yours.
										</li>
									</ul>
								</li>
								<li><strong>Right to access:</strong> you may ask from us information regarding Personal
									Data that we hold about you, including information as to which categories of
									Personal Data we have in our possession or control, what they are being used for,
									where we collected them, if not from you directly, and to whom they have been
									disclosed, if applicable. You may obtain from us one copy, free of charge, of
									Personal Data we hold about you. To the extent permitted by law, we reserve the
									right to charge a reasonable fee for each further copy you may request.
								</li>
								<li><strong>Right to portability:</strong> At your request, we will transfer your
									Personal Data to another controller, where technically feasible, provided that the
									processing is based on your consent or necessary for the performance of a contract.
									Rather than receiving a copy of your Personal Data you may request that we transfer
									the data to another controller, specified by you, directly.
								</li>
								<li><strong>Right to erasure: </strong>you may obtain from us erasure of your Personal
									Data or anonymization of your Personal Data, where:
									<ul>
										<li>the Personal Data are no longer necessary in relation to the Purposes for
											which they were collected or otherwise processed; or
										</li>
										<li>You withdraw the consent on which the collection or processing is based, and
											where we have no legal ground for such collection or processing; or
										</li>
										<li>You have a right to object further processing of your Personal Data (see
											below) and execute this right object to the processing; or
										</li>
										<li>the Personal Data has been unlawfully processed.</li>
									</ul>
									The above does not apply to the extent that such Personal Data retention is
									necessary for the purpose of freedom of expression, for the performance of a
									contract, the purpose of establishment, compliance or exercise of legal claims, or
									defense of legal claims, or other purposes permissible by and in compliance with the
									law. Furthermore, if you decide to revoke your permission given to Bridgestone to
									provide you with any marketing and advertising content, please note that we may
									still be required to send you emails, mails, calls, facsimiles and/or any other form
									of communication regarding factual transactional and/or service information in
									connection with products or services that Bridgestone provided to you or the
									organisation through whom you are known to us.
								</li>
								<li><strong>Right to object: </strong>you may object – at any time – to the processing
									of your Personal Data due to your particular situation, provided that the processing
									is not based on your consent but on our legitimate interests or those of a third
									party. In this event we shall no longer process your Personal Data, unless we can
									demonstrate compelling legitimate grounds and an overriding interest for the
									processing or for the establishment, exercise or defense of legal claims. If you
									object to the processing, please specify whether you wish the erasure of your
									Personal Data or the restriction of its processing by us.
								</li>
								<li><strong>Right to lodge a complaint: </strong>In case of an alleged infringement of
									applicable privacy laws, you may lodge a complaint with the data protection
									supervisory authority, including the Personal Data Protection Commission of
									Thailand.
								</li>
							</ul>
							To the extent permitted by law, we will try to fulfill your request within an appropriate
							period or any other period stipulated by the laws of Thailand. However, the period may be
							extended due to specific reasons relating to the specific legal right or the complexity of
							your request.
							<br/><br/>
							In certain situations we may not be able to give you access to all or some of your Personal
							Data due to statutory provisions. If we deny your request for access, we will advise you of
							the reason for the refusal.
							<br/><br/>
							If you believe that the processing of your Personal Data infringes your statutory rights,
							you have the right to lodge a complaint with a data protection supervisory authority.
							<br/><br/>
							<strong>8. Links</strong>
							<br/><br/>
							Our Services may, from time to time, contain hyperlinks to websites which are not controlled
							by us. Although we will do our utmost to make sure that the hyperlinks on the website lead
							exclusively to websites which share our safety and confidentiality standards, we are not
							responsible for the protection or confidentiality of any data you may submit on such other
							websites. Before submitting information on such sites, we recommend that you read their
							privacy policy and other statements in that regard.
							<br/><br/>
							<strong>9. Minors</strong>
							<br/><br/>
							We do not specifically target our Services at minors. However, we may from time to time
							collect and process Personal Data relating to individuals under the age of 20. Where we do
							so, we will comply with all applicable laws and regulations relating to the processing of
							Personal Data of minors.
							<br/><br/>
							However, if you are under the age of 10, you must ask a parent or guardian for permission
							before using our Services. Individuals under the age of 10 should not provide us with their
							Personal Data without the consent and supervision of their parent or guardian. Without such
							permission, we do not wish to save Personal Data from such individuals, nor process or
							forward such data to any third parties. If we become aware that Personal Data from under
							aged persons was inadvertently collected, we will delete such data without undue delay.
							<br/><br/>
							<strong>10. Sensitive Data</strong>
							<br/><br/>
							We will not process special categories of Personal Data concerning you ("Sensitive Data")
							except where we are able to do so under applicable laws or with your explicit consent.
							Sensitive Data refers to Personal Data pertaining to racial, ethnic origin, political
							opinions, cult, religious or philosophical beliefs, sexual behavior, criminal records,
							health data, disability, trade union information, genetic data, biometric data, or of any
							data which may affect the data subject in the same manner.
							<br/><br/>
							<strong>11. Period for Retaining the Personal Data</strong>
							<br/><br/>
							We will retain Personal Data for the period necessary to fulfil the Purposes for which the
							Personal Data was collected, or any other period regulated by the laws of Thailand
							(including but not limited to the PDPA). We may then destroy such files without further
							notice or liability.
							<br/><br/>
							If any Personal Data is only useful for a short period (e.g. for a specific event or
							marketing campaign or in relation to recruitment or security purposes, etc.), we may delete
							it at the end of that period.
							<br/><br/>
							If you have opted out of receiving marketing communications from us, we will need to retain
							certain Personal Data on a suppression list indefinitely so that we know not to send you
							further marketing communications in the future.
							<br/><br/>
							<strong>12. Changes to this Policy</strong>
							<br/><br/>
							To the extent permitted by law, we reserve the right, at our discretion, to modify our
							privacy practices and update and make changes to this Policy at any time. We may also make
							changes as required to comply with changes in applicable law or regulatory requirements. For
							this reason, we encourage you to refer to this Policy on an ongoing basis. This Policy is
							current as of the "last revised” date which appears at the top of this page. We will treat
							your Personal Data in a manner consistent with the Policy under which they were collected,
							unless we have your consent to treat them differently.
							<br/><br/>
							We will also keep prior versions of this Policy in an archive for your review.

						</p>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}