import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class HowToBuy extends React.Component {
	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<div className="header-bg">
						<h1>วิธีใช้งานเว็บไซต์</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">วิธีใช้งานเว็บไซต์</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div style={{paddingTop: 25}}>
							<div className="how-to-use-row">
								<div>
									<a href="./B-Give Website Manual for TBSC-BSTL.pdf">
										<img src={'./for-tbsc.jpg'} alt={"how to use"} style={{maxWidth: '100%'}}/>
									</a>
								</div>
								<div>
									<a href="./B-Give Website Manual for Bridgestone Thailand GCs.pdf">
										<img src={'./for-gcs.jpg'} alt={"how to use"} style={{maxWidth: '100%'}}/>
									</a>
								</div>
								<div>
									<a href="./B-GIVE website manual_for Teammates.pdf">
										<img src={'./for-teammates.jpg'} alt={"how to use"} style={{maxWidth: '100%'}}/>
									</a>
								</div>
							</div>

						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}