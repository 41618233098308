import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			form_mode: window.Global.getAllQueryStrings().p,
		};
	}

	componentDidMount() {
		window.Global.createCookie('relationship_account', '', -1);
	}

	render() {
		return (
			<>
				<Header hide_cart={false} {...this.props}/>
				<div className="body-content" style={{paddingBottom: 0}}>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">
									เข้าสู่ระบบ
								</div>
							</div>
						</div>
					</div>
					{/*<div className="ui divider" style={{marginTop: 0}}/>*/}
					<div className={"login-bg"}
					     style={{backgroundImage: "url('./login-bg.jpg')", backgroundColor: '#f4f4f4'}}>
						<div className="ui container">
							<div className={"ui grid"}>
								<div className={"row centered"}>
									<div
										className={"sixteen wide mobile eleven wide tablet six wide computer column"}>
										<div className={"ui segment sign-in"}>
											<form className={"ui large form"}
											      ref={(ref) => {
												      this.form = window.$(ref);
												      this.form.form2('submit', {
													      method: 'POST',
													      url: '/api/v1/relationship/account/login',
													      beforeSent: (object) => {
														      //console.log(object);
														      return object;
													      },
													      loadingButton: this.form.find('.button.approve'),
													      submitButton: this.form.find('.button.approve'),
													      onSuccess: (response) => {
														      console.log(response);
														      window.Global.messageTop({
															      type: 'positive',
															      message: 'เข้าสู่ระบบสำเร็จ'
														      });
														      let relationship_account_set_cookie = {};
														      relationship_account_set_cookie.id = response.relationship_account.id;
														      relationship_account_set_cookie.token = response.relationship_account.token;
														      relationship_account_set_cookie.relationship = {};
														      relationship_account_set_cookie.relationship.name = response.relationship_account.relationship.name;

														      window.Global.createCookie('relationship_account', JSON.stringify(relationship_account_set_cookie), 365);
														      window.localStorage.removeItem('products');
														      //console.log(Global2.getMyProfile());
														      window.location.href = window.location.origin + '/manage-my-account';
													      }
												      })
											      }}
											>
												<div className="field">
													<label>ชื่อผู้ใช้งาน / รหัสพนักงาน</label>
													<input type={"text"} name={"username"} required={true}/>
												</div>
												<div className="field">
													<label>รหัสผ่าน</label>
													<input type={"password"} name={"password"}/>
												</div>
												<div className="field GoogleRecaptcha_root"/>
												<div className="ui error message"/>
												<div className={"field flex row middle-xs wrap"}>
													<div className="flex-shrink">
														<button className="ui button brown right labeled icon approve"
														        type="submit">
															<i className="icon arrow right"/>
															เข้าสู่ระบบ
														</button>
													</div>
													<div className="right floated">
														<a className={"form-link"} href={'/forgot-password'}>ลืมรหัสผ่าน
															?</a>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}
