import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class PasswordReset extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show_message: false,
		};
	}

	render() {
		return (
			<>
				<Header hide_cart={false} {...this.props}/>
				<div className="body-content" style={{paddingBottom: 0}}>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">
									ตั้งรหัสผ่านใหม่
								</div>
							</div>
						</div>
					</div>
					{/*<div className="ui divider" style={{marginTop: 0}}/>*/}
					<div className={"login-bg"}
					     style={{backgroundImage: "url('./login-bg.jpg')", backgroundColor: '#f4f4f4'}}>
						<div className="ui container">
							<div className={"ui grid"}>
								<div className={"row centered"}>
									<div
										className={"sixteen wide mobile eleven wide tablet six wide computer column"}>

										<div className={"ui segment sign-in"}>
											<form className={"ui large form"}
											      ref={(ref) => {
												      window.$(ref).form2('setup');
												      window.$(ref).form2('submit', {
													      method: 'POST',
													      url: '/api/v1/e-commerce/password-reset',
													      beforeSent: (object) => {
														      return object;
													      },
													      loadingButton: window.$(ref).find('.button.approve'),
													      onSuccess: (response) => {
														      if (response.success) {
															      window.Global.messageTop({
																      node: window.$('body'),
																      type: 'positive',
																      message: response.message
															      });

															      let setTime = setTimeout(() => {
																      window.location = '/login';
																      clearTimeout(setTime)
															      }, 3000)
														      }
													      }
												      })
											      }}
											>
												<div className="field">
													{/*<h3 className={"ui header dividing"}>ลืมรหัสผ่าน</h3>*/}
													<p>ระบุรหัสผ่านใหม่</p>
												</div>
												<div className="field">
													<label>รหัสผ่านใหม่</label>
													<input type={"password"} name={"new_password"}/>
												</div>
												<div className="field">
													<label>ยืนยันรหัสผ่านใหม่</label>
													<input type={"password"} name={"confirm_new_password"}/>
												</div>
												<input type={"hidden"} name={"token"}
												       value={window.Global.getAllQueryStrings().t}/>
												<div className="field GoogleRecaptcha_root"/>
												<div className="ui error message"/>
												<div className={"field flex row middle-xs wrap"}>
													<div className="flex-shrink">
														<button
															className="ui button brown right labeled icon approve"
															type="submit">
															<i className="icon arrow right"/>
															ยืนยัน
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}