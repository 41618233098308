import React from 'react';
import {Modal} from "../component/Modal";

export default class EditName extends React.Component {
	constructor(props) {
		super(props);
		this.state = {...props};
	}

	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       closable={false}
				       onShowToSetupForm={($modal) => {
					       $modal.find('form').form2('setup');
					       $modal.find('.close.icon').remove();
					       $modal.find('form').form2('submit', {
						       method: 'PUT',
						       url: '/api/v1/e-commerce/relationship',
						       beforeSent: function (object) {
							       //console.log(object);
							       return object;
						       },
						       loadingButton: $modal.find('.button.approve'),
						       onSuccess: (response) => {
							       if (response.success) {
								       window.Global.messageTop({
									       type: 'positive',
									       message: 'แก้ไขข้อมูลสำเร็จ'
								       });
							       }
							       this.props.onSuccess(response);
							       $modal.modal('hide');
						       }
					       });
				       }}>
					<div className="header">
						<div className="flex flex-row">
							แก้ไขชื่อ/เลขประจำตัวผู้เสียภาษี
						</div>
					</div>
					<div className="content">
						<form className={"ui form"}>
							<div className="field">
								<label>ชื่อ</label>
								<input type={"text"} name={"name"} defaultValue={this.props.data.name}/>
							</div>
							<div className="field">
								<label>เลขประจำตัวผู้เสียภาษี</label>
								<input type={"text"} name={"tax_number"} defaultValue={this.props.data.tax_number}/>
							</div>
							<div className="ui error message"/>
						</form>
					</div>
					<div className="actions">
						<button className="ui deny basic button"
						        type={"button"}>
							ยกเลิก
						</button>
						<button className="ui approve blue button "
						        type={"button"}
						        style={{marginLeft: 15}}
						>
							ตกลง
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}
