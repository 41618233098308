import React from 'react';
import {Modal} from "../component/Modal";

export default class ChangePassword extends React.Component {
    render() {
        return (
            <div>
                <Modal className="ui tiny modal"
                       closable={false}
                       onShowToSetupForm={($modal) => {
                           $modal.find('form').form2('setup');
                           $modal.find('form').form2('submit', {
                               method: 'PUT',
                               url: '/api/v1/e-commerce/change-password',
                               beforeSent: function (object) {
                                   return object;
                               },
                               loadingButton: $modal.find('.button.approve'),
                               onSuccess: (response) => {
                                   if(response.success){
                                       window.Global.messageTop({
                                           type: 'positive',
                                           message: 'เปลี่ยนรหัสผ่านสำเร็จ'
                                       });
                                       this.props.onSuccess();
                                       $modal.modal('hide');
                                   }
                               }
                           });
                           $modal.find('.close.icon').remove();
                       }}
                       onDeny={()=> {this.props.onDeny();}}
                >
                    <div className="header">
                        <div className="flex flex-row">
                            เปลี่ยนรหัสผ่าน
                        </div>
                    </div>
                    <div className="content">
                        <form className={"ui form"}>
                            <div className="field">
                                <label>รหัสผ่านเดิม</label>
                                <input type={"password"} name={"old_password"}/>
                            </div>
                            <div className="field">
                                <label>รหัสผ่านใหม่</label>
                                <input type={"password"} name={"new_password"}/>
                            </div>
                            <div className="field">
                                <label>ยืนยันรหัสผ่านใหม่</label>
                                <input type={"password"} name={"confirm_new_password"}/>
                            </div>
                            <div className="field GoogleRecaptcha_root"/>
                            <div className="ui error message"/>
                        </form>
                    </div>
                    <div className="actions">
                        <button className="ui deny basic button"
                                type={"button"}>
                            ยกเลิก
                        </button>
                        <button className="ui approve blue button "
                                type={"button"}
                                style={{marginLeft: 15}}
                        >
                            ตกลง
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}
