import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			form_mode: window.Global.getAllQueryStrings().p,
			form_message: false,
			form_verify_captcha: false,
			is_was_click: false,
		};
	}

	componentDidMount() {
		if (window.segment[1] && window.segment[1] === 'change-email') {
			window.Global.ajax({
				method: 'POST',
				url: '/api/v1/e-commerce/old-email-verify-to-change-email',
				data: {
					token: window.Global.getAllQueryStrings().t,
				},
				done: (response) => {
					if (response.success) {
						this.setState({
							form_message: response.message,
							form_verify_captcha: false,
						})
					}
					if (response.error) {
						this.setState({
							form_verify_captcha: response.message[0].error_message,
						});
					}
				}
			});
		}
		if (window.segment[1] && window.segment[1] === 'comfirm-change-email') {
			window.Global.ajax({
				method: 'POST',
				url: '/api/v1/e-commerce/confirm-change-email',
				data: {
					token: window.Global.getAllQueryStrings().t,
				},
				done: (response) => {
					if (response.success) {
						this.setState({
							form_message: response.message,
							form_verify_captcha: false,
						})
					}
					if (response.error) {
						// console.log(response.message);
						this.setState({
							form_verify_captcha: response.message[0].error_message,
						});
					}
				}
			});
		}
	}

	render() {
		return (
			<>
				<Header hide_cart={false} {...this.props}/>
				<div className="body-content" style={{paddingBottom: 0}} ref={(ref) => {
					this.$content = window.$(ref)
				}}>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">
									ยืนยันเปลี่ยนอีเมล์
								</div>
							</div>
						</div>
					</div>
					{/*<div className="ui divider" style={{marginTop: 0}}/>*/}
					<div className={"login-bg"}
					     style={{backgroundImage: "url('./login-bg.jpg')", backgroundColor: '#f4f4f4'}}>
						<div className="ui container">
							<div className={"ui grid"}>
								<div className={"row centered"}>
									<div
										className={"sixteen wide mobile eleven wide tablet six wide computer column"}>
										{
											this.state.form_message ?
												<div className="ui success icon message">
													<i className="check icon"/>
													<div className="content">
														<h1 className="ui header" style={{textAlign: 'left'}}>
															เปลี่ยนอีเมล์
														</h1>
														<p>{this.state.form_message}</p>
													</div>
												</div>
												: null
										}
										{
											!this.state.form_verify_captcha
											&& !this.state.form_message ?
												<div className="ui info icon message">
													<i className="notched circle loading icon"/>
													<div className="content">
														<h1 className="ui header" style={{textAlign: 'left'}}>
															ยืนยันเปลี่ยนอีเมล์
														</h1>
														<p>ระบบกำลังทำการยืนยันเปลี่ยนอีเมล์</p>
													</div>
												</div>
												: null
										}
										{
											this.state.form_verify_captcha ?
												<form className={"ui form segment"}
												      ref={(ref) => {
													      let post_url = false;
													      if (window.segment[1] && window.segment[1] === 'change-email') {
														      post_url = '/api/v1/e-commerce/old-email-verify-to-change-email';
													      }
													      if (window.segment[1] && window.segment[1] === 'comfirm-change-email') {
														      post_url = '/api/v1/e-commerce/confirm-change-email';
													      }
													      window.$(ref).form2('setup');
													      window.$(ref).form2('submit', {
														      method: 'POST',
														      url: post_url,
														      beforeSent: (object) => {
															      return object;
														      },
														      loadingButton: this.$content.find('form').find('.button.approve'),
														      onSuccess: (response) => {
															      if (response.success) {
																      this.setState({
																	      message_form: response.message,
																	      form_verify_captcha: false,
																      });
																      if (response.error) {
																	      this.setState({
																		      form_verify_captcha: response.error_message,
																	      });
																      }
															      }
														      }
													      });
												      }}
												>
													<h3 className={"ui header"}>{this.state.form_verify_captcha}</h3>
													<input type={"hidden"} name={"token"}
													       value={window.Global.getAllQueryStrings().t}/>
													<div className="field GoogleRecaptcha_root"/>
													<div className="ui error message"/>
													<button type={"submit"}
													        className={"ui button blue approve"}>
														ตกลง
													</button>
												</form>
												: null
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}
