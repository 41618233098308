import React from 'react';
import Header from "../include/Header";
import PreviousLook from "./../PreviousLook";
import {
	SideBySideMagnifier
} from "react-image-magnifiers";
import Footer from "../include/Footer";
import {Global2} from "../component/Global";
import CartFunction from "../Function";

export default class Product extends React.Component {
	constructor() {
		super();
		this.state = {
			message_modal: false,
			is_loading: true,
			product_variation_link_selected: false,
			product_variation_selected: [],
			product_display_link: [],
			product_display: false,

			show_image: false,
			gallery_list: [],
		};
		this.Header = React.createRef();
	}

	componentDidMount() {
		this.fetch();
		this.setStore();
	}

	setStore() {
		let products = JSON.parse(window.localStorage.getItem('products')) || [];
		if (window.localStorage.getItem('products')) {
			let splice_index = 0;
			if (products.filter((product) => {
				return parseFloat(product) === parseFloat(this.props.product_id)
			}).length > 0) {
				splice_index = products.indexOf(this.props.product_id);
			}
			if (products.length === 5 || products.filter((product) => {
				return parseFloat(product) === parseFloat(this.props.product_id)
			}).length > 0) {
				products.splice(splice_index, 1);
			}
			products.push(this.props.product_id);
		}
		if (!window.localStorage.getItem('products')) {
			products.push(this.props.product_id);
		}
		window.localStorage.setItem('products', JSON.stringify(products));
	}

	fetch() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/e-commerce/v1/product_display',
			data: {
				product_display_id: this.props.product_id,
				request_data_product_display_using_filter: true,
			},
			// data: {product_display_id: 3},
			done: (response) => {
				// console.log(response);
				let gallery_list = [];
				if (response.product_display[0]) {
					response.product_display[0].product_display_link.forEach((product_display_link) => {
						if (product_display_link.product && product_display_link.product.product_gallery_file_upload) {
							product_display_link.product.product_gallery_file_upload.forEach((product_gallery_file_upload) => {
								product_gallery_file_upload.selected = false;
								gallery_list.push(product_gallery_file_upload);
							})
						}
					});
					if (gallery_list.length > 0) {
						gallery_list[0].selected = true;
					}
				}

				this.setState({
					count: response.count,
					product_display: response.product_display[0],
					gallery_list: gallery_list,
					show_image: gallery_list.length > 0 ? gallery_list[0].file_upload.url : false,
					is_loading: false,
				});
			}
		});
	}

	getMaxQuantity() {
		let max_qty = 10000000000;

		let qty = [];
		if (this.state.product_display_link.length === 0
			|| (this.state.product_display_link.length > 0 && this.state.product_variation_selected.length > 0)) {
			this.state.product_display.product_display_link.forEach((product_display_link) => {
				if (product_display_link.product) {
					qty.push(parseFloat(product_display_link.product.ready_to_sale.maximum_quantity));
				}
			})
		}
		qty = qty.filter((item, pos) => {
			return qty.indexOf(item) === pos;
		});
		if (qty.length > 0) {
			max_qty = Math.max.apply(null, qty).toString();
		}

		if (this.state.product_variation_link_selected) {
			max_qty = (this.state.product_variation_link_selected.product.ready_to_sale.maximum_quantity).toString();
		}
		// if(this.state.product_display_link.length === 0 && this.state.product_variation_selected.length === 0){
		// 	max_qty = 0;
		// }
		if (max_qty < 0) {
			max_qty = 0;
		}
		// console.log(max_qty);
		return parseFloat(max_qty);
	}

	getVariationItemPrice(is_display_text = false, options = {}) {
		// console.log(this.state.product_variation_selected);
		// console.log(this.state.product_display_link);
		// console.log(this.state.product_variation_link_selected);
		let data = {};
		if (this.state.product_variation_link_selected) {
			data = CartFunction.getPrice(
				[this.state.product_variation_link_selected],
				{
					return_type: 'object'
				}
			);
			if (is_display_text) {
				return {
					text: data.summary.total_net_price2.format(2, 3, {pretty: true})
				};
			}
			return data;
		}

		// let price = 0;
		// if (this.state.product_display_link.length > 0 && this.state.product_variation_selected.length > 0) {
		// 	price = CartFunction.getPrice(this.state.product_display_link);
		// }
		// if (this.state.product_display_link.length === 0) {
		// 	price = CartFunction.getPrice(this.state.product_display.product_display_link);
		// }
		// data.price = price;

		if (this.state.product_display_link.length > 0 && this.state.product_variation_selected.length > 0) {
			return {
				text: CartFunction.getPrice(this.state.product_display_link, {})
			}
		}
		if (this.state.product_display_link.length === 0) {
			return {
				text: CartFunction.getPrice(this.state.product_display.product_display_link, {})
			}
		}

		return data;

	}

	checkDisabledVariationItemButton(item) {
		let count_by_product_variation_item_id = 0;
		// let coutn_product_variation_id = 0;
		this.state.product_display_link.forEach((product_display_link) => {
			product_display_link.product_display_link_detail.forEach((product_display_link_detail) => {
				if (product_display_link_detail.product_variation_item_id === item.product_variation_item_id) {
					count_by_product_variation_item_id++;
				}
			})
		});

		if (this.state.product_variation_selected.length === 1
			&& this.state.product_variation_selected[0].product_display_using_product_variation_id === item.product_display_using_product_variation_id) {
			return 1;//disabled === false
		}
		if (
			(this.state.product_display_link.length === 0
				&& this.state.product_variation_selected.length === 0)
			|| count_by_product_variation_item_id > 0) {
			return 1; //disabled === false
		}
		return 0; //disabled === true
	}

	setClassSelected(item) {
		let count = this.state.product_variation_selected.filter((product_variation_selected) => {
			return product_variation_selected.product_variation_item_id === item.product_variation_item_id
		}).length;
		if (count > 0) {
			return "option-selected-thumbnail text selected";
		}
		if (this.checkDisabledVariationItemButton(item) === 0) {
			return "option-selected-thumbnail text disabled";
		}
		return "option-selected-thumbnail text";

	}

	selectVariationItem(item) {
		let product_variation_selected = [...this.state.product_variation_selected];
		let count = this.state.product_variation_selected.filter((product_variation_selected) => {
			return product_variation_selected.product_display_using_product_variation_id === item.product_display_using_product_variation_id
		}).length;
		let count_by_product_variation_item_id = this.state.product_variation_selected.filter((product_variation_selected) => {
			return product_variation_selected.product_variation_item_id === item.product_variation_item_id
		}).length;
		if (count > 0) {
			product_variation_selected = this.state.product_variation_selected.filter((product_variation_selected) => {
				return product_variation_selected.product_display_using_product_variation_id !== item.product_display_using_product_variation_id
			});
		}
		if (count === 0 || count_by_product_variation_item_id === 0) {
			product_variation_selected.push(item);
		}
		let product_display_link = [];
		let product_variation_link_selected = false;
		this.state.product_display.product_display_link.forEach((_product_display_link) => {
			let count_list = 0;
			_product_display_link.product_display_link_detail.forEach((_product_display_link_detail) => {
				product_variation_selected.forEach((_product_variation_selected) => {
					if (_product_variation_selected.product_variation_item_id === _product_display_link_detail.product_variation_item_id) {
						count_list++;
					}
				})
			});
			if (count_list !== 0
				&& _product_display_link.product_id) {
				product_display_link.push(_product_display_link);
			}
		});
		if (product_variation_selected.length === this.state.product_display.product_display_using_product_variation.length) {
			this.state.product_display.product_display_link.forEach((_product_display_link) => {
				let count_selected = 0;
				_product_display_link.product_display_link_detail.forEach((_product_display_link_detail) => {
					product_variation_selected.forEach((_product_variation_selected) => {
						if (_product_variation_selected.product_variation_item_id === _product_display_link_detail.product_variation_item_id
							&& _product_variation_selected.product_variation_item.product_variation_id === _product_display_link_detail.product_variation_id
						) {
							count_selected++;
						}
					})
				});
				if (count_selected === this.state.product_display.product_display_using_product_variation.length
					&& _product_display_link.product_id) {
					product_variation_link_selected = _product_display_link;
				}
			});
		}
		this.setState({
			product_variation_selected: product_variation_selected,
			product_display_link: product_display_link,
			product_variation_link_selected: product_variation_link_selected,
			qty: '1'
		});
	}

	addProductToCart(type) {
		let _type = false;
		if (type) {
			_type = type;
		}
		if (!Global2.getMyProfile().id) {
			window.Global.messageTop({
				type: 'negative',
				message: 'กรุณาเข้าสู่ระบบก่อนทำการสั่งซื้อ'
			});
			window.$(this.add_cart).removeClass('loading');
			window.$(this.buy_btn).removeClass('loading');
			window.$(this.add_cart).attr('disabled', false);
			window.$(this.buy_btn).attr('disabled', false);
			return;
		}
		if (!this.state.product_variation_link_selected) {
			window.Global.messageTop({
				type: 'negative',
				message: 'โปรดเลือกข้อมูลสินค้าให้ครบถ้วน'
			});
			window.$(this.add_cart).removeClass('loading');
			window.$(this.buy_btn).removeClass('loading');
			window.$(this.add_cart).attr('disabled', false);
			window.$(this.buy_btn).attr('disabled', false);
			return;
		}
		if (this.state.product_variation_link_selected.product.ready_to_sale.maximum_quantity <= 0) {
			window.Global.messageTop({
				type: 'negative',
				message: 'สินค้าหมด'
			});
			window.$(this.add_cart).removeClass('loading');
			window.$(this.buy_btn).removeClass('loading');
			window.$(this.add_cart).attr('disabled', false);
			window.$(this.buy_btn).attr('disabled', false);
			return;
		}


		let data = {};
		data.sale_order_item = {};
		data.sale_order_item.sale_order_item_product_display_link = {};
		data.sale_order_item.sale_order_item_product_display_link.product_display_link_id = this.state.product_variation_link_selected.id;
		data.sale_order_item.sale_order_item_product_display_link._quantity = this.state.qty;
		window.Global.ajax({
			method: 'POST',
			url: '/api/v1/sale_order/manage_item_cart',
			data: data,
			done: (response) => {
				// console.log(response);

				if (response.success) {
					window.Global.messageTop({
						type: 'positive',
						message: 'เพิ่มสินค้าลงตะกร้าสินค้าเรียบร้อยแล้ว'
					});
					this.Header.current.getSaleOrderViaEcomerce(response.sale_order_via_e_commerce);
				}
				if (response.error) {
					window.Global.messageTop({
						type: 'negative',
						message: response.message[0].error_message
					});
				}

				window.$(this.add_cart).removeClass('loading');
				window.$(this.buy_btn).removeClass('loading');
				window.$(this.add_cart).attr('disabled', false);
				window.$(this.buy_btn).attr('disabled', false);
				if (_type === 'checkout') {
					window.location.href = window.location.origin + '/checkout';
				}
			}
		});

	}

	render() {
		let default_item_sell = this.getVariationItemPrice(false);
		return (
			<>
				<Header borderBottom={true}
				        {...this.props}
				        ref={this.Header}
				/>
				<div className="body-content">
					<div className="ui divider" style={{marginTop: 0}}/>
					<div className={"ui container"}>
						{/*BREADCRUMB*/}
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<a className="section" href={window.location.origin + '/shop'}>รายการสินค้า</a>
								<i className="right chevron icon divider"/>
								<div className="active section">รายละเอียดสินค้า</div>
							</div>
						</div>
						{/*{GALLERY & DETAILS}*/}
						{
							this.state.is_loading ?
								<h2 className={"flex row middle-xs center-xs"} style={{marginTop: 15}}>
									<div className="ui active inline loader" style={{marginRight: 10}}/>
									กำลังโหลดข้อมูล
								</h2>
								: null
						}
						{
							!this.state.product_display
							&& !this.state.is_loading ?
								<>
									<h1 className={"ui header middle-xs center-xs"}
									    style={{marginTop: 15}}>ไม่พบข้อมูล</h1>
								</>
								:
								null
						}
						{
							this.state.product_display
							&& (this.state.product_display.is_disable
								|| this.state.product_display.is_void)
							&& !this.state.is_loading ?
								<>
									<h1 className={"ui header middle-xs center-xs"}
									    style={{marginTop: 15}}>ไม่พบรายการสินค้า</h1>
								</>
								: null
						}
						{
							this.state.product_display
							&& !this.state.product_display.is_disable
							&& !this.state.product_display.is_void
							&& !this.state.is_loading ?
								<>
									<div className="ui grid space">
										<div
											className="sixteen wide mobile eight wide tablet  eight wide computer column">
											<div className="ui grid responsive-column-reverse">
												<div className="sixteen wide tablet four wide computer column ">
													<ul className="gallery-list">
														{
															this.state.gallery_list
															&& this.state.gallery_list.length > 0 ?
																this.state.gallery_list.map((gallery, gallery_i) => {
																	return (
																		<li className={gallery.selected === true ? "selected" : null}
																		    key={gallery_i}>
																			<div className="gallery-frame"
																			     onClick={() => {
																				     let gallery_list = [...this.state.gallery_list];
																				     gallery_list.forEach((g, g_index) => {
																					     gallery_list[g_index].selected = false;
																				     });
																				     gallery_list[gallery_i].selected = true;
																				     this.setState({
																					     show_image: false,
																					     gallery_list: gallery_list,
																				     }, () => {
																					     this.setState({
																						     show_image: gallery.file_upload.url || gallery.file_upload.url
																					     })
																				     });
																				     return null
																			     }}
																			>
																				<img src={gallery.file_upload.url}
																				     alt="Product"/>
																			</div>
																		</li>
																	);
																})
																: null
														}
													</ul>
												</div>
												<div className="sixteen wide tablet twelve wide computer column">
													{
														this.state.show_image ?
															<SideBySideMagnifier
																className={"slider-image"}
																imageSrc={this.state.show_image}
																imageAlt="Example"
																largeImageSrc={this.state.show_image} // Optional
																transitionSpeed={0.3}
																style={{position: 'relative', zIndex: 10}}
																alwaysInPlace={true}
															/>
															: null
													}
												</div>
											</div>
										</div>
										<div
											className="sixteen wide mobile eight wide tablet eight wide computer column">
											<div className="product-detail" style={{marginBottom: 15}}>
												<h2>{this.state.product_display.name}</h2>
												<div className="price">
													{
														default_item_sell.discount
														&& parseFloat(default_item_sell.discount) > 0
															?
															<div style={{
																textDecoration: 'line-through',
																fontSize: 16,
																color: '#999'
															}}>฿{CartFunction.addTaxToPrice(default_item_sell.price)}</div>
															:
															<div>฿{this.getVariationItemPrice(true).text}</div>
													}
													{
														default_item_sell.discount
														&& parseFloat(default_item_sell.discount) > 0 ? (
															<>
																<div
																	style={{color: 'green', fontSize: 30}}>
																	{/*฿{this.getVariationItemPrice().summary.total_net_price.format(2, 3, {pretty: true})}*/}
																	฿{this.getVariationItemPrice(true).text}
																</div>
																<div
																	className={"ui label red small"}
																	ref={(ref) => {
																		if (default_item_sell.used_product_price_condition
																			&& default_item_sell.used_product_price_condition.description
																		) {
																			window.$(ref).popup({
																				position: 'bottom left',
																				content: default_item_sell.used_product_price_condition.description
																			})
																		}
																	}}
																>
																	ส่วนลด {default_item_sell.discount_type === "฿" ? "฿" : null}{default_item_sell.discount.format(2, 3, {pretty: true})}{default_item_sell.discount_type === "%" ? "%" : null}
																</div>
															</>
														) : null
													}
												</div>
												{/*{*/}
												{/*this.state.product_display.description ?*/}
												{/*<div className="product-description">*/}
												{/*{this.state.product_display.description}*/}
												{/*</div>*/}
												{/*: null*/}
												{/*}*/}
											</div>
											<div className={"ui divider"}/>
											<div className="ui form">
												{
													this.state.product_display.product_display_using_product_variation.map((_option, _index) => {
														return (
															<div className={"inline fields flex-start"} key={_index}>
																<div className={"three wide field"}>
																	<label>{_option.product_variation.name} : </label>
																</div>
																<div className={"thirteen wide field"}>
																	<div className="options-info">
																		{/*<label>{_option.size_type ? _option.size_type : this.showSelectedTitle(_option)}</label>*/}
																		<div className="options-selected">
																			{
																				_option.product_display_using_product_variation_item.map((item, item_i) => {
																					// if (item.url) {
																					//     return (
																					//         <button key={item_i}
																					//                 className={item.selected ? "option-selected-thumbnail selected" : "option-selected-thumbnail"}
																					//                 onClick={() => {
																					//                     let options = [...this.state.options];
																					//                     options[_index].items.forEach((_item, _item_i) => {
																					//                         if (_item.selected) {
																					//                             options[_index].items[_item_i].selected = false;
																					//                         }
																					//                     });
																					//                     options[_index].items[item_i].selected = true;
																					//                     let more_gallery = [];
																					//                     more_gallery = options[_index].items[item_i].gallery;
																					//                     this.setState({
																					//                         options: options,
																					//                         more_gallery: more_gallery
																					//                     })
																					//                 }}
																					//                 data-variation="inverted"
																					//                 data-position={"top center"}
																					//                 data-content={item.title}
																					//                 ref={(ref) => {
																					//                     window.$(ref).popup({
																					//                         transition: 'scale'
																					//                     });
																					//                 }}
																					//         >
																					//             <img alt={"Product thumbnail"}
																					//                  src={item.url}/>
																					//         </button>
																					//     );
																					// }
																					return (
																						<button key={item_i}
																						        className={this.setClassSelected(item)}
																						        onClick={() => {
																							        if (this.checkDisabledVariationItemButton(item) === 0) {
																								        return null;
																							        }
																							        this.selectVariationItem(item);
																						        }}
																						>
																							{item.product_variation_item.name}
																						</button>
																					);
																				})
																			}
																		</div>
																	</div>
																</div>
															</div>
														);
													})
												}
												<div className={"inline fields flex-start"}>
													<div className={"three wide field"}>
														<label>จำนวน : </label>
													</div>
													<div className={"thirteen wide field"}>
														<div className="field qty-input">
															<div className="ui left action input">
																<button className="ui icon button"
																        onClick={() => {
																	        let qty = parseFloat(this.state.qty) - 1;
																	        if (qty < 0 || isNaN(qty)) {
																		        qty = 0;
																	        }
																	        this.setState({
																		        qty: qty + ''
																	        })
																        }}
																        disabled={this.getMaxQuantity() === 0 || !this.state.product_variation_link_selected || (this.state.qty <= 0 || !this.state.qty)}
																>
																	<i className="minus icon"/>
																</button>
																<input type="text" name="quantity"
																       placeholder="Quantity"
																       value={this.getMaxQuantity() === 0 ? 0 : this.state.qty || 1}
																       disabled={this.getMaxQuantity() === 0 || !this.state.product_variation_link_selected || !this.state.qty}
																       onChange={(event) => {
																	       // console.log(event.target.value);

																	       let qty = parseFloat(event.target.value);

																	       if (qty > this.getMaxQuantity()) {
																		       qty = this.getMaxQuantity();
																	       }
																	       if (isNaN(parseFloat(event.target.value))) {
																		       qty = 0;
																	       }
																	       this.setState({
																		       qty: qty + ''
																	       })
																       }}
																/>
																<button className="ui right icon button"
																        onClick={() => {
																	        this.setState({
																		        qty: (parseFloat(this.state.qty) + 1) + ''
																	        })
																        }}
																        disabled={!this.state.product_variation_link_selected || (this.state.qty >= this.getMaxQuantity() || !this.state.qty)}
																>
																	<i className="plus icon"/>
																</button>
															</div>
															{
																this.getMaxQuantity() ?
																	<span>
																		มีสินค้าทั้งหมด {this.getMaxQuantity().format(2, 3, {pretty: true})} ชิ้น
																	</span>
																	: null
															}
															{
																this.getMaxQuantity() === 0 ?
																	<span>
																		สินค้าหมด
																	</span>
																	: null
															}
														</div>
													</div>
												</div>

												{
													Global2.getMyProfile().id ?
														<div className="field">
															<button className="ui button green"
															        onClick={() => {
																        window.$(this.add_cart).addClass('loading');
																        window.$(this.add_cart).attr('disabled', true);
																        this.addProductToCart();
															        }}
															        ref={(ref) => {
																        this.add_cart = ref;
															        }}
															        disabled={
																        (
																	        !this.state.product_variation_link_selected
																	        || this.state.qty <= 0
																        )
																        ||
																        (
																	        this.state.product_variation_link_selected
																	        && this.state.product_variation_link_selected.product.ready_to_sale.maximum_quantity <= 0
																        )
															        }
															>
																<i className="cart plus icon"/> เพิ่มไปยังรถเข็น
															</button>
															{/*<button className="ui button blue"*/}
															{/*        onClick={() => {*/}
															{/*	        window.$(this.buy_btn).addClass('loading');*/}
															{/*	        window.$(this.buy_btn).attr('disabled', true);*/}
															{/*	        this.addProductToCart('checkout');*/}

															{/*        }}*/}
															{/*        ref={(ref) => {*/}
															{/*	        this.buy_btn = ref;*/}
															{/*        }}*/}
															{/*>*/}
															{/*	<i className="handshake icon"/> ซื้อสินค้า*/}
															{/*</button>*/}
														</div>
														: null
												}
											</div>
											{
												!Global2.getMyProfile() ?
													<div className="ui warning message">
														กรุณาเข้าสู่ระบบก่อนทำการเลือกสินค้า
													</div>
													: null
											}
											<div className="ui divider"/>
											<div className={"flex flex-row flex-wrap"}
											     style={this.state.product_display.description ? {marginBottom: 15} : null}>
												<div className={"flex-shrink"}>
													{/*this.props.product_display.product_display_using_filter[0].filter.root_parent_filter_id*/}

													{
														this.state.product_display.product_display_using_filter
														&& this.state.product_display.product_display_using_filter.length > 0 ?
															<div className={'category-list'}>
															<span style={{
																display: 'inline-block',
																marginRight: 5
															}}>ประเภท :</span>
																{
																	this.state.product_display.product_display_using_filter.map((product_display_using_filter, i) => {
																		return (
																			<a href={window.location.origin + '/shop?filter_id=' + product_display_using_filter.filter.root_parent_filter_id}
																			   key={i}>
																				{i !== 0 ? ' , ' : null}{product_display_using_filter.filter.name}

																			</a>
																		);
																	})
																}
															</div>
															: null
													}
												</div>
												{/*<div className="right floated ">*/}
												{/*	<div className="row flex middle-xs">*/}
												{/*	<span*/}
												{/*		style={{display: 'inline-block', marginRight: 5}}>แชร์ :</span>*/}
												{/*		<EmailShareButton>*/}
												{/*			<EmailIcon size={35} round={true} style={{marginLeft: 3}}/>*/}
												{/*		</EmailShareButton>*/}
												{/*		<FacebookShareButton url={window.location}*/}
												{/*		                     quote={'Facebook'}>*/}
												{/*			<FacebookIcon size={35} round={true}*/}
												{/*			              style={{marginLeft: 3}}/>*/}
												{/*		</FacebookShareButton>*/}
												{/*		<LineShareButton url={window.location}>*/}
												{/*			<LineIcon size={35} round={true}*/}
												{/*			          style={{marginLeft: 3}}/>*/}
												{/*		</LineShareButton>*/}
												{/*		<TwitterShareButton url={window.location}>*/}
												{/*			<TwitterIcon size={35} round={true}*/}
												{/*			             style={{marginLeft: 3}}/>*/}
												{/*		</TwitterShareButton>*/}
												{/*		<WhatsappShareButton url={window.location}>*/}
												{/*			<WhatsappIcon size={35} round={true}*/}
												{/*			              style={{marginLeft: 3}}/>*/}
												{/*		</WhatsappShareButton>*/}

												{/*	</div>*/}
												{/*</div>*/}
											</div>
											{/*DESCRIPTION*/}
											{
												this.state.product_display.description ?
													<>
														<div className={"pd-tabs-title"}>
															<ul>
																<li className={"selected"}>รายละเอียด</li>
															</ul>
														</div>
														<div
															className="pd-tabs-detail white-space-pre-line">{this.state.product_display.description}</div>
													</>
													: null
											}
										</div>
									</div>
									{
										Global2.getMyProfile() ?
											<>
												<div className={"ui divider"}/>
												{/*{YOU MAY ALSO LIKE}*/}
												<PreviousLook current_product_id={this.props.product_id}/>
											</>
											: null
									}

								</>
								: null
						}
					</div>
				</div>
				{this.state.message_modal}
				<Footer/>
			</>
		);
	}
}
