import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";
import ManageMenu from "../component/ManageMenu";
import CartFunction from '../Function';
import {Global2} from "../component/Global";

export default class OrderDetails extends React.Component {
	constructor() {
		super();
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			sale_order_via_e_commerce: false,
			is_loading: true
		}
	}

	componentDidMount() {
		if (!Global2.getMyProfile()) {
			window.location = window.location.origin;
		}
		this.fetch();
	}

	fetch() {
		let data = {};
		data = this.state.qs;
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/sale_order_via_e_commerce',
			data: data,
			done: (response) => {
				window.$(this.$parent).find('#sale-order-displays').tablePagination({
					// disableContent: 1,
					data: response.sale_order_via_e_commerce,
					count: response.count,
				});
				this.setState({
					count: response.count,
					sale_order_via_e_commerce: response.sale_order_via_e_commerce[0],
					is_loading: false
				});
			}
		});
	}

	render() {
		let payment_list = [];
		if (this.state.sale_order_via_e_commerce.sale_order && this.state.sale_order_via_e_commerce.sale_order.payment) {
			this.state.sale_order_via_e_commerce.sale_order.payment.forEach((_payment) => {
				_payment.payment.forEach((__payment) => {
					payment_list.push(__payment.payment_by2);
				})
			});
		}
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					{/*BREADCRUMB*/}
					<div className="header-bg">
						<h1>รายการสั่งซื้อ
							{/*<span>{this.state.order.code}</span>*/}
						</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>Home</a>
								<i className="right chevron icon divider"/>
								<a className="section"
								   href={window.location.origin + '/my-purchase'}>การซื้อของฉัน</a>
								<i className="right chevron icon divider"/>
								<div className="active section">รายการสั่งซื้อ</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0}}/>
					<div className="ui container">
						<div className="ui grid">
							<div className="row">
								<div className="sixteen wide mobile four wide tablet three wide computer column">
									<ManageMenu page={this.props.page}/>
								</div>
								<div className="sixteen wide mobile twelve wide tablet thirteen wide computer column">
									{
										this.state.is_loading ?
											<div className={"flex row middle-xs center-xs"}
											     style={{paddingTop: 20, paddingBottom: 20}}>
												<h2 className={"ui header"}>
													<span className="ui active inline loader"
													      style={{marginRight: 10}}/>
													กำลังโหลดข้อมูล
												</h2>
											</div>
											: null
									}
									{
										!this.state.is_loading
										&& this.state.sale_order_via_e_commerce.sale_order ?
											<>
												<div className="ui segment flex row middle-xs wrap">
													<h2 className="ui header flex-shrink" style={{margin: 0}}>
														รายการสั่งซื้อ <span
														style={{color: '#a5673f'}}>{this.state.sale_order_via_e_commerce.sale_order.desc}</span>
														<span
															className="sub header">สั่งซื้อวันที่ {window.Global.getDateThai(this.state.sale_order_via_e_commerce.sale_order.doc_date)}</span>
													</h2>
													<div className="right floated order-details-total-price">
														<span style={{color: 'rgba(0,0,0,.6)'}}>รวมทั้งสิ้น </span>
														<strong> ฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price.format(2, 3, {pretty: false}).toString()}</strong>
													</div>
												</div>
												<div className="ui segment">
													{
														this.state.sale_order_via_e_commerce
														&& this.state.sale_order_via_e_commerce.sale_order.is_draft === 0 ?
															<div className="steps">
																<div className={
																	this.state.sale_order_via_e_commerce.sale_order.paid_status === 'some_part' ? "step step-active"
																		: this.state.sale_order_via_e_commerce.sale_order.paid_status === 'full' ? "step step-done"
																		: 'step'
																}>
																	<div className="step-connection">
																		<div className="step-connector-left"/>
																		<div className="step-number"/>
																		<div className="step-connector-right ">
																			<div className="step-line"/>
																		</div>
																	</div>
																	<div className="step-title">
																		{
																			this.state.sale_order_via_e_commerce.sale_order.paid_status === 'some_part' ? "ชำระเงินแล้วบางส่วน"
																				: this.state.sale_order_via_e_commerce.sale_order.paid_status === 'full' ? "ชำระเงินเรียบร้อย"
																				: 'รอชำระเงิน'
																		}
																	</div>
																</div>

																<div className={
																	this.state.sale_order_via_e_commerce.sale_order.item_shipment_status === 'some_part' ? "step step-active"
																		: this.state.sale_order_via_e_commerce.sale_order.item_shipment_status === 'full' ? "step step-done"
																		: 'step'
																}>
																	<div className="step-connection">
																		<div className="step-connector-left">
																			<div
																				className={this.state.sale_order_via_e_commerce.sale_order.paid_status === 'full' ? "step-line active"
																					: 'step-line'
																				}/>
																		</div>
																		<div className="step-number"/>
																		<div className="step-connector-right">
																			<div className="step-line"/>
																		</div>
																	</div>
																	<div className="step-title">
																		{
																			this.state.sale_order_via_e_commerce.sale_order.item_shipment_status === 'some_part' ? "ส่งสินค้าเงินแล้วบางส่วน"
																				: this.state.sale_order_via_e_commerce.sale_order.item_shipment_status === 'full' ? "ส่งสินค้าครบถ้วน"
																				: 'รอส่งสินค้า'
																		}
																	</div>
																</div>

																<div className={
																	this.state.sale_order_via_e_commerce.sale_order.item_shipment_status === 'full' ? "step step-active"
																		: 'step'
																}>
																	<div className="step-connection">
																		<div className="step-connector-left">
																			<div
																				className={this.state.sale_order_via_e_commerce.sale_order.item_shipment_status === 'full' ? "step-line active"
																					: 'step-line'
																				}/>
																		</div>
																		<div className="step-number"/>
																		<div className="step-connector-right"/>
																	</div>
																	<div className="step-title">
																		รับสินค้าเรียบร้อย
																	</div>
																</div>
															</div>
															: null
													}
													{
														this.state.sale_order_via_e_commerce
														&& this.state.sale_order_via_e_commerce.sale_order.is_draft === 1 ?
															<div className="ui warning message center-xs">
																<p>
																	<i className="clipboard check icon"/>
																	การสั่งซื้อสมบูรณ์ อยู่ระหว่างการตรวจสอบและรออนุมัติ
																</p>
															</div>
															: null
													}
													{
														this.state.sale_order_via_e_commerce.sale_order.paid_status !== 'full'
														&& this.state.sale_order_via_e_commerce.sale_order.pay_by_credit_card_button ?
															<div className={"center-xs"}>
																<button className={"ui button green"}
																        onClick={() => {
																	        window.$('form[name="payForm"]').remove();
																	        window.$('body').append(this.state.sale_order_via_e_commerce.sale_order.pay_by_credit_card_button);
																        }}
																>
																	ชำระเงินด้วยบัตรเครดิต
																</button>
															</div>
															: null
													}

													<div className={"cart resp-table"}>
														<table className="ui very basic table">
															<tbody>
															{
																this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, sale_order_item_index) => {
																	return (
																		<tr key={sale_order_item_index}>
																			<td>
																				<div className={"cart-pd"}>
																					<div className="cart-image">
																						<img
																							src={sale_order_item.product.product_image_file_upload.url}
																							alt={"Product"}/>
																					</div>
																					<div className="ui tiny header"
																					     style={{
																						     margin: 0,
																						     width: '100%'
																					     }}>
																						<a href={'/product?product_id=' + sale_order_item.sale_order_item_product_display_link.product_display_link.product_display_id}>{sale_order_item.product.name}</a>
																						{
																							sale_order_item.sale_order_item_product_display_link ?
																								<div
																									className="sub header custom-sub-header">
																									{
																										CartFunction.showProductDisplayLinkDetail(sale_order_item.sale_order_item_product_display_link.product_display_link.product_display_link_detail)
																									}
																								</div>
																								: null
																						}
																					</div>
																				</div>
																			</td>
																			<td>
																				{
																					'฿' + sale_order_item.summary.price_after_discount.format(2, 3, {pretty: false}).toString()
																				}
																			</td>
																			<td>
																				{
																					Global2.getQuantity(sale_order_item)
																				}
																			</td>
																			<td>
																				{
																					'฿' + sale_order_item.summary.total_net_price.format(2, 3, {pretty: false}).toString()
																				}
																			</td>
																		</tr>
																	)
																})
															}
															</tbody>
														</table>
														{
															this.state.sale_order_via_e_commerce
															&& this.state.sale_order_via_e_commerce.sale_order
															&& this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark ?
																<div>
																	<strong>หมายเหตุ : </strong>
																	{this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark}
																</div>
																: null
														}
													</div>
												</div>
												<div className="ui grid">
													<div
														className="sixteen wide mobile eight wide tablet eight wide computer column">
														{
															this.state.sale_order_via_e_commerce
															&& this.state.sale_order_via_e_commerce.sale_order.shipment
															&& this.state.sale_order_via_e_commerce.sale_order.shipment.length > 0 ?
																<div className="ui segment">
																	<h3 className="ui header dividing">
																		{this.state.sale_order_via_e_commerce.sale_order.shipment[0].title}
																	</h3>
																	<div>
																		{
																			this.state.sale_order_via_e_commerce.sale_order.shipment[0]
																			&& this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_name ?
																				this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_name
																				: null
																		}
																	</div>
																	{
																		this.state.sale_order_via_e_commerce.sale_order.shipment[0]
																		&& this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_address_line_1 ?
																			<div>
																				{this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_address_line_1}
																			</div>
																			: null
																	}
																	{
																		this.state.sale_order_via_e_commerce.sale_order.shipment[0]
																		&& this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_address_line_2 ?
																			<div>
																				{this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_address_line_2}
																			</div>
																			: null
																	}
																	{
																		this.state.sale_order_via_e_commerce.sale_order.shipment[0]
																		&& this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_address_line_3 ?
																			<div>
																				{this.state.sale_order_via_e_commerce.sale_order.shipment[0].ship_to_address_line_3}
																			</div>
																			: null
																	}
																</div>
																: null
														}
														{
															this.state.sale_order_via_e_commerce
															&& this.state.sale_order_via_e_commerce.sale_order.payment
															&& this.state.sale_order_via_e_commerce.sale_order.payment.length > 0 ?
																<div className="ui segment">
																	<h3 className="ui header dividing flex row">
                                                                        <span>
                                                                            {this.state.sale_order_via_e_commerce.sale_order.payment[0].title}
	                                                                        <span className={"sub header"}>
                                                                                {this.state.sale_order_via_e_commerce.sale_order.desc}
                                                                            </span>
                                                                        </span>
																		<span className="right floated">
                                                                            {
	                                                                            this.state.sale_order_via_e_commerce.sale_order.payment[0]
	                                                                            && this.state.sale_order_via_e_commerce.sale_order.payment[0].send_mail
	                                                                            && this.state.sale_order_via_e_commerce.sale_order.payment[0].send_mail[0] ?
		                                                                            <button
			                                                                            className={"ui label green mini"}
			                                                                            style={{cursor: 'pointer'}}
			                                                                            onClick={() => {
				                                                                            window.$('body').append('<iframe src="' + this.state.sale_order_via_e_commerce.sale_order.payment[0].send_mail[0].file_upload.url + '" style="width: 0;height: 0;"></iframe>');
			                                                                            }}
		                                                                            >
			                                                                            ดาวน์โหลด
		                                                                            </button>
		                                                                            : null
                                                                            }
                                                                        </span>
																	</h3>
																	<div>
																		{
																			this.state.sale_order_via_e_commerce.sale_order.payment[0]
																			&& this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_company_name ?
																				this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_company_name
																				: null
																		}
																		{
																			this.state.sale_order_via_e_commerce.sale_order.payment[0]
																			&& this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_branch_name ?
																				<span>
																			{' ' + this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_branch_name + ' '}
																					{
																						this.state.sale_order_via_e_commerce.sale_order.payment[0]
																						&& this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_branch_code ?
																							<>({this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_branch_code})</>
																							: null
																					}
																		</span>
																				: null
																		}
																	</div>
																	{
																		this.state.sale_order_via_e_commerce.sale_order.payment[0]
																		&& this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_bill_line_1 ?
																			<div>
																				{this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_bill_line_1}
																			</div>
																			: null
																	}
																	{
																		this.state.sale_order_via_e_commerce.sale_order.payment[0]
																		&& this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_bill_line_2 ?
																			<div>
																				{this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_bill_line_2}
																			</div>
																			: null
																	}
																	{
																		this.state.sale_order_via_e_commerce.sale_order.payment[0]
																		&& this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_tax_number ?
																			<div>
																				{this.state.sale_order_via_e_commerce.sale_order.payment[0].buyer_tax_number}
																			</div>
																			: null
																	}
																</div>
																: null
														}

													</div>
													<div
														className="sixteen wide mobile eight wide tablet eight wide computer column">
														<div className="ui segment">
															<h3 className="ui header dividing">สรุปราคา</h3>
															<div className="summary-list">
																<div className="flex row">
																	<div>รวมเป็นเงิน</div>
																	<div className="right floated">
																		<strong>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.total_price_before_discount).format(2, 3, {pretty: false}).toString()}</strong>
																	</div>
																</div>
																<div className="flex row">
																	<div>ส่วนลดรวม</div>
																	<div className="right floated">
																		<strong>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.discount_amount).format(2, 3, {pretty: false}).toString()} (0%)</strong>
																	</div>
																</div>
																<div className="flex row">
																	<div>รวมมูลค่าสินค้า</div>
																	<div className="right floated">
																		<strong>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.total_price).format(2, 3, {pretty: false}).toString()}</strong>
																	</div>
																</div>
																<div className="flex row">
																	<div>ภาษีมูลค่าเพิ่ม {this.state.sale_order_via_e_commerce.sale_order.summary.tax}%</div>
																	<div className="right floated">
																		<strong>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.total_tax_price).format(2, 3, {pretty: false}).toString()}</strong>
																	</div>
																</div>
																<div className="flex row">
																	<div>ราคาไม่รวมภาษีมูลค่าเพิ่ม</div>
																	<div className="right floated">
																		<strong>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.total_price_exclude_vat).format(2, 3, {pretty: false}).toString()}</strong>
																	</div>
																</div>
																<div className="flex row">
																	<div>จำนวนเงินรวมทั้งสิ้น</div>
																	<div className="right floated">
																		<strong>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price_without_withholding_tax).format(2, 3, {pretty: false}).toString()}</strong>
																	</div>
																</div>
															</div>
															<div className="ui divider"/>
															<div className="flex row middle-xs">
																<div>ยอดชำระ</div>
																<div className="right floated">
																	<strong
																		style={{fontSize: 20}}>{'฿' + (this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price).format(2, 3, {pretty: false}).toString()}</strong>
																</div>
															</div>
															{
																this.state.sale_order_via_e_commerce.sale_order
																&& this.state.sale_order_via_e_commerce.sale_order.payment ?
																	payment_list.map((payment, i) => {
																		return (
																			<div key={i}
																			     style={{marginTop: 15}}>
																				ชำระด้วย <strong>{payment}</strong>
																			</div>
																		);
																	})
																	: null
															}

														</div>
													</div>
												</div>
											</>
											: null
									}


								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}
