import React from 'react';
import {Global2} from "../component/Global";

export default class Footer extends React.Component {
    render() {
        // console.log(Global2.getMyProfile());
        return (
            <>
                <footer>
                    <div className="ui container">
                        <div className="footer-row">
                            <div className="ui grid center-xs">
                                <div className="sixteen wide mobile six wide tablet ui five wide computer column">
                                    <div className="footer-info">
                                        {/*<h3 style={{marginBottom: 4}}>*/}
                                        {/*    <strong><img src={'./B-Give-final-logo-wh.png'} alt={"logo"} style={{maxWidth: '100%'}}/></strong>*/}
                                        {/*</h3>*/}
                                        <div className="footer-description">
                                            <h3>ข้อมูลติดต่อเรา</h3>
                                            ฝ่ายสื่อสารองค์กรและสร้างสรรค์ผลลัพธ์ที่ดีต่อสังคม
                                            <br/>
                                            บริษัท ไทยบริดสโตน จำกัด 990 อาคารอับดุลราฮิม
                                            <br/>ชั้น 16 ถนนพระรามสี่ แขวงสีลม เขตบางรัก กรุงเทพมหานคร 10500
                                            <br/>
                                            โทร : 0 2636-1505 ถึง 32 ต่อ 2383 (อำพรพรรณ)
                                            <br/>
                                            อีเมล์ : <strong>amphonphan.khokanklang@bridgestone.com</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="sixteen wide mobile five wide tablet ui three wide computer column">
                                    <h3 style={{textAlign: 'left'}}>ข้อมูล</h3>
                                    <ul className={"footer-list"}>
                                        <li><a className={window.segment[1] === 'about' ? 'active' : null} href={"https://www.bridgestone.co.th"}>เกี่ยวกับ Bridgestone</a>
                                        </li>
                                        <li><a className={window.segment[1] === 'how-to-buy' ? 'active' : null} href={window.location.origin + '/how-to-buy'}>วิธีสั่งซื้อ</a></li>
                                        <li><a className={window.segment[1] === 'how-to-use' ? 'active' : null} href={window.location.origin + '/how-to-use'}>วิธีใช้งานเว็บไซต์</a></li>
                                        <li><a className={window.segment[1] === 'faq' ? 'active' : null} href={window.location.origin + '/faq'}>คำถามที่พบบ่อย</a></li>
                                        <li><a className={window.segment[1] === 'privacy-policy' ? 'active' : null}
                                            href={window.location.origin + '/privacy-policy'}>นโยบายความเป็นส่วนตัว</a>
                                        </li>
                                        {/*<li>ติดต่อเรา</li>*/}
                                    </ul>
                                </div>
                                <div className="sixteen wide mobile five wide tablet ui three wide computer column">
                                    <h3>ช่องทางการชำระเงิน</h3>
                                    <ul className={"payment-way"}>
                                        <li><img src={'./visa.png'} alt={"logo"}/></li>
                                        <li><img src={'./mastercard.png'} alt={"logo"}/></li>
                                        <li><img src={'./JCB.png'} alt={"logo"}/></li>
                                        <li><img src={'./unionpay.png'} alt={"logo"}/></li>
                                        <li><img src={'./TPN-Logo-Standard-660x317.png'} alt={"logo"}/></li>
                                    </ul>

                                </div>
                                <div className="sixteen wide mobile five wide tablet ui three wide computer column">
                                    <h3 >ช่องทางการจัดส่ง</h3>
                                    <img src={'./scg-express.jpg'} alt={"logo"} style={{maxWidth: '100%'}}/>
                                    <a className={'header-footer-h3'}
                                       href={window.location.origin + '/warehouse-map'}>แผนที่คลังสินค้า</a>
                                </div>
                                <div className="sixteen wide mobile four wide tablet ui two wide computer column">
                                    <h3 style={{textAlign: 'left'}}>บัญชีของฉัน</h3>
                                    <ul className={"footer-list"}>
                                        {/*<li><a href={window.location.origin + '/login'}>เข้าสู่ระบบ</a></li>*/}
                                        {
                                            !Global2.getMyProfile() ?
                                                <>
                                                    <li>
                                                        <a className={window.segment[1] === 'login' ? 'active' : null} href={window.location.origin + '/login'}>เข้าสู่ระบบ</a>
                                                    </li>
                                                </>
                                                :
                                                <li>
                                                    <div onClick={() => {
                                                        return Global2.loginCheck(window.location.origin + '/manage-my-account')
                                                    }} className={window.segment[1] === 'manage-my-account' ? 'active' : null}>บัญชีของฉัน
                                                    </div>
                                                </li>
                                        }

                                        <li>
                                            <div onClick={() => {
                                                return Global2.loginCheck(window.location.origin + '/my-purchase')
                                            }} className={window.segment[1] === 'my-purchase' ? 'active' : null}>การซื้อของฉัน
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => {
                                                return Global2.loginCheck(window.location.origin + '/cart')
                                            }} className={window.segment[1] === 'cart' ? 'active' : null}>ดูตะกร้าสินค้า
                                            </div>
                                        </li>
                                        <li>รายการโปรด</li>
                                        <li>ติดตามคำสั่งซื้อ</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className={"footer-line"}/>
                        <div className="copyright">
                            Copyright© Thai Bridgestone Co.,Ltd.
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}
