const CartFunction = {
	showProductDisplayLinkDetail: (product_display_link_detail) => {
		if (product_display_link_detail.length === 0 || !product_display_link_detail) {
			return null;
		}
		let _return = '';
		product_display_link_detail.forEach((option, option_i) => {
			let comma = option_i > 0 ? ' ' : '';
			_return = _return +
				comma + option.product_variation.name + ' : ' + option.product_variation_item.name
		});
		return _return;
	},
	getPrice(product_display_link, options) {
		let price = [];
		price = CartFunction.getPriceArray(product_display_link, options);
		if (price.length === 0) {
			price = [0];
		}
		price = price.filter((item, pos) => {
			return price.indexOf(item) === pos;
		});
		if (price.length > 1) {
			return Math.min.apply(null, price).format(2, 3, {pretty: true}) + ' - ' + Math.max.apply(null, price).format(2, 3, {pretty: true});
		}
		if (price.length === 1) {
			if (options && options.return_type && options.return_type === 'object') {
				price = CartFunction.getPriceArray(product_display_link, options)[0];
				let _price = {...price}
				_price.price = parseFloat(price.price).format(2, 3, {pretty: true});
				return _price;
			}
			return Math.min.apply(null, price).format(2, 3, {pretty: true});
		}
		// return price;
	},
	getPriceArray(product_display_link, options) {
		let price = [];

		if (product_display_link) {
			product_display_link.forEach((product_display_link) => {
				if (product_display_link.product) {
					product_display_link.product.sell_price.forEach((sell_price) => {
						if (sell_price.ratio === parseFloat(product_display_link.ratio)) {
							if (options && options.return_type && options.return_type === 'object') {
								price.push(sell_price.default_item_sell);
							}
							if (!options || !options.return_type || options.return_type !== 'object') {
								if (sell_price.default_item_sell.discount && sell_price.default_item_sell.discount > 0) {
									price.push(sell_price.default_item_sell.summary.total_net_price);
								} else {
									price.push(sell_price.default_item_sell.summary.total_net_price);
								}
							}
						}
					})
				}
			});
		}

		// fix display price
		if (localStorage.getItem('how_display_price') === 'total_net_price') {
			for (let i = 0; i < price.length; i++) {
				if (options && options.return_type && options.return_type === 'object') {
					price[i].summary.total_net_price2 = this.addTaxToPrice(price[i].summary.total_net_price);
				} else {
					price[i] = this.addTaxToPrice(price[i]);
				}
			}
		}
		return price;
	},
	addTaxToPrice(price) {
		price = parseFloat(price);
		return price + ((price * 0.07).round(2))
	}
};
export default CartFunction
