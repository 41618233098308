import React from 'react';
import Header from "../include/Header";
import Filter from "../component/Filter";
import Card from "../component/Card";
import Footer from "../include/Footer";

export default class Shop extends React.Component {
	constructor() {
		super();
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			list_type: 'vertical',
			product_displays: [],
			limit: 12,
		}
	}

	componentDidMount() {
		this.fetch()
	}

	fetch() {
		let data = {...this.props};
		delete data.p;
		data.limit = this.state.limit;
		data.request_data_product_display_using_filter = true;
		// console.log(data);
		window.Global.ajax({
			method: 'GET',
			url: '/api/e-commerce/v1/product_display',
			data: data,
			done: (response) => {
				// console.log(response);
				// console.log(window.$(this.$parent).find('#product-displays'));
				let product_display = response.product_display;
				let count = response.count;
				if (response.error) {
					product_display = [];
					count = 0;
				}
				window.$(this.$parent).find('#product-displays').tablePagination({
					// disableContent: 1,
					maxSize: this.state.limit,
					data: product_display.length,
					count: count,
				});
				this.setState({
					count: count,
					product_displays: product_display
				});
			}
		});
	}

	pageCount() {
		let page = 1;
		if (this.props.page) {
			page = parseFloat(this.state.qs.page);
		}
		if (this.state.product_displays.length < this.state.limit && page === 1) {
			return this.state.product_displays.length;
		}
		if (this.state.product_displays.length < this.state.limit && page !== 1) {
			return (this.state.limit * (page - 1)) + this.state.product_displays.length;
		}
		return this.state.limit * page;
	};

	render() {

		return (
			<>
				<Header {...this.props}/>
				<div className="body-content"
				     ref={(ref) => {
					     this.$parent = ref
				     }}
				>
					{/*BREADCRUMB*/}
					<div className="header-bg">
						<h1>รายการสินค้า {this.props.search_query || null}
							<span>สินค้า</span>
						</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section"
								   href={window.location.origin}
								>
									หน้าแรก
								</a>
								<i className="right chevron icon divider"/>
								<div className="active section">รายการสินค้า {this.props.search_query || null}</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0}}/>
					<div className="ui container">
						<div className="ui grid responsive-column-reverse">
							<div className="sixteen wide mobile sixteen wide tablet four wide computer column">
								<Filter/>
							</div>
							<div className="sixteen wide mobile sixteen wide tablet twelve wide computer column">
								<div className="flex flex-row filter-title middle-xs">
									<div>แสดงสินค้า <span className={"cart-total-price"}>
											{this.pageCount()} จาก {this.state.count || 0}
										</span> รายการ
									</div>
									<div className="right floated">
										<div className="ui basic buttons mini">
											<div
												className={this.state.list_type === 'horizontal' ? "ui icon button active" : "ui icon button"}
												onClick={() => {
													this.setState({
														list_type: 'horizontal'
													})
												}}
											>
												<i className={"th list icon"}/>
											</div>
											<div
												className={this.state.list_type === 'vertical' ? "ui icon button active" : "ui icon button"}
												onClick={() => {
													this.setState({
														list_type: 'vertical'
													})
												}}
											>
												{/*<i className={"th th icon"}/>*/}
												<i className={"th large icon"} style={{fontSize: 10}}/>
											</div>
										</div>
									</div>
								</div>

								<div className="ui basic table can click Table_root td-top-xs"
								     style={{marginTop: 0, borderWidth: 0}}
								     id="product-displays">

									<div className="ui grid">
										{
											this.state.product_displays
											&& this.state.product_displays.length > 0 ?
												this.state.product_displays.map((product_display, index) => {
													if (this.state.list_type === "horizontal") {
														return (
															<div className={"sixteen wide column"} key={index}>
																<Card product_display={product_display}
																      theme={"horizontal"}/>
															</div>
														);
													}
													return (
														<div
															className={"eight wide mobile half_five wide tablet four wide computer column"}
															key={index}>
															<Card product_display={product_display}/>
														</div>
													);
												}) : null
										}
										{
											(!this.state.product_displays
												|| this.state.product_displays.length === 0)
											&& this.state.qs.page
											&& this.state.qs.page === 1 ?
												<div className={"sixteen wide computer column"}>
													<h1 className={"ui header"}
													    style={{textAlign: 'center', padding: '20px 0'}}>
														ไม่มีสินค้า
													</h1>
												</div>
												: null
										}
									</div>
									<div className="tbody">
										<div className="tr" style={{backgroundColor: 'transparent'}}>
											<div className="td center aligned" data-info="nodata">
												<div className={"flex row middle-xs center-xs"}
												     style={{paddingTop: 20, paddingBottom: 20}}>
													<h2 className={"ui header"}>
														<div className="ui active small inline loader"
														     style={{marginRight: 10}}/>
														กำลังโหลดข้อมูล
													</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}