import React from 'react';
import {Modal} from "../component/Modal";

export default class EditBillingManageMyAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			relationship_address: this.props.relationship_account.relationship.relationship_address.length
				? this.props.relationship_account.relationship.relationship_address[0]
				: {},
			is_company: 0
		}

		if (!this.state.relationship_address.tax_id) {
			if (this.props.relationship_account.relationship.tax_number) {
				this.state.relationship_address.tax_id = this.props.relationship_account.relationship.tax_number
			}
		}
	}

	renderIsCompanyForm() {
		return (
			<>
				<input type="hidden" name={"relationship_branch_id"} defaultValue={this.props.data.branch_id}/>
				<div className={"field"}>
					<label htmlFor="branch_name">ชื่อสาขา</label>
					<input type={"text"} name={"name"} id={"branch_name"}
					       defaultValue={this.props.data.branch_name}/>
				</div>
				<div className={"field"}>
					<label htmlFor="branch_code">รหัสสาขา</label>
					<input type={"text"} name={"code"} id={"branch_code"}
					       defaultValue={this.props.data.branch_code}/>
				</div>
				<div className={"field"}>
					<label htmlFor="bill_line_1">ที่อยู่บนเอกสาร 1</label>
					<input type={"text"} name={"bill_line_1"} id={"bill_line_1"}
					       defaultValue={this.props.data.bill_line_1}/>
				</div>
				<div className={"field"}>
					<label htmlFor="bill_line_2">ที่อยู่บนเอกสาร 2</label>
					<input type={"text"} name={"bill_line_2"} id={"bill_line_2"}
					       defaultValue={this.props.data.bill_line_2}/>
				</div>
			</>
		);
	}

	renderIsNotCompanyForm() {
		return (
			<div className="flex row padded two column computer">
				{
					this.props.is_issued_in_another_name
					&&
					<div className={'column col-xs-16'}>
						<div className="fields">
							<div style={{marginRight: 15}}>
								<div className="ui radio checkbox">
									<input type="radio"
									       id={'individual'}
									       value={0}
									       name={'is_company'}
									       checked={!this.state.is_company}
									       onChange={() => {
										       this.setState({
											       is_company: 0
										       })
									       }}
									/>
									<label
										htmlFor={'individual'}>บุคคลธรรมดา</label>
								</div>
							</div>
							<div>
								<div className="ui radio checkbox">
									<input type="radio"
									       id={'corporation'}
									       value={1}
									       name={'is_company'}
									       checked={this.state.is_company}
									       onChange={() => {
										       this.setState({
											       is_company: 1
										       })
									       }}
									/>
									<label
										htmlFor={'corporation'}>นิติบุคคล</label>
								</div>
							</div>
						</div>
						<div style={{marginLeft: -20, marginRight: -20}}>
							<div className={"ui divider"}/>
						</div>
					</div>
				}
				<div className="column field">
					<label htmlFor="name">ชื่อ-นามสกุล / ชื่อนิติบุคคล</label>
					<input type="text"
					       name={"name"}
					       defaultValue={this.state.relationship_address.name}/>
				</div>
				<div className="column field required">
					<label>หมายเลขประจำตัวผู้เสียภาษี</label>
					<input type={"text"}
					       name={"tax_id"}
					       defaultValue={this.state.relationship_address.tax_id}/>
				</div>
				{
					this.state.is_company
						?
						<>
							<div className="column field required">
								<label>ชื่อสาขา</label>
								<input type={"text"}
								       name={"branch_name"}
								       defaultValue={this.state.relationship_address.branch_name}/>
							</div>
							<div className="column field required">
								<label>รหัสสาขา</label>
								<input type={"text"}
								       name={"branch_code"}
								       defaultValue={this.state.relationship_address.branch_code}/>
							</div>
						</>
						: null
				}
				<div className="column col-xs-16 field required">
					<label htmlFor="name">เบอร์โทรศัพท์</label>
					<input type="text"
					       name={"phone_number"}
					       defaultValue={
						       this.state.relationship_address.phone_number
							       ?
							       this.state.relationship_address.phone_number.replace('+66', '0')
							       : ''
					       }/>
				</div>
				<div className="column col-xs-16 field required">
					<label htmlFor="street">รายละเอียดที่อยู่ (บ้านเลขที่ ชื่ออาคาร ฯลฯ)</label>
					<input type="text" name={"street"}
					       defaultValue={this.state.relationship_address.street}/>
				</div>
				<div className="column field required">
					<label htmlFor="district">ตำบล/แขวง</label>
					<input name={"district"} id={"district"}
					       placeholder={"กรอกตำบล"}
					       defaultValue={this.state.relationship_address.district}/>
				</div>
				<div className="column field required">
					<label htmlFor="city">อำเภอ/เขต</label>
					<input name={"city"} id={"city"}
					       placeholder={"กรอกอำเภอ"}
					       defaultValue={this.state.relationship_address.city}/>
				</div>
				<div className="column field required">
					<label htmlFor="province">จังหวัด</label>
					<input name={"province"} id={"province"}
					       placeholder={"กรอกจังหวัด"}
					       defaultValue={this.state.relationship_address.province}/>
				</div>
				<div className="column field required">
					<label htmlFor="zip_code">รหัสไปรษณีย์</label>
					<input name={"zip_code"}
					       id={"zipcode"}
					       placeholder={"กรอกรหัสไปรษณีย์"}
					       defaultValue={this.state.relationship_address.zip_code}/>
				</div>
				<div className="column field required"
				     style={{display: 'none'}}>
					<label>ประเทศ</label>
					<select className="ui dropdown search"
					        ref={(ref) => {
						        window.$(ref).dropdown()
					        }}
					        name="country"
					        data-form2_setup="true"
					        defaultValue={'TH'}
						// defaultValue={this.state.relationship_address.country || 'TH'}
					>
						<option value="RU">Russian Federation</option>
						<option value="US">United States</option>
						<option value="ES">Spain</option>
						<option value="FR">France</option>
						<option value="UK">United Kingdom</option>
						<option value="BR">Brazil</option>
						<option value="IL">Israel</option>
						<option value="NL">Netherlands</option>
						<option value="CA">Canada</option>
						<option value="IT">Italy</option>
						<option value="CL">Chile</option>
						<option value="UA">Ukraine</option>
						<option value="PL">Poland</option>
						<option value="AU">Australia</option>
						<option value="DE">Germany</option>
						<option value="BE">Belgium</option>
						<option value="">-------- Country &amp; Territories (A-Z) --------
						</option>
						<option value="AF">Afghanistan</option>
						<option value="ALA">Aland Islands</option>
						<option value="AL">Albania</option>
						<option value="GBA">Alderney</option>
						<option value="DZ">Algeria</option>
						<option value="AS">American Samoa</option>
						<option value="AD">Andorra</option>
						<option value="AO">Angola</option>
						<option value="AI">Anguilla</option>
						<option value="AG">Antigua and Barbuda</option>
						<option value="AR">Argentina</option>
						<option value="AM">Armenia</option>
						<option value="AW">Aruba</option>
						<option value="ASC">Ascension Island</option>
						<option value="AU">Australia</option>
						<option value="AT">Austria</option>
						<option value="AZ">Azerbaijan</option>
						<option value="BH">Bahrain</option>
						<option value="GGY">Guernsey</option>
						<option value="BD">Bangladesh</option>
						<option value="BB">Barbados</option>
						<option value="BY">Belarus</option>
						<option value="BE">Belgium</option>
						<option value="BZ">Belize</option>
						<option value="BJ">Benin</option>
						<option value="BM">Bermuda</option>
						<option value="BT">Bhutan</option>
						<option value="BO">Bolivia</option>
						<option value="BA">Bosnia and Herzegovina</option>
						<option value="BW">Botswana</option>
						<option value="BR">Brazil</option>
						<option value="VG">Virgin Islands (British)</option>
						<option value="BG">Bulgaria</option>
						<option value="BF">Burkina Faso</option>
						<option value="BI">Burundi</option>
						<option value="KH">Cambodia</option>
						<option value="CM">Cameroon</option>
						<option value="CA">Canada</option>
						<option value="CV">Cape Verde</option>
						<option value="BQ">Caribbean Netherlands</option>
						<option value="KY">Cayman Islands</option>
						<option value="CF">Central African Republic</option>
						<option value="TD">Chad</option>
						<option value="CL">Chile</option>
						<option value="CX">Christmas Island</option>
						<option value="CC">Cocos (Keeling) Islands</option>
						<option value="CO">Colombia</option>
						<option value="KM">Comoros</option>
						<option value="CK">Cook Islands</option>
						<option value="CR">Costa Rica</option>
						<option value="CI">Cote D'Ivoire</option>
						<option value="HR">Croatia (local name: Hrvatska)</option>
						<option value="CW">Curacao</option>
						<option value="CY">Cyprus</option>
						<option value="CZ">Czech Republic</option>
						<option value="DK">Denmark</option>
						<option value="DJ">Djibouti</option>
						<option value="DM">Dominica</option>
						<option value="DO">Dominican Republic</option>
						<option value="EC">Ecuador</option>
						<option value="EG">Egypt</option>
						<option value="SV">El Salvador</option>
						<option value="GQ">Equatorial Guinea</option>
						<option value="ER">Eritrea</option>
						<option value="EE">Estonia</option>
						<option value="ET">Ethiopia</option>
						<option value="FK">Falkland Islands (Malvinas)</option>
						<option value="FO">Faroe Islands</option>
						<option value="FJ">Fiji</option>
						<option value="FI">Finland</option>
						<option value="FR">France</option>
						<option value="PF">French Polynesia</option>
						<option value="GA">Gabon</option>
						<option value="GM">Gambia</option>
						<option value="GE">Georgia</option>
						<option value="DE">Germany</option>
						<option value="GH">Ghana</option>
						<option value="GI">Gibraltar</option>
						<option value="GR">Greece</option>
						<option value="GL">Greenland</option>
						<option value="GD">Grenada</option>
						<option value="GP">Guadeloupe</option>
						<option value="GU">Guam</option>
						<option value="GT">Guatemala</option>
						<option value="GN">Guinea</option>
						<option value="GW">Guinea-Bissau</option>
						<option value="GY">Guyana</option>
						<option value="GF">French Guiana</option>
						<option value="HT">Haiti</option>
						<option value="HN">Honduras</option>
						<option value="HK">Hong Kong,China</option>
						<option value="HU">Hungary</option>
						<option value="IS">Iceland</option>
						<option value="IN">India</option>
						<option value="ID">Indonesia</option>
						<option value="IQ">Iraq</option>
						<option value="IE">Ireland</option>
						<option value="IL">Israel</option>
						<option value="IT">Italy</option>
						<option value="JM">Jamaica</option>
						<option value="JP">Japan</option>
						<option value="JEY">Jersey</option>
						<option value="JO">Jordan</option>
						<option value="KZ">Kazakhstan</option>
						<option value="KE">Kenya</option>
						<option value="KI">Kiribati</option>
						<option value="KR">Korea</option>
						<option value="KS">Kosovo</option>
						<option value="KW">Kuwait</option>
						<option value="KG">Kyrgyzstan</option>
						<option value="LA">Lao People's Democratic Republic</option>
						<option value="LV">Latvia</option>
						<option value="LB">Lebanon</option>
						<option value="LS">Lesotho</option>
						<option value="LR">Liberia</option>
						<option value="LY">Libya</option>
						<option value="LI">Liechtenstein</option>
						<option value="LT">Lithuania</option>
						<option value="LU">Luxembourg</option>
						<option value="MO">Macau,China</option>
						<option value="MG">Madagascar</option>
						<option value="MW">Malawi</option>
						<option value="MY">Malaysia</option>
						<option value="MV">Maldives</option>
						<option value="ML">Mali</option>
						<option value="MT">Malta</option>
						<option value="MQ">Martinique</option>
						<option value="MR">Mauritania</option>
						<option value="MU">Mauritius</option>
						<option value="YT">Mayotte</option>
						<option value="MX">Mexico</option>
						<option value="FM">Micronesia</option>
						<option value="MC">Monaco</option>
						<option value="MN">Mongolia</option>
						<option value="MNE">Montenegro</option>
						<option value="MS">Montserrat</option>
						<option value="MA">Morocco</option>
						<option value="MZ">Mozambique</option>
						<option value="MM">Myanmar</option>
						<option value="NA">Namibia</option>
						<option value="NR">Nauru</option>
						<option value="BN">Negara Brunei Darussalam</option>
						<option value="NP">Nepal</option>
						<option value="NL">Netherlands</option>
						<option value="AN">Netherlands Antilles</option>
						<option value="NC">New Caledonia</option>
						<option value="NZ">New Zealand</option>
						<option value="NI">Nicaragua</option>
						<option value="NE">Niger</option>
						<option value="NG">Nigeria</option>
						<option value="NU">Niue</option>
						<option value="NF">Norfolk Island</option>
						<option value="MK">Macedonia</option>
						<option value="MP">Northern Mariana Islands</option>
						<option value="NO">Norway</option>
						<option value="OM">Oman</option>
						<option value="PK">Pakistan</option>
						<option value="PW">Palau</option>
						<option value="PS">Palestine</option>
						<option value="PA">Panama</option>
						<option value="PG">Papua New Guinea</option>
						<option value="PY">Paraguay</option>
						<option value="PE">Peru</option>
						<option value="PH">Philippines</option>
						<option value="PL">Poland</option>
						<option value="PT">Portugal</option>
						<option value="PR">Puerto Rico</option>
						<option value="QA">Qatar</option>
						<option value="MD">Moldova</option>
						<option value="RE">Reunion</option>
						<option value="RO">Romania</option>
						<option value="RU">Russian Federation</option>
						<option value="RW">Rwanda</option>
						<option value="BLM">Saint Barthelemy</option>
						<option value="KN">Saint Kitts and Nevis</option>
						<option value="LC">Saint Lucia</option>
						<option value="MAF">Saint Martin</option>
						<option value="PM">St. Pierre and Miquelon</option>
						<option value="VC">Saint Vincent and the Grenadines</option>
						<option value="WS">Samoa</option>
						<option value="SM">San Marino</option>
						<option value="ST">Sao Tome and Principe</option>
						<option value="SA">Saudi Arabia</option>
						<option value="SN">Senegal</option>
						<option value="SRB">Serbia</option>
						<option value="SC">Seychelles</option>
						<option value="SL">Sierra Leone</option>
						<option value="SG">Singapore</option>
						<option value="SX">Sint Maarten (Netherlands)</option>
						<option value="SK">Slovakia (Slovak Republic)</option>
						<option value="SI">Slovenia</option>
						<option value="SB">Solomon Islands</option>
						<option value="SO">Somalia</option>
						<option value="ZA">South Africa</option>
						<option value="SGS">South Georgia and the South Sandwich Islands
						</option>
						<option value="SS">South Sudan</option>
						<option value="ES">Spain</option>
						<option value="LK">Sri Lanka</option>
						<option value="SR">Suriname</option>
						<option value="SZ">Swaziland</option>
						<option value="SE">Sweden</option>
						<option value="CH">Switzerland</option>
						<option value="TW">Taiwan,China</option>
						<option value="TJ">Tajikistan</option>
						<option value="TH">Thailand (ประเทศไทย)</option>
						<option value="BS">Bahamas</option>
						<option value="ZR">Congo, The Democratic Republic Of The</option>
						<option value="CG">Congo, The Republic of Congo</option>
						<option value="MH">Marshall Islands</option>
						<option value="VA">Vatican City State (Holy See)</option>
						<option value="TLS">Timor-Leste</option>
						<option value="TG">Togo</option>
						<option value="TO">Tonga</option>
						<option value="TT">Trinidad and Tobago</option>
						<option value="TN">Tunisia</option>
						<option value="TR">Turkey</option>
						<option value="TM">Turkmenistan</option>
						<option value="TC">Turks and Caicos Islands</option>
						<option value="TV">Tuvalu</option>
						<option value="VI">Virgin Islands (U.S.)</option>
						<option value="UG">Uganda</option>
						<option value="UA">Ukraine</option>
						<option value="AE">United Arab Emirates</option>
						<option value="UK">United Kingdom</option>
						<option value="TZ">Tanzania</option>
						<option value="US">United States</option>
						<option value="UY">Uruguay</option>
						<option value="UZ">Uzbekistan</option>
						<option value="VU">Vanuatu</option>
						<option value="VE">Venezuela</option>
						<option value="VN">Vietnam</option>
						<option value="WF">Wallis And Futuna Islands</option>
						<option value="YE">Yemen</option>
						<option value="ZM">Zambia</option>
						<option value="EAZ">Zanzibar</option>
						<option value="ZW">Zimbabwe</option>
						<option value="OTHER">Other Country</option>
						<option value="">-------- Country &amp; Territories (A-Z) --------
						</option>
					</select>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       closable={false}
				       onShowToSetupForm={($modal) => {
					       this.$modal = $modal;
					       $modal.find('form').form2('setup');
					       $modal.find('form').form2('submit', {
						       method: 'PUT',
						       url: '/api/v1/e-commerce/relationship/address',
						       beforeSent: (object) => {
							       // console.log(object);

							       if (this.props.is_issued_in_another_name) {
								       if (!object.tax_id) {
									       $modal.find('form')
										       .addClass('error')
										       .find('.ui.error.message').html('<ul class="list">\n    <li>หมายเลขประจำตัวผู้เสียภาษี</li>\n</ul>')
									       return false;
								       }
								       if (this.state.is_company) {
									       if (!object.branch_name) {
										       $modal.find('form')
											       .addClass('error')
											       .find('.ui.error.message').html('<ul class="list">\n    <li>ระบุชื่อสาขา</li>\n</ul>')
										       return false;
									       }
									       if (!object.branch_code) {
										       $modal.find('form')
											       .addClass('error')
											       .find('.ui.error.message').html('<ul class="list">\n    <li>ระบุรหัสสาขา</li>\n</ul>')
										       return false;
									       }
								       }
							       }

							       return object;
						       },
						       loadingButton: window.$(this.$modal).find('.button.approve'),
						       submitButton: window.$(this.$modal).find('.button.approve'),
						       onSuccess: (response) => {
							       let object = $modal.find('form').serializeObject();

							       if (!this.state.is_company) {
								       object.branch_name = '';
								       object.branch_code = '';
							       }

							       this.props.onSuccess(response, object);
							       $modal.modal('hide');
						       }
					       });
					       window.$.Thailand({
						       database: './libs/spaceship-web/jquery_Thailand/db.json',
						       database_type: 'json',
						       autocomplete_size: 20,
						       $district: window.$('#district'),
						       $amphoe: window.$('#city'),
						       $province: window.$('#province'),
						       $zipcode: window.$('#zipcode'),
					       });
					       $modal.find('.close.icon').remove();
				       }}
				       onDeny={() => {
					       if (this.props.onDeny) {
						       this.props.onDeny();
					       }
				       }}
				>
					<div className="header remove-time-icon">
						<div className="flex flex-row">
							รายละเอียดใบเสร็จรับเงิน
						</div>
					</div>
					<div className="content">
						<form className={"ui form"}>
							{this.renderIsNotCompanyForm()}
							{/*{*/}
							{/*    this.props.data*/}
							{/*    && !this.props.data.is_company ?*/}
							{/*       this.renderIsNotCompanyForm()*/}
							{/*       : null*/}
							{/*}*/}
							{/*{*/}
							{/*    this.props.data*/}
							{/*    && this.props.data.is_company ?*/}
							{/*       this.renderIsCompanyForm()*/}
							{/*       : null*/}
							{/*}*/}
							<div className="ui error message"/>
						</form>
					</div>
					<div className="actions">
						<button className="ui deny basic button"
						        type={"button"}>
							ยกเลิก
						</button>
						<button className="ui blue button approve"
						        type={"button"}
						        style={{marginLeft: 15}}
						>
							ตกลง
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}
