import React from 'react';
import {Modal} from "../component/Modal";

export default class EditBilling extends React.Component {
	constructor(props) {
		super(props);
		this.state = {is_company: props.data.is_company};
	}

	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       closable={false}
				       onShowToSetupForm={($modal) => {
					       this.$modal = $modal;
					       $modal.find('form').form2('setup');
					       $modal.find('.close.icon').remove();
				       }}
				       onDeny={() => {
					       if (this.props.onDeny) {
						       this.props.onDeny();
					       }
				       }}
				>
					<div className="header remove-time-icon">
						<div className="flex flex-row">
							{
								this.props.data.is_issued_in_another_name
								&& !this.props.is_edit
									?
									'ออกที่อยู่ใบเสร็จในนามอื่น'
									: 'แก้ไขที่อยู่ออกใบเสร็จ'
							}
						</div>
					</div>
					<div className="content">
						<form className={"ui form"}>
							{
								this.props.data.is_issued_in_another_name ?
									<>
										<div className="fields">
											<div style={{marginRight: 15}}>
												<div className="ui radio checkbox">
													<input type="radio"
													       id={'individual'}
													       value={0}
													       name={'is_company'}
													       checked={!this.state.is_company}
													       onChange={() => {
														       this.setState({
															       is_company: 0
														       })
													       }}
													/>
													<label
														htmlFor={'individual'}>บุคคลธรรมดา</label>
												</div>
											</div>
											<div>
												<div className="ui radio checkbox">
													<input type="radio"
													       id={'corporation'}
													       value={1}
													       name={'is_company'}
													       checked={this.state.is_company}
													       onChange={() => {
														       this.setState({
															       is_company: 1
														       })
													       }}
													/>
													<label
														htmlFor={'corporation'}>นิติบุคคล</label>
												</div>
											</div>
										</div>
										<div style={{marginLeft: -20, marginRight: -20}}>
											<div className={"ui divider"}/>
										</div>
									</>
									: null
							}
							<div className="field required">
								<label>ชื่อ-นามสกุล</label>
								<input type={"text"} name={"buyer_company_name"}
								       defaultValue={this.props.data.buyer_company_name}/>
							</div>
							<div className="field required">
								<label>ที่อยู่ 1</label>
								<input type={"text"} name={"buyer_bill_line_1"}
								       defaultValue={this.props.data.buyer_bill_line_1}/>
							</div>
							<div className="field required">
								<label>ที่อยู่ 2</label>
								<input type={"text"} name={"buyer_bill_line_2"}
								       defaultValue={this.props.data.buyer_bill_line_2}/>
							</div>
							{
								this.state.is_company ?
									<>
										<div className="field required">
											<label>ชื่อสาขา</label>
											<input type={"text"} name={"buyer_branch_name"}
											       defaultValue={this.props.data.buyer_branch_name}/>
										</div>
										<div className="field required">
											<label>รหัสสาขา</label>
											<input type={"text"} name={"buyer_branch_code"}
											       defaultValue={this.props.data.buyer_branch_code}/>
										</div>
									</>
									: null
							}
							<div className="field required">
								<label>หมายเลขประจำตัวผู้เสียภาษี</label>
								<input type={"text"}
								       name={"buyer_tax_number"}
								       defaultValue={this.props.data.buyer_tax_number}/>
							</div>

							<div className="ui error message"/>
						</form>
					</div>
					<div className="actions">
						<button className="ui deny basic button"
						        type={"button"}>
							ยกเลิก
						</button>
						<button className="ui blue button "
						        type={"button"}
						        style={{marginLeft: 15}}
						        onClick={() => {
							        let data = this.$modal.find('form').serializeObject();
							        data.is_company = this.state.is_company;
							        if (!data.buyer_company_name) {
								        window.Global.messageTop({
									        type: 'negative',
									        message: 'กรุณากรอกชื่อ'
								        });
								        return false;
							        }
							        if (!this.state.is_company) {
								        data.buyer_branch_name = '';
								        data.buyer_branch_code = '';
							        }
							        this.props.onChanged(data);
							        window.$(this.$modal).modal('hide');
						        }}
						>
							ตกลง
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}
