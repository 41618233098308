import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class WareHouseMap extends React.Component {
	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<div className="header-bg">
						<h1>แผนที่คลังสินค้า</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">แผนที่คลังสินค้า</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div style={{paddingTop: 25, marginBottom: 15,}}>
							<img src={'./warehouse_location.jpg'} alt={"how to buy"}
							     style={{maxWidth: '100%', display: 'block', margin: 'auto'}}/>
						</div>
						<div className={"warehouse-map-iframe"}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3870.375231128042!2d100.61510371483386!3d14.055005990151802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e27fff02e098f7%3A0xf0ca26bb550a361!2z4LmA4Lit4LiqIOC4i-C4tSDguIjguLUg4LmC4Lil4LiI4Li04Liq4LiV4Li04LiB4Liq4LmMIOC4o-C4seC4h-C4quC4tOC4lQ!5e0!3m2!1sth!2sth!4v1587960125085!5m2!1sth!2sth"
								aria-hidden="false" tabIndex="0"
								title={"แผนที่คลังสินค้า"}
							/>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}