import React from 'react';
import Cart from './../component/Cart';
import {Global2} from "../component/Global";

export default class Header extends React.Component {
	constructor() {
		super();
		this.state = {
			show_cart: false,
			show_search: false,
			show_responsive_menu: false,
			is_cart_number_load: true,
			user: false,
			sale_order_via_e_commerce: {
				sale_order: {
					sale_order_item: [],
					summary: {
						total_net_price: 0
					}
				}
			},
			qs: window.Global.getAllQueryStrings(),
			// user: {name: 'FirstName LastName'},
		};
		this.cart = React.createRef();
	}

	componentDidMount() {
		if (Global2.getMyProfile()) {
			this.fetchCart();
		}
	}

	fetchCart() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/sale_order/cart',
			done: (response) => {
				let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
				if (response.sale_order_via_e_commerce) {
					sale_order_via_e_commerce = response.sale_order_via_e_commerce
				}
				this.setState({
					sale_order_via_e_commerce: sale_order_via_e_commerce,
					is_cart_number_load: false,
				})
			}
		});
	}

	getSaleOrderViaEcomerce(sale_order_via_e_commerce) {
		this.setState({
			sale_order_via_e_commerce: sale_order_via_e_commerce
		})
	}

	getProductQuantity() {
		let quantity = 0;
		if (this.state.sale_order_via_e_commerce.sale_order.sale_order_item
			&& this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length > 0) {
			this.state.sale_order_via_e_commerce.sale_order.sale_order_item.forEach((sale_order_item) => {
				if (sale_order_item.sale_order_item_product_display_link._quantity) {
					quantity = quantity + parseFloat(sale_order_item.sale_order_item_product_display_link._quantity)
				} else {
					quantity = quantity + 1;
				}
			})
		}
		return quantity;
	}

	getProductTotal() {
		return this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price;
	}


	render() {
		return (
			<>
				<div
					className={this.state.show_responsive_menu ? "responsive-menu-overlay show" : "responsive-menu-overlay"}
					onClick={() => {
						this.setState({
							show_responsive_menu: false
						})
					}}
				/>
				<div className={this.state.show_responsive_menu ? "responsive-menu show" : "responsive-menu"}>
					<div className="responsive-menu-header flex flex-row middle-xs">
						<div><strong className={"responsive-logo"}>
							{/*<img src={'./bridgestone-logo-set-en.png'} alt={"logo"}/>*/}
							<img src={'./images/logo-small.png'} alt={"logo"}/>
						</strong></div>
						<div className="right floated">
							<button className="circular ui icon button"
							        onClick={() => {
								        this.setState({
									        show_responsive_menu: false
								        })
							        }}
							>
								<i className="icon times"/>
							</button>
						</div>
					</div>
					<div className={"search"} id={"header-search"}>
						<form className="ui icon input action"
						      onSubmit={(e) => {
							      e.preventDefault();
							      window.$(e.target).find('[type="submit"]').addClass('loading');
							      window.location = '/shop?search_query=' + window.$(e.target).find('input[name="search_query"]').val()
						      }}
						>
							<input type="text"
							       name={"search_query"}
							       placeholder="ค้นหา..."
							       defaultValue={this.state.qs.search_query || ''}
							/>
							<button className="ui icon button" type={'submit'}>
								<i className="search icon"/>
							</button>
						</form>
					</div>
					<ul>
						<li className={!window.segment[1] ? 'active' : null}>
							<a href={window.location.origin}>หน้าแรก</a>
						</li>
						<li className={window.segment[1] === 'shop' ? 'active' : null}>
							<a href={window.location.origin + '/shop'}>สินค้า</a>
						</li>
						{
							!Global2.getMyProfile().id ?
								<li className={window.segment[1] === 'login' || window.segment[1] === 'manage-my-account' ? 'active' : null}>
									<a href={window.location.origin + '/login'}>เข้าสู่ระบบ</a>
								</li>
								: null
						}
					</ul>
					{
						Global2.getMyProfile().id ?
							<>
								<div className="responsive-menu-header flex flex-row middle-xs" style={{marginTop: 15}}>
									<div>
										<strong className={"responsive-logo"} style={{fontSize: 20}}>
											<i className="user icon"/> {Global2.getMyProfile().relationship.name}
										</strong>
									</div>
								</div>
								<ul>
									<>
										<li className={window.segment[1] === 'manage-my-account' ? 'active' : null}>
											<a href={window.location.origin + '/manage-my-account'}>บัญชีของฉัน</a>
										</li>
										<li className={window.segment[1] === 'my-purchase' ? 'active' : null}>
											<a href={window.location.origin + '/my-purchase'}>การซื้อของฉัน</a>
										</li>
										<li>
											<div onClick={() => {
												window.Global.createCookie('relationship_account', '', 365);
												window.location.reload();
											}}>
												ออกจากระบบ
											</div>
										</li>
									</>
								</ul>
							</>
							: null
					}

				</div>
				<header className={this.state.qs.borderBottom ? 'border-bottom' : null}>
					<div className="ui container">
						<div className="header-left">
							<button className={"responsive-menu-icon"}
							        onClick={() => {
								        this.setState({
									        show_responsive_menu: true
								        })
							        }}
							>
								<i className={"icon bars"}/>
							</button>
							<a href={window.location.origin} style={{padding: '0.5rem 0'}}>
								{/*<strong><img src={'./bridgestone-logo-set-en.png'} alt={"logo"}/></strong>*/}
								<strong><img src={'./images/logo-small.png'} alt={"logo"} style={{height: '50px'}} /></strong>
							</a>
						</div>
						<div className="header-center">
							<nav>
								<ul className="menu2">
									<li className={!window.segment[1] ? 'active' : null}>
										<a href={window.location.origin}>หน้าแรก</a>
									</li>
									<li className={window.segment[1] === 'shop' ? 'active' : null}>
										<a href={window.location.origin + '/shop'}>สินค้า</a>
									</li>
								</ul>
							</nav>
						</div>
						<div className="header-right">
							<div className={"search hidden-xs hidden-sm"} id={"header-search"}>
								<form className="ui icon input action"
								      onSubmit={(e) => {
									      e.preventDefault();
									      window.$(e.target).find('[type="submit"]').addClass('loading');
									      window.location = '/shop?search_query=' + window.$(e.target).find('input[name="search_query"]').val()
								      }}
								>
									<input type="text"
									       name={"search_query"}
									       placeholder="ค้นหา..."
									       defaultValue={this.state.qs.search_query || ''}
									/>
									<button className="ui icon basic button" type={'submit'}>
										<i className="search icon"/>
									</button>
								</form>
							</div>
							{
								Global2.getMyProfile().id ?
									<div
										className={window.segment[1] === 'cart' || window.segment[1] === 'checkout' ? "shopping-header flex row middle-xs active" : "shopping-header flex row middle-xs"}
										id={"shopping-header"}
										onClick={() => {
											// this.setState({
											// 	show_cart: !this.state.show_cart
											// })
											window.location.href = window.location.origin + '/cart';
										}}
									>
										<div className="shopping-icon-header">
											<div className="shopping-icon-circle">
												{
													this.state.is_cart_number_load ?
														<i className="sync alternate loading icon"
														   style={{margin: 0, fontSize: 10}}/>
														: null
												}
												{
													!this.state.is_cart_number_load ?
														this.getProductQuantity()
														: null
												}
											</div>
											<i className="shopping bag icon"/>
										</div>
										<span className="shopping-price-header">฿
											{
												this.state.sale_order_via_e_commerce ?
													this.getProductTotal().format(2, 3, {pretty: false}).toString()
													: (0).format(2, 3, {pretty: false}).toString()
											}
										</span>
									</div>
									: null
							}
							{
								Global2.getMyProfile().id ?
									<div className="ui simple dropdown item hidden-xs"
									     ref={(ref) => {
										     window.$(ref).dropdown()
									     }}
									     style={{marginLeft: '1rem'}}
									>
										<a className={"ui button icon"}
										   href={window.location.origin + '/manage-my-account'}>
											<i className="user icon"/> {Global2.getMyProfile().relationship.name}
											<i className="dropdown icon"/>
										</a>
										<div className="menu" style={{width: '100%'}}>
											<a className={window.segment[1] === 'manage-my-account' ? "active item" : "item"}
											   href={window.location.origin + '/manage-my-account'}>
												<i className={"icon id card"}/>
												บัญชีของฉัน
											</a>
											<a className={window.segment[1] === 'my-purchase' || window.segment[1] === 'order-detail' ? "active item" : "item"}
											   href={window.location.origin + '/my-purchase?status=all'}>
												<i className={"icon shopping bag"}/>
												การซื้อของฉัน
											</a>
											<div className="item" onClick={() => {
												window.Global.createCookie('relationship_account', '', 365);
												window.location.reload();
											}}>
												<i className={"icon sign out"}/>
												ออกจากระบบ
											</div>
										</div>
									</div>

									:
									<a className={window.segment[1] === 'login' ? "ui button brown icon" : "ui button icon"}
									   style={{marginLeft: 10}}
									   href={window.location.origin + '/login'}
									>
										<i className="sign in alternate icon"/> เข้าสู่ระบบ
									</a>
							}

						</div>
					</div>
				</header>
				{
					!this.props.hide_cart ?
						<Cart theme={'fixed_right'}
						      show_cart={this.state.show_cart}
						      ref={this.cart}
						/>
						: null
				}

			</>
		);
	}
}
