import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class FAQ extends React.Component {
	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<div className="header-bg">
						<h1>คำถามที่พบบ่อย</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">คำถามที่พบบ่อย</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div style={{paddingTop: 25}}>
							<p>
								<strong>ฉันจะเข้าใช้งานเว็บไซต์ได้อย่างไร</strong>
								<br/><br/>
								<span
									style={{textDecoration: 'underline'}}>สำหรับลูกค้าองค์กร และแผนกต่างๆ</span> โปรดใช้รหัสที่ได้รับจากแผนกสื่อสารองค์กร
								ไทยบริดจสโตน
								หากไม่ทราบ โปรดติดต่อ 02-636-1505 ถึง 32 ต่อ 2383 (อำพรพรรณ)
								<br/><br/>
								<span style={{textDecoration: 'underline'}}>สำหรับพนักงานบริดจสโตน</span>
								<br/><br/>
								สามารถลงชื่อเข้าใช้ได้ด้วยรหัสประจำตัวพนักงาน หากไม่พบข้อมูล โปรดติดต่อ 02-636-1505 ถึง
								32 ต่อ 2383 (อำพรพรรณ)
								<br/>
								Username : รหัสประจำตัวพนักงาน
								<br/>
								Password : วัน / เดือน / ปีเกิด (เช่น 18Oct2535)
								<br/><br/><br/>
								<strong>ปลอดภัยหรือไม่ หากชำระด้วยบัตรเครดิต？</strong>
								<br/>
								บริษัทรับรองความปลอดภัยในการชำระผ่านบัตรเครดิตของลูกค้า
								โดยระบบอิเล็กทรอนิกส์ที่เชื่อมโยงกับธนาคารโดยตรง
								ข้อมูลส่วนตัวของท่านจะเป็นความลับเฉพาะธนาคารเจ้าของบัตรเท่านั้น
								<br/><br/><br/>
								<strong>ทำไมฉันถึงไม่ได้รับรหัส OTP?</strong>
								<br/>
								หากคุณไม่ได้รับรหัส OTP จากระบบชำระเงิน
								กรุณาตรวจสอบว่าเบอร์ที่ใช้ลงทะเบียนกับธนาคารเป็นเบอร์เดียวกันกับเบอร์ที่ใช้อยู่ ณ
								ปัจจุบันหรือไม่
								<br/><br/><br/>
								<strong>สถานะทำไมถึงยังไม่เปลี่ยนเป็นชำระแล้ว ทั้งๆ ที่ชำระเงินไปแล้ว ?</strong>
								<br/>
								หลังจากที่ลูกค้าได้ชำระเงินผ่านทางช่องทางต่างๆ ของบริษัท
								จำเป็นต้องมีการตรวจสอบจากระบบธนาคารเพื่อยืนยันความถูกต้องของรายการสั่งซื้อซึ่งมีจำนวนมาก
								อาจส่งผลให้การแจ้งสถานะไปยังลูกค้าช้ากว่าปกติ
								<br/><br/><br/>
								<strong>ฉันสามารถซื้อสินค้าที่แสดง "สินค้าหมด" ได้หรือไม่?</strong>
								<br/>
								ขออภัยค่ะ ลูกค้าไม่สามารถทำการซื้อสินค้าที่ “สินค้าหมด” ได้ค่ะ
								<br/><br/><br/>
								<strong>ฉันสามารถชำระค่าสินค้าที่สั่งซื้อไว้แล้ว ซึ่งปัจจุบันแสดงว่า "สินค้าหมด"
									ได้หรือไม่?</strong>
								<br/>
								สินค้าที่แสดง "สินค้าหมด" หมายถึงสินค้านั้นหมดสต็อคแล้ว
								ลูกค้าไม่ต้องดำเนินการชำระเงินค่ะ
								<br/><br/><br/>
								<strong>ฉันสามารถติดตามสถานะการจัดส่งได้อย่างไร?</strong>
								<br/>
								สำหรับการจัดส่งสินค้าทางบริษัทจะดำเนินการ แจ้งเลขสินค้าให้ลูกค้าในระบบเมื่อนำส่งพัสดุ
								ลูกค้าสามารถนำเลขพัสดุที่ได้ไปตรวจสอบได้ที่ <a
								href={"https://www.scgexpress.co.th/tracking"}>https://www.scgexpress.co.th/tracking</a> ทั้งนี้หากตรวจสอบไม่พบข้อมูล
								ให้ทำการตรวจสอบใหม่อีกครั้งในวันถัดไป หรือหากยังไม่พบข้อมูลใดๆ ให้รีบแจ้งข้อมูลมาที่
								02-636-1505 ถึง 32 ต่อ 2383
							</p>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}