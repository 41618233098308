import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";

export default class HowToBuy extends React.Component {
	render() {
		return (
			<>
				<Header {...this.props}/>
				<div className="body-content">
					<div className="header-bg">
						<h1>วิธีสั่งซื้อ</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">วิธีสั่งซื้อ</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div style={{paddingTop: 25}}>
							<img src={'./how-to-buy-infographic.jpg'} alt={"how to buy"} style={{maxWidth: '100%'}}/>
						</div>
					</div>
				</div>
				<Footer/>
			</>
		);
	}
}