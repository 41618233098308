import React from 'react';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Slider extends React.Component {
	constructor() {
		super();
		this.state = {
			slide: [],
			is_loading: true
		}
	}

	componentDidMount() {
		this.fetch();
	}

	fetch() {
		let data = {};
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/slide',
			data: data,
			done: (response) => {
				this.setState({...response, is_loading: false})
			}
		});
	}

	render() {
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		};
		return (
			<>
				<div className="main_slide">
					{
						this.state.is_loading ?
							<div className={"flex row middle-xs center-xs loader-slide"}>
								<h1 className={"ui header"}>
									<div className="ui active inline loader" style={{marginRight: 10}}/>
									กำลังโหลดข้อมูล
								</h1>
							</div>
							: null
					}
					{
						!this.state.is_loading
						&& this.state.slide
						&& this.state.slide.length > 0 ?
						<SlickSlider {...settings}>
						{
							this.state.slide.map((slide, index) => {
								return (
									<div key={index}>
										<div className="image_bg_slide">
											<img src={slide.file_upload.url} alt={"Slider"}/>
										</div>
									</div>
								);
							})
						}

						</SlickSlider>
						: null
					}

				</div>
			</>
		);
	}
}
