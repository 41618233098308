import React from 'react';
import Header from "../include/Header";
import RelationshipAddress from '../modal_form/RelationshipAddress';
import EditBilling from '../modal_form/EditBilling';
import Footer from "../include/Footer";
import MessageConfirm from '../component/MessageComfirm';
// import {Modal} from '../component/Modal';
// import Form2FileUpload from '../component/Modal';
import CartFunction from '../Function';
import {Global2} from "../component/Global";
import EditBillingManageMyAccount from "../modal_form/EditBillingManageMyAccount";
import {Modal} from "../component/Modal";

export default class CartPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading_cart: true,
			sale_order_via_e_commerce: false,
			message_form: false,
			is_tax_invoice: 0,
			shipment_method_id: '',
			payment_by_type: false,
			payment_by: [],
			shipment_method: [],
			relation_ship_address: [],
			relationship_account: {
				relationship_branch_id: "",
				buyer_company_name: "",
				buyer_branch_name: "",
				buyer_branch_code: "",
				buyer_bill_line_1: "",
				buyer_bill_line_2: "",
				buyer_tax_number: "",
				relationship: {},
			},
			shipping: {
				shipment_method: ''
			},
			edit_shipping: {
				title: false,
				type: false,
				selected_id: false,
			},
			sale_order_default_shipment: {remark: ''},
			edit_telephone: false,
			edit_email: false,
			edit_tax_no: false,
			edit_branch_id: false,
			is_committed: 0,
		};
		this.Header = React.createRef();
	}

	componentDidMount() {
		if (!Global2.getMyProfile()) {
			window.location = window.location.origin;
		}
		this.fetchCart();
		this.setup();
	}

	setup() {
		this.$parent.find('form').form2('setup');
		this.$parent.find('form').form2('submit', {
			method: 'PUT',
			url: '/api/v1/e-commerce/sale_order_via_e_commerce',
			beforeSent: (object) => {
				this.$parent.find('form').addClass('loading');
				delete object.shipment_method;
				// delete object.payment_type;
				delete object.is_tax_invoice;
				delete object.branch;
				// object.is_committed=1;
				// console.log(object);
				return object;
			},
			loadingButton: this.$parent.find('.button.approve'),
			onSuccess: (response) => {
				// console.log(response);
				if (response.sale_order_via_e_commerce.is_committed === 0) {
					this.$parent.find('form').removeClass('loading');
					this.setState({
						sale_order_via_e_commerce: response.sale_order_via_e_commerce,
					})
				}
				if (response.sale_order_via_e_commerce.is_committed === 1) {
					// response.sale_order_default_shipment.payment_by_type
					window.Global.messageTop({
						type: 'positive',
						message: 'บันทึกข้อมูลสำเร็จ'
					});
					let redirect = setTimeout(() => {
						clearTimeout(redirect);
						if (response.url) {
							window.location = response.url;
						}
						if (response.html) {
							window.$('form[name="payForm"]').remove();
							window.$('body').append(response.html);
						}
					}, 1000);

				}
			},
			onError: (error) => {
				this.$parent.find('form').removeClass('loading');
				if (this.state.is_committed === 1 && error.response.message) {
					window.Global.messageTop({
						type: 'negative',
						message: error.response.message[0].error_message,
						delay: 5000,
					});
				}
				this.setState({
					is_committed: 0
				});
			}
		});
	}

	fetchRelationshipAddress(shipment_relationship_address_id) {
		//console.log(shipment_relationship_address_id);
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/relationship_address',
			data: {
				source: 'billing',
			},
			done: (response) => {
				this.setState({
					relationship_address: response.relationship_address,
					is_loading: false,
				})
			}
		})
	}


	fetchCart() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/sale_order/cart',
			done: (response) => {
				let sale_order_via_e_commerce = response.sale_order_via_e_commerce ? {...response.sale_order_via_e_commerce} : false;
				let relationship_account = response.relationship_account ? {...response.relationship_account} : false;
				let shipment_method = response.shipment_method ? [...response.shipment_method] : [];
				let payment_by = response.payment_by ? [...response.payment_by] : [];
				// if (response.sale_order_via_e_commerce) {
				//     this.fetchRelationshipAddress(response.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address_id);
				// }
				this.fetchRelationshipAddress();
				this.setState({
					sale_order_via_e_commerce: sale_order_via_e_commerce,
					relationship_account: relationship_account,
					payment_by: payment_by,
					shipment_method: shipment_method,
					is_loading_cart: false
				})
			}
		});
	}


	subTotal() {
		let total = 0;
		if (this.state.sale_order_via_e_commerce && this.state.sale_order_via_e_commerce.sale_order) {
			total = this.state.sale_order_via_e_commerce.sale_order.summary.total_price_before_discount;
		}
		return total;
	}

	grandTotal() {
		let total = 0;
		// // eslint-disable-next-line array-callback-return
		// if (this.state.sale_order_via_e_commerce
		//     && this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length > 0) {
		//     this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item) => {
		//         if (parseFloat(sale_order_item.discount) > 0) {
		//             total = total + (parseFloat(sale_order_item.quantity) * parseFloat(sale_order_item.discount))
		//         } else {
		//             total = total + (parseFloat(sale_order_item.quantity) * parseFloat(sale_order_item.price))
		//         }
		//         return true;
		//     });
		// }
		if (this.state.sale_order_via_e_commerce && this.state.sale_order_via_e_commerce.sale_order) {
			total = this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price
		}
		return total;
	}

	removeItem(sale_order_items, event) {
		if (!sale_order_items) {
			return;
		}
		let data = {};
		data.sale_order_item = {};
		data.sale_order_item.sale_order_item_product_display_link = {};
		data.sale_order_item.sale_order_item_product_display_link.product_display_link_id = sale_order_items.sale_order_item_product_display_link.product_display_link_id;
		data.sale_order_item.sale_order_item_product_display_link._quantity = 0;
		// console.log(data);
		window.Global.ajax({
			method: 'POST',
			url: '/api/v1/sale_order/manage_item_cart',
			data: data,
			done: (response) => {
				let sale_order_via_e_commerce = response.sale_order_via_e_commerce;
				response.sale_order_via_e_commerce.sale_order.sale_order_item.forEach((sale_order_item, sale_order_item_index) => {
					sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_item_index].selected = true;
				});

				this.Header.current.getSaleOrderViaEcomerce(response.sale_order_via_e_commerce);
				this.setState({
					sale_order_via_e_commerce: sale_order_via_e_commerce
				}, () => {
					window.$(event).removeClass('loading');
					window.$(event).parent().find('button,input').attr('disabled', false);
					if (!this.state.sale_order_via_e_commerce
						|| (this.state.sale_order_via_e_commerce
							&& this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length === 0)) {
						window.location = '?p=cart';
					}
				});

			}
		});
	}

	triggerSubmitForm = () => {
		// this.$parent.find('form').find('.approve').trigger('click')
		this.$parent.find('form').trigger('submit');
	};

	setBuyerInSaleOrder(sale_order_via_e_commerce, branch) {
		// console.log(branch);
		sale_order_via_e_commerce.sale_order.relationship_branch_id = branch.id;
		let buyer_bill_line_1 = '';
		if (branch.address_street) {
			buyer_bill_line_1 = buyer_bill_line_1 + ' ' + branch.address_street;
		}
		if (branch.address_district) {
			buyer_bill_line_1 = buyer_bill_line_1 + ' ' + branch.address_district;
		}
		if (branch.address_city) {
			buyer_bill_line_1 = buyer_bill_line_1 + ' ' + branch.address_city;
		}
		if (branch.address_province) {
			buyer_bill_line_1 = buyer_bill_line_1 + ' ' + branch.address_province;
		}
		if (branch.address_zip_code) {
			buyer_bill_line_1 = buyer_bill_line_1 + ' ' + branch.address_zip_code;
		}
		sale_order_via_e_commerce.sale_order.buyer_bill_line_1 = buyer_bill_line_1 || '';
		sale_order_via_e_commerce.sale_order.buyer_bill_line_2 = '';
		sale_order_via_e_commerce.sale_order.buyer_branch_code = branch.code || '';
		sale_order_via_e_commerce.sale_order.buyer_branch_name = branch.name || '';
		sale_order_via_e_commerce.sale_order.buyer_company_name = sale_order_via_e_commerce.sale_order.relationship.name || '';
		sale_order_via_e_commerce.sale_order.buyer_tax_number = branch.tax_number || '';
		return sale_order_via_e_commerce;
	}

	render() {
		// console.log(this.state.sale_order_via_e_commerce);
		return (
			<>
				<Header hide_cart={true} {...this.props} ref={this.Header}/>
				<div className="body-content" ref={(ref) => this.$parent = window.$(ref)}>
					{/*BREADCRUMB*/}
					<div className="header-bg">
						<h1>ชำระเงิน
							<span>ตะกร้าสินค้า</span>
						</h1>
					</div>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<a className="section" href={window.location.origin + '/shop'}>รายการสินค้า</a>
								<i className="right chevron icon divider"/>
								<a className="section" href={window.location.origin + '/cart'}>ตะกร้าสินค้า</a>
								<i className="right chevron icon divider"/>
								<div className="active section">ชำระเงิน</div>
							</div>
						</div>
					</div>
					<div className="ui divider" style={{marginTop: 0}}/>
					<div className="cart-body">
						<div className="ui container">
							<div className="ui grid">
								<div className="sixteen wide mobile sixteen wide tablet eleven wide computer column">
									<div className={"cart checkout"}>
										<table className="ui very basic table">
											<thead>
											<tr>
												<th style={{paddingLeft: 12, paddingRight: 12}}>
													สินค้า
												</th>
												<th>ราคาต่อชิ้น</th>
												<th>จำนวน</th>
												<th>ราคารวม</th>
												<th/>
											</tr>
											</thead>
											<tbody>
											{
												this.state.is_loading_cart ?
													<tr>
														<td colSpan="5">
															<div className="flex center-xs">
																<div>
																	<div className="ui active mini inline loader"
																	     style={{marginRight: 10}}/>
																	กำลังโหลดข้อมูล
																</div>
															</div>
														</td>
													</tr>
													: null
											}
											{
												!this.state.is_loading_cart
												&& this.state.sale_order_via_e_commerce
												&& this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length === 0 ?
													<tr>
														<td colSpan="4">
															<div className="flex center-xs">
																<div>
																	<h2 className={"ui header"}>ไม่มีสินค้าในตะกร้า</h2>
																	<button className="ui button basic"
																	        onClick={(e) => {
																		        window.$(e).addClass('loading');
																		        window.location.href = window.location.origin + '/?p=shop'

																	        }}
																	>
																		<i className="cart alternate icon"/> เลือกสินค้า
																	</button>
																</div>
															</div>
														</td>
													</tr>
													: null
											}
											{
												this.state.sale_order_via_e_commerce ?
													this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, sale_order_items_index) => {
														return (
															<tr key={sale_order_items_index}
															    style={{position: 'relative'}}>
																<td>
																	<div className={"cart-pd"}>
																		{
																			sale_order_item.sale_order_item_product_display_link.product_display_link.product.product_image_file_upload ?
																				<div className="cart-image">
																					<img
																						src={sale_order_item.sale_order_item_product_display_link.product_display_link.product.product_image_file_upload.url}
																						alt={"Product"}/>
																				</div>
																				: null
																		}
																		<div className="ui tiny header"
																		     style={{margin: 0, width: '100%'}}>
																			<div>{sale_order_item.product.name}</div>
																			<div
																				className="sub header custom-sub-header">
																				{
																					CartFunction.showProductDisplayLinkDetail(sale_order_item.sale_order_item_product_display_link.product_display_link.product_display_link_detail)
																				}
																			</div>
																		</div>
																	</div>
																</td>
																<td>
																	{
																		parseFloat(sale_order_item.discount) !== 0
																			?
																			<div>
																				<div
																					className={"show-on-mobile"}>ราคาต่อชิ้น
																				</div>
																				{'฿ ' + sale_order_item.summary.price_after_discount.format(2, 3, {pretty: true}).toString()}
																				<div
																					className={"discount-line"}>{'฿ ' + sale_order_item.price.format(2, 3, {pretty: true}).toString()}</div>
																			</div>
																			:
																			<div>
																				<div
																					className={"show-on-mobile"}>ราคาต่อชิ้น
																				</div>
																				{'฿ ' + sale_order_item.price.format(2, 3, {pretty: true}).toString()}
																			</div>
																	}
																</td>
																<td>
																	<div
																		className={"show-on-mobile"}>จำนวน
																	</div>
																	{
																		Global2.getQuantity(sale_order_item)
																	}
																</td>
																<td>
																	<div
																		className={"show-on-mobile"}>ราคารวม
																	</div>
																	฿{sale_order_item.summary.total_net_price}
																</td>
																<td>
																	<button
																		className="circular ui icon button basic remove-item-button"
																		ref={(ref) => this['remove_button_' + sale_order_items_index] = ref}
																		onClick={(event) => {
																			window.$(this['remove_button_' + sale_order_items_index]).addClass('loading');
																			window.$(this['remove_button_' + sale_order_items_index]).attr('disabled', true);
																			window.Global.reactToDom(
																				window.$('body'),
																				<MessageConfirm
																					item_name={sale_order_item.product.name}
																					onApprove={() => {
																						this.removeItem(this.state.sale_order_via_e_commerce.sale_order.sale_order_item[sale_order_items_index], event);
																					}}
																					onDeny={() => {
																						window.$(this['remove_button_' + sale_order_items_index]).removeClass('loading');
																						window.$(this['remove_button_' + sale_order_items_index]).attr('disabled', false);
																					}}
																				/>
																			)
																		}}
																	>
																		<i className="icon trash alternate"/>
																	</button>
																</td>
															</tr>
														)
													})
													: null
											}
											</tbody>
										</table>
										<div className="ui divider" style={{marginTop: 0}}/>
									</div>
								</div>


								<div className="sixteen wide mobile sixteen wide tablet five wide computer column">
									<form ref={(ref) => this.form = ref}
									      className="ui segment form custom-form" id={"data-form"}
									>
										<h3 className={"ui header"}>ที่อยู่จัดส่ง:</h3>
										<input type={"hidden"}
										       name={"sale_order_default_shipment[shipment_relationship_address_id]"}
										       defaultValue={
											       this.state.sale_order_via_e_commerce.sale_order
											       && this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment
											       && this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment
											       && this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_method_type === "my_cloud_fulfillment_delivery"
											       && this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address ?
												       this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.id : ''
										       }/>
										<div className={"ui divider"}/>
										{
											this.state.shipment_method
											&& this.state.shipment_method.length > 0 ?
												<div className="grouped fields">
													{
														this.state.shipment_method.map((shipment_method, shipment_method_i) => {
															return (
																<div className="field" key={shipment_method_i}>
																	<div className="ui radio checkbox">
																		<input type="radio"
																		       id={shipment_method.type}
																		       name="sale_order_default_shipment[shipment_method_id]"
																		       checked={parseFloat(this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_method_id) === parseFloat(shipment_method.id)}
																		       value={shipment_method.id}
																		       onChange={(event) => {
																			       let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																			       sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_method_id = event.target.value;
																			       sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_method_type = shipment_method.type;
																			       // set default RelationshipAddress
																			       let dafault_address = this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address || false;
																			       if (!sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address
																				       && this.state.relationship_address
																				       && this.state.relationship_address.length > 0
																			       ) {
																				       this.state.relationship_address.forEach((relationship_address) => {
																					       if (relationship_address.is_default) {
																						       dafault_address = relationship_address;
																					       }
																				       });
																			       }
																			       sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address = dafault_address;
																			       this.setState({
																				       relationship_address: false,
																				       sale_order_via_e_commerce: sale_order_via_e_commerce,
																			       }, () => {
																				       this.triggerSubmitForm();
																			       })
																		       }}
																		/>
																		<label
																			htmlFor={shipment_method.type}>{shipment_method.name}</label>
																	</div>
																	{
																		this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_method_type === "my_cloud_fulfillment_delivery"
																		&& shipment_method.type === "my_cloud_fulfillment_delivery" ?
																			<>
																				<div
																					className={"flex flex-row middle-xs flex-wrap"}>
																					<div className={"flex-description"}
																					     style={{paddingRight: 5}}>
																						{
																							this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address
																							&& this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.name
																								?
																								<span>
																									 <b>ที่อยู่จัดส่ง</b>: {this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.name}
																								</span>
																								:
																								<span style={{color: 'red'}}>ยังไม่มีข้อมูลที่อยู่จัดส่ง โปรดระบุ</span>
																						}
																					</div>
																					<div className="right floated">
																						<button
																							type="button"
																							className="circular ui icon button brown mini"
																							onClick={() => {
																								window.Global.reactToDom(
																									window.$('body'),
																									<div>
																										<Modal className="ui modal"
																										       closable={false}
																										       onShowToSetupForm={($modal) => {

																										       }}
																											// onDeny={() => {
																											//    if (this.props.onDeny) {
																											//       this.props.onDeny();
																											//    }
																											// }}
																										>
																											<RelationshipAddress
																												mode={'list'}
																												is_can_select={true}
																												selected_id={this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address ? this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.id : 0}
																												onSelectedRelationshipAddress={(relationship_address) => {
																													let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																													sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address = relationship_address;
																													this.setState({
																														sale_order_via_e_commerce: sale_order_via_e_commerce,
																													}, () => {
																														this.triggerSubmitForm();
																													})
																												}}
																											/>
																										</Modal>
																									</div>
																								)
																							}}
																						>
																							<i className={"edit icon"}/>
																						</button>
																					</div>
																				</div>
																				{
																					this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address
																					&& this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.id
																						?
																						<div className={"flex"}>
																							<span className={"address-description"}>
	                                                                     {
		                                                                     this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.street
		                                                                     + ' ' + this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.district
		                                                                     + ' ' + this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.city
		                                                                     + ' ' + this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.province
		                                                                     + ' ' + this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.zip_code
	                                                                     }
	                                                                  </span>
																						</div>
																						: null
																				}
																				{
																					this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address
																					&& this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.phone_number
																						?
																						<div className={'space flex-description'}>
																							<b>โทร</b>: {this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.shipment_relationship_address.phone_number.replace('+66', '0')}
																						</div>
																						: null
																				}
																			</>
																			: null
																	}
																</div>
															);
														})
													}
												</div>
												: <p>ไม่มีข้อมูล</p>

										}
										<div className="ui divider"></div>
										<div className={"flex flex-row"}>
											<h3 className={"ui header"}>รายละเอียดใบเสร็จ:</h3>
											{
												this.state.sale_order_via_e_commerce.sale_order
												&& this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment
												&& false
												&&
												<div className={"right floated"}>
													<div className="ui checkbox"
													     ref={(ref) => {
														     window.$(ref).checkbox({
															     onChecked: () => {
																     window.Global.reactToDom(
																	     window.$('body'),
																	     <EditBilling
																		     sale_order_via_e_commerce={this.state.sale_order_via_e_commerce}
																		     data={{
																			     buyer_company_name: '',
																			     buyer_bill_line_1: '',
																			     buyer_bill_line_2: '',
																			     buyer_branch_name: '',
																			     buyer_branch_code: '',
																			     buyer_tax_number: '',
																			     is_issued_in_another_name: 1,
																			     is_company: 0,
																		     }}
																		     onChanged={(data) => {
																			     let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																			     sale_order_via_e_commerce.sale_order.buyer_company_name = data.buyer_company_name;
																			     sale_order_via_e_commerce.sale_order.buyer_bill_line_1 = data.buyer_bill_line_1;
																			     sale_order_via_e_commerce.sale_order.buyer_bill_line_2 = data.buyer_bill_line_2;
																			     sale_order_via_e_commerce.sale_order.buyer_branch_name = data.buyer_branch_name;
																			     sale_order_via_e_commerce.sale_order.buyer_branch_code = data.buyer_branch_code;
																			     sale_order_via_e_commerce.sale_order.buyer_tax_number = data.buyer_tax_number;
																			     sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name = 1;
																			     sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_company = data.is_company;
																			     this.setState({
																				     sale_order_via_e_commerce: sale_order_via_e_commerce,
																			     }, () => {
																				     this.triggerSubmitForm();
																			     })
																		     }}
																		     onDeny={() => {
																			     let _sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																			     _sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name = 0;
																			     this.setState({
																				     sale_order_via_e_commerce: _sale_order_via_e_commerce,
																			     }, () => {
																				     this.triggerSubmitForm();
																			     })
																		     }}
																	     />
																     )
															     },
															     onUnchecked: () => {
																     let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																     sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name = 0;
																     if (this.state.relationship_account.relationship.is_company) {
																	     sale_order_via_e_commerce = this.setBuyerInSaleOrder(sale_order_via_e_commerce, this.state.relationship_account.relationship.relationship_branch[0])
																     }
																     if (!this.state.relationship_account.relationship.is_company) {
																	     sale_order_via_e_commerce = this.setBuyerInSaleOrder(sale_order_via_e_commerce, this.state.relationship_account.relationship)
																	     sale_order_via_e_commerce.sale_order.buyer_branch_code = '';
																	     sale_order_via_e_commerce.sale_order.buyer_branch_code = '';

																	     sale_order_via_e_commerce.sale_order.relationship_branch_id = '';
																     }
																     this.setState({
																	     sale_order_via_e_commerce: sale_order_via_e_commerce,
																     }, () => {
																	     this.triggerSubmitForm();
																     })
															     }
														     });
													     }}
													>
														<input type="checkbox" value={1}
														       name={"sale_order_default_shipment[is_issued_in_another_name]"}
														       checked={this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name}
														       onChange={() => {
															       return true;
														       }}
														/>
														<input type="hidden"
														       name={"sale_order_default_shipment[is_company]"}
														       value={this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_company}
														/>
														<label>ออกที่อยู่ใบเสร็จในนามอื่น</label>
													</div>
												</div>
											}
										</div>
										{
											this.state.sale_order_via_e_commerce
												?
												<>
													<input type={"hidden"}
													       name={"sale_order[buyer_company_name]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.buyer_company_name}/>
													<input type={"hidden"}
													       name={"sale_order[buyer_branch_name]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.buyer_branch_name}/>
													<input type={"hidden"}
													       name={"sale_order[buyer_branch_code]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.buyer_branch_code}/>
													<input type={"hidden"}
													       name={"sale_order[buyer_bill_line_1]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_1}/>
													<input type={"hidden"}
													       name={"sale_order[buyer_bill_line_2]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_2}/>
													<input type={"hidden"}
													       name={"sale_order[buyer_tax_number]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.buyer_tax_number}/>
													<input type={"hidden"}
													       name={"sale_order[relationship_branch_id]"}
													       defaultValue={this.state.sale_order_via_e_commerce.sale_order.relationship_branch_id}/>
													<input type={"hidden"}
													       name={"sale_order_default_shipment[is_tax_invoice]"}
													       value={this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_tax_invoice}/>
												</>
												: null
										}
										{/*{*/}
										{/*	this.state.relationship_account*/}
										{/*	&& this.state.sale_order_via_e_commerce.sale_order*/}
										{/*	&& !this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name*/}
										{/*	&& this.state.relationship_account.relationship.is_company ?*/}
										{/*		<div className="grouped fields">*/}
										{/*			{*/}
										{/*				this.state.relationship_account.relationship.relationship_branch.map((branch, branch_i) => {*/}
										{/*					return (*/}
										{/*						<div className="field" key={branch_i}>*/}
										{/*							<div className="ui radio checkbox">*/}
										{/*								<input type="radio"*/}
										{/*								       name={'branch'}*/}
										{/*								       id={'branch_id_' + branch.id}*/}
										{/*								       checked={this.state.sale_order_via_e_commerce.sale_order.relationship_branch_id ? parseFloat(this.state.sale_order_via_e_commerce.sale_order.relationship_branch_id) === branch.id : false}*/}
										{/*								       value={branch.id}*/}
										{/*								       onChange={(event) => {*/}
										{/*									       // console.log(event.target.value);*/}
										{/*									       let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};*/}
										{/*									       sale_order_via_e_commerce = this.setBuyerInSaleOrder(sale_order_via_e_commerce, branch);*/}
										{/*									       this.setState({*/}
										{/*										       sale_order_via_e_commerce: sale_order_via_e_commerce,*/}
										{/*									       }, () => {*/}
										{/*										       this.triggerSubmitForm();*/}
										{/*									       })*/}
										{/*								       }}*/}
										{/*								/>*/}
										{/*								<label*/}
										{/*									htmlFor={'branch_id_' + branch.id}>{branch.name}</label>*/}
										{/*							</div>*/}
										{/*						</div>*/}
										{/*					);*/}
										{/*				})*/}
										{/*			}*/}
										{/*		</div>*/}
										{/*		: null*/}
										{/*}*/}
										<div className={"flex flex-row middle-xs"} style={{marginTop: '1rem'}}>
                                   <span className={"flex-description"}>
                                       <span className={"icon-box"}>
                                          <i className="book icon"/>
                                       </span>
                                       <div style={{
	                                       display: 'inline-block',
	                                       marginRight: 5
                                       }}>
                                           <b>ชื่อ</b>: {
	                                       this.state.sale_order_via_e_commerce
	                                       && this.state.sale_order_via_e_commerce.sale_order.buyer_company_name
		                                       ?
		                                       <span><b>{this.state.sale_order_via_e_commerce.sale_order.buyer_company_name}</b></span>
		                                       : <span style={{color: 'red'}}>โปรดระบุ</span>
                                       }
                                       </div>
	                                   {
		                                   this.state.sale_order_via_e_commerce
		                                   && (this.state.sale_order_via_e_commerce.sale_order.buyer_branch_name
			                                   || this.state.sale_order_via_e_commerce.sale_order.buyer_branch_code
		                                   )
			                                   ?
			                                   <div style={{display: 'inline-block'}}>
				                                   (สาขา {
				                                   this.state.sale_order_via_e_commerce.sale_order.buyer_branch_name
					                                   ?
					                                   <span>{this.state.sale_order_via_e_commerce.sale_order.buyer_branch_name} </span>
					                                   : null
			                                   }
				                                   {
					                                   this.state.sale_order_via_e_commerce.sale_order.buyer_branch_code
						                                   ?
						                                   <span>{this.state.sale_order_via_e_commerce.sale_order.buyer_branch_code}</span>
						                                   : null
				                                   }
				                                   )
			                                   </div>
			                                   : null
	                                   }
                                   </span>
											<div className="right floated">
												<button
													type="button"
													className="circular ui icon button brown mini"
													onClick={() => {

														window.Global.reactToDom(
															window.$('body'),
															<EditBillingManageMyAccount
																is_issued_in_another_name={true}
																relationship_account={this.state.relationship_account}
																onSuccess={(response, form_data) => {
																	// console.log(response);
																	let relationship_address = response.relationship_account.relationship.relationship_address[0];

																	let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																	sale_order_via_e_commerce.sale_order.buyer_company_name = relationship_address.name;
																	sale_order_via_e_commerce.sale_order.buyer_bill_line_1 = relationship_address.bill_line_1;
																	sale_order_via_e_commerce.sale_order.buyer_bill_line_2 = relationship_address.bill_line_2;
																	sale_order_via_e_commerce.sale_order.buyer_branch_name = form_data.branch_name;
																	sale_order_via_e_commerce.sale_order.buyer_branch_code = form_data.branch_code;
																	sale_order_via_e_commerce.sale_order.buyer_tax_number = form_data.tax_id;

																	if (!sale_order_via_e_commerce.sale_order.phone
																		|| sale_order_via_e_commerce.sale_order.phone.length === 0
																	) {
																		sale_order_via_e_commerce.sale_order.phone = [relationship_address.phone_number]
																	}

																	this.setState({
																		sale_order_via_e_commerce: sale_order_via_e_commerce,
																	}, () => {
																		this.triggerSubmitForm();
																	})
																}}
															/>
														)
														// window.Global.reactToDom(
														// 	window.$('body'),
														// 	<EditBilling
														// 		sale_order_via_e_commerce={this.state.sale_order_via_e_commerce}
														// 		is_edit={true}
														// 		data={{
														// 			buyer_company_name: this.state.sale_order_via_e_commerce.sale_order.buyer_company_name,
														// 			buyer_bill_line_1: this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_1,
														// 			buyer_bill_line_2: this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_2,
														// 			buyer_branch_name: this.state.sale_order_via_e_commerce.sale_order.buyer_branch_name,
														// 			buyer_branch_code: this.state.sale_order_via_e_commerce.sale_order.buyer_branch_code,
														// 			buyer_tax_number: this.state.sale_order_via_e_commerce.sale_order.buyer_tax_number,
														// 			is_issued_in_another_name: this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name,
														// 			is_company: this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_issued_in_another_name ? this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_company : this.state.relationship_account.relationship.is_company,
														// 		}}
														// 		onChanged={(data) => {
														// 			let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
														// 			sale_order_via_e_commerce.sale_order.buyer_company_name = data.buyer_company_name;
														// 			sale_order_via_e_commerce.sale_order.buyer_bill_line_1 = data.buyer_bill_line_1;
														// 			sale_order_via_e_commerce.sale_order.buyer_bill_line_2 = data.buyer_bill_line_2;
														// 			sale_order_via_e_commerce.sale_order.buyer_branch_name = data.buyer_branch_name;
														// 			sale_order_via_e_commerce.sale_order.buyer_branch_code = data.buyer_branch_code;
														// 			sale_order_via_e_commerce.sale_order.buyer_tax_number = data.buyer_tax_number;
														// 			sale_order_via_e_commerce.sale_order.sale_order_default_shipment.is_company = data.is_company;
														// 			this.setState({
														// 				sale_order_via_e_commerce: sale_order_via_e_commerce,
														// 			}, () => {
														// 				this.triggerSubmitForm();
														// 			})
														// 		}}
														// 	/>
														// )
													}}
												>
													<i className={"edit icon"}/>
												</button>
											</div>
										</div>
										<div className={"flex space"}>
											{
												this.state.sale_order_via_e_commerce
													?
													<span className={"address-description"}>
														<div>
                                            <b>ที่อยู่ใบเสร็จ</b>: {
															this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_1
																? (
																	this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_1
																	+ (
																		this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_2
																			?
																			<>
																				<br/>{this.state.sale_order_via_e_commerce.sale_order.buyer_bill_line_2}
																			</>
																			: ''
																	)
																)
																: <span style={{color: 'red'}}>โปรดระบุ</span>
														}
                                         </div>
														<div>
															<b>เลขประจำตัวผู้เสียภาษี</b>: {
															this.state.sale_order_via_e_commerce.sale_order.buyer_tax_number
																?
																this.state.sale_order_via_e_commerce.sale_order.buyer_tax_number
																: <span style={{color: 'red'}}>โปรดระบุ</span>
														}
														</div>
                                       </span>
													: null
											}
										</div>
										<div className={"ui divider"}/>

										{
											this.state.edit_telephone ?
												<div className="field">
													<div className="ui action input tiny">
														<input type="text" placeholder="กรอกเบอร์โทรศัพท์"
														       defaultValue={
															       this.state.sale_order_via_e_commerce.sale_order
															       && this.state.sale_order_via_e_commerce.sale_order.phone
															       && this.state.sale_order_via_e_commerce.sale_order.phone.length > 0 ?
																       this.state.sale_order_via_e_commerce.sale_order.phone[0]
																       : ''
														       }
														       ref={(ref) => {
															       this.phone = ref
														       }}
														       onBlur={() => {
															       let sale_order_via_e_commerce = this.state.sale_order_via_e_commerce;
															       sale_order_via_e_commerce.sale_order.phone[0] = window.$(this.phone).val();
															       this.setState({
																       sale_order_via_e_commerce: sale_order_via_e_commerce,
																       edit_telephone: false,
															       }, () => {
																       this.triggerSubmitForm();
															       })
														       }}
														       onKeyUp={(event) => {
															       if (event.key === 'Enter') {
																       let sale_order_via_e_commerce = this.state.sale_order_via_e_commerce;
																       sale_order_via_e_commerce.sale_order.phone[0] = window.$(this.phone).val();
																       this.setState({
																	       sale_order_via_e_commerce: sale_order_via_e_commerce,
																	       edit_telephone: false,
																       }, () => {
																	       this.triggerSubmitForm();
																       })
															       }
														       }}
														/>
														<button className="ui button tiny" type={"button"}
														        onClick={() => {
															        let sale_order_via_e_commerce = this.state.sale_order_via_e_commerce;
															        sale_order_via_e_commerce.sale_order.phone[0] = window.$(this.phone).val();
															        this.setState({
																        sale_order_via_e_commerce: sale_order_via_e_commerce,
																        edit_telephone: false,
															        }, () => {
																        this.triggerSubmitForm();
															        })
														        }}
														>
															บันทึก
														</button>
													</div>
												</div>
												:
												<div className={"flex flex-row middle-xs space"}>
													<div className={"flex-description"}>
                                                        <span className={"icon-box"}>
                                                            <i className="phone icon"/>
                                                        </span>
														{
															this.state.sale_order_via_e_commerce.sale_order
															&& this.state.sale_order_via_e_commerce.sale_order.phone
															&& this.state.sale_order_via_e_commerce.sale_order.phone.length > 0 ?
																this.state.sale_order_via_e_commerce.sale_order.phone[0].replace('+66', '0')
																: 'ระบุเบอร์โทรศัพท์'
														}
														<input type={"hidden"} name={"sale_order[phone]"}
														       value={
															       this.state.sale_order_via_e_commerce.sale_order
															       && this.state.sale_order_via_e_commerce.sale_order.phone
															       && this.state.sale_order_via_e_commerce.sale_order.phone.length > 0 ?
																       this.state.sale_order_via_e_commerce.sale_order.phone[0].replace('+66', '0')
																       : ''
														       }
														/>
													</div>
													<div className="right floated">
														<button
															type="button"
															className="circular ui icon button brown mini"
															onClick={() => {
																this.setState({
																	edit_telephone: true,
																}, () => {
																	window.$(this.phone).focus()
																})
															}}
														>
															<i className={"edit icon"}/>
														</button>
													</div>
												</div>
										}

										{
											this.state.edit_email ?
												<div className="field">
													<div className="ui action input tiny">
														<input type="text" placeholder="กรอกอีเมล์"
														       defaultValue={this.state.sale_order_via_e_commerce.sale_order.email[0]}
														       ref={(ref) => {
															       this.email = ref
														       }}
														       onBlur={() => {
															       let sale_order_via_e_commerce = this.state.sale_order_via_e_commerce;
															       sale_order_via_e_commerce.sale_order.email[0] = window.$(this.email).val();
															       this.setState({
																       sale_order_via_e_commerce: sale_order_via_e_commerce,
																       edit_email: false,
															       }, () => {
																       this.triggerSubmitForm();
															       })
														       }}
														       onKeyUp={(event) => {
															       if (event.key === 'Enter') {
																       let sale_order_via_e_commerce = this.state.sale_order_via_e_commerce;
																       sale_order_via_e_commerce.sale_order.email[0] = window.$(this.email).val();
																       this.setState({
																	       sale_order_via_e_commerce: sale_order_via_e_commerce,
																	       edit_email: false,
																       }, () => {
																	       this.triggerSubmitForm();
																       })
															       }
														       }}
														/>
														<button className="ui button tiny" type={"button"}
														        onClick={() => {
															        let sale_order_via_e_commerce = this.state.sale_order_via_e_commerce;
															        sale_order_via_e_commerce.sale_order.email[0] = window.$(this.email).val();
															        this.setState({
																        sale_order_via_e_commerce: sale_order_via_e_commerce,
																        edit_email: false,
															        }, () => {
																        this.triggerSubmitForm();
															        })
														        }}
														>
															บันทึก
														</button>
													</div>
												</div>
												:
												<div className={"flex flex-row middle-xs space"}>
													<div className={"flex-description"}>
                                                        <span className={"icon-box"}>
                                                            <i className="envelope icon"/>
                                                        </span>
														{
															this.state.sale_order_via_e_commerce.sale_order
															&& this.state.sale_order_via_e_commerce.sale_order.email
															&& this.state.sale_order_via_e_commerce.sale_order.email.length > 0 ?
																this.state.sale_order_via_e_commerce.sale_order.email[0]
																: 'ระบุอีเมล์'
														}
														<input type={"hidden"} name={"sale_order[email]"}
														       defaultValue={
															       this.state.sale_order_via_e_commerce.sale_order
															       && this.state.sale_order_via_e_commerce.sale_order.email
															       && this.state.sale_order_via_e_commerce.sale_order.email.length > 0 ?
																       this.state.sale_order_via_e_commerce.sale_order.email[0]
																       : ''
														       }
														/>
													</div>
													<div className="right floated">
														<button
															type="button"
															className="circular ui icon button brown mini"
															onClick={() => {
																this.setState({
																	edit_email: true
																}, () => {
																	window.$(this.email).focus()
																})
															}}
														>
															<i className={"edit icon"}/>
														</button>
													</div>
												</div>
										}

										{
											this.state.edit_remark ?
												<>
													<div className="field">
														<div className="ui action input tiny">
															<input type="text" placeholder="กรอกหมายเหตุ"
															       defaultValue={
																       this.state.sale_order_via_e_commerce.sale_order ?
																	       this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark
																	       : ''
															       }
															       ref={(ref) => {
																       this.remark = ref
															       }}
															       onBlur={() => {
																       let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																       sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark = window.$(this.remark).val();
																       this.setState({
																	       sale_order_via_e_commerce: sale_order_via_e_commerce,
																	       edit_remark: false,
																       }, () => {
																	       this.triggerSubmitForm();
																       })
															       }}
															       onKeyUp={(event) => {
																       if (event.key === 'Enter') {
																	       let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																	       sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark = window.$(this.remark).val();
																	       this.setState({
																		       sale_order_via_e_commerce: sale_order_via_e_commerce,
																		       edit_remark: false,
																	       }, () => {
																		       this.triggerSubmitForm();
																	       })
																       }
															       }}
															/>
															<button className="ui button tiny" type={"button"}
															        onClick={() => {
																        let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																        sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark = window.$(this.remark).val();
																        this.setState({
																	        sale_order_via_e_commerce: sale_order_via_e_commerce,
																	        edit_remark: false,
																        }, () => {
																	        this.triggerSubmitForm();
																        })
															        }}
															>
																บันทึก
															</button>
														</div>
													</div>
												</>
												:
												<div className={"flex flex-row middle-xs space"}>
													<div className={"flex-description"}>
                                                        <span className={"icon-box"}>
                                                            <i className="hashtag icon"/>
                                                        </span>
														หมายเหตุ
														<div>
															{
																this.state.sale_order_via_e_commerce.sale_order ?
																	this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark
																	: null
															}
														</div>
														<input type={"hidden"}
														       name={"sale_order_default_shipment[remark]"}
														       defaultValue={
															       this.state.sale_order_via_e_commerce.sale_order ?
																       this.state.sale_order_via_e_commerce.sale_order.sale_order_default_shipment.remark
																       : ''
														       }/>
													</div>
													<div className="right floated">
														<button
															type="button"
															className="circular ui icon button brown mini"
															onClick={() => {
																this.setState({
																	edit_remark: true
																})
															}}
														>
															<i className={"edit icon"}/>
														</button>
													</div>
												</div>
										}
										{/*<div className="field">*/}
										{/*    <div id="Upload_file"*/}
										{/*         data-form2_setup="true"*/}
										{/*         data-form2_type="upload"*/}
										{/*         data-single_file_upload="true"*/}
										{/*         data-input_name="filename"*/}
										{/*    />*/}
										{/*</div>*/}
										<div className={"ui divider"}/>
										<h3 className={"ui header"} style={{marginTop: 0}}>วิธีการชำระเงิน
											:</h3>
										<div className="grouped fields">
											{
												this.state.payment_by
												&& this.state.payment_by.length > 0 ?
													this.state.payment_by.map((payment_by, payment_by_index) => {
														return (
															<div className="field" key={payment_by_index}>
																<div className="ui radio checkbox">
																	<input type="radio"
																	       id={"payment_by_" + payment_by.type}
																	       name="sale_order_default_shipment[payment_by_id]"
																	       checked={payment_by.is_selected}
																	       value={payment_by.id}
																	       onChange={() => {
																		       let sale_order_via_e_commerce = {...this.state.sale_order_via_e_commerce};
																		       sale_order_via_e_commerce.sale_order.sale_order_default_shipment.payment_by_type = payment_by.type;
																		       let _payment_by = [...this.state.payment_by];
																		       this.state.payment_by.forEach((payment_b, index) => {
																			       if (payment_b.is_selected === 1) {
																				       _payment_by[index].is_selected = 0
																			       }
																		       });
																		       _payment_by[payment_by_index].is_selected = 1;
																		       this.setState({
																			       sale_order_via_e_commerce: sale_order_via_e_commerce,
																			       payment_by: _payment_by
																		       }, () => {
																			       this.triggerSubmitForm();
																		       })
																	       }}
																	/>
																	<label
																		htmlFor={"payment_by_" + payment_by.type}>
																		{payment_by.name}
																	</label>
																</div>
															</div>
														);
													}) : null
											}
											{/*<input type={"hidden"} name="sale_order_default_shipment[payment_by_type]" value={this.state.payment_type}/>*/}
										</div>
										<div className="ui divider"/>
										<div className={"cart-summary"}>
											<div className="content">
												<div className="ui action input">
													<input type="text" placeholder="รหัสส่วนลด" name={"discount_code"}
													       disabled={true}/>
													<button className="ui button icon"
													        type={"button"}
													        onClick={() => {
														        // console.log("Clicked");
													        }}
													        disabled={true}
													>
														<i className={"icon arrow right"}/>
													</button>
												</div>
											</div>
										</div>
										<div className={"ui divider"}/>
										<h3 className={"ui header"} style={{marginTop: 0}}>สรุปการสั่งซื้อ
											:</h3>
										<div className={"cart-total"}
										     style={{marginBottom: 15}}
										>
											{
												this.state.sale_order_via_e_commerce
												&& this.state.sale_order_via_e_commerce.sale_order
												&& this.state.sale_order_via_e_commerce.sale_order.summary ?
													<>
														<div className={"flex flex-row"}>
															<div>ราคารวม :</div>
															<div className={"right floated"}>
																<div>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_price_exclude_vat.format(2, 3, {pretty: false}).toString()} </div>
															</div>
														</div>
														<div className={"flex flex-row"}>
															<div>ภาษีมูลค่าเพิ่ม :</div>
															<div className={"right floated"}>
																<div>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_tax_price.format(2, 3, {pretty: false}).toString()} </div>
															</div>
														</div>
													</>
													: null
											}

											{/*<div className={"flex flex-row"}>*/}
											{/*    <div>ค่าขนส่ง :</div>*/}
											{/*    <div className={"right floated"}>*/}
											{/*        <div>฿{(45).format(2, 3, {pretty: false}).toString()} </div>*/}
											{/*    </div>*/}
											{/*</div>*/}
										</div>
										{
											this.state.sale_order_via_e_commerce
											&& this.state.sale_order_via_e_commerce.sale_order
											&& this.state.sale_order_via_e_commerce.sale_order.summary ?
												<>
													<div className="ui divider"/>
													<div className={"flex flex-row cart-total-price cart-total"}
													     style={{marginBottom: 15}}
													>
														<h3>รวมทั้งสิ้น :</h3>
														<div className={"right floated"}>
															<h3>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price.format(2, 3, {pretty: false}).toString()} </h3>
														</div>
													</div>
												</>
												: null
										}

										<input type={"hidden"} name={"is_committed"} value={this.state.is_committed}/>
										{/*<div className="ui message info">เว็บไซต์ปิดปรับปรุง 26 ธันวาคม 2564 ถึง 9 มกราคม 2565</div>*/}
										<button className="ui button brown fluid approve"
										        type={"button"}
										        onClick={() => {
											        if (this.props.is_close_website) {
												        return;
											        }
											        this.setState({
												        is_committed: 1,
											        }, () => {
												        this.triggerSubmitForm();
											        });
										        }}
										        disabled={this.state.sale_order_via_e_commerce && this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length === 0}
										>
											ทำการสั่งซื้อ
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.message_form}
				<Footer/>
			</>
		);
	}
}
