import React from 'react';
// import FilterList from './../component/FilterList';
// import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

export default class Filter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			filter: [],
		}
	}
	componentDidMount() {
		this.fetchTabs();
	}

	fetchTabs() {
		let data = {};
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/filter',
			data: data,
			done: (response) => {
				this.setState({
					filter: response.filter
				})
			}
		});
	}

	render() {
		return (
			<>
				<div className="filter">
					<div className="flex flex-row filter-title middle-xs">
						<div>ตัวกรอง :</div>
						<div className="right floated">
							<button className="ui button basic mini"
							        onClick={() => {
								        window.location.href = window.location.origin + '/shop?search_query=';
							        }}
							>
								<i className={"redo icon"}/>
								ล้าง
							</button>
						</div>
					</div>
					<div className="ui divider"/>
					<div className="ui list">
						{
							this.state.filter.map((filter, i) => {
								// console.log(this.state.qs.filter_id === category.filter_id);
								return (
									<div key={i} className="item">
										<div className="flex flex-row middle-xs">
											<label htmlFor={filter.name}>
												<div className="ui checkbox"
												     onClick={() => {
													     if (parseFloat(this.state.qs.filter_id) === parseFloat(filter.id)) {
														     window.location = '/shop?filter_id=';
														     return;
													     }
													     window.location = '/shop?filter_id=' + filter.id
												     }}
												>
													<input type="checkbox" name={"category"}
													       id={filter.name}
													       defaultChecked={parseFloat(this.state.qs.filter_id) === parseFloat(filter.id)}
													/>
													<label>{filter.name}</label>
												</div>
											</label>
											{
												filter.count !== undefined ?
													<div className="right floated">
														<div
															className="ui label">{filter.count}</div>
													</div>
													: null
											}
										</div>
									</div>
								);
							})
						}
					</div>
					{/*<div className="ui divider"/>*/}
					{/*<FilterList data={this.state.size}/>*/}
					{/*<div className="ui divider"/>*/}
					{/*<FilterList data={this.state.colour} theme={"color"}/>*/}
					{/*<div className="ui divider"/>*/}
					{/*<FilterList data={this.state.brand}/>*/}
					<div className="ui divider"/>
					{/*<div className="filter-list"*/}
					{/*ref={(ref) => {*/}
					{/*this.category_filter = ref*/}
					{/*}}*/}
					{/*>*/}
					{/*<div className="flex flex-row filter-title"*/}
					{/*onClick={() => {*/}
					{/*window.$(this.category_filter).toggleClass("hide-filter-content").find('.filter-content').slideToggle()*/}
					{/*}}*/}
					{/*>*/}
					{/*<h3>Price :</h3>*/}
					{/*<div className="right floated">*/}
					{/*<i className="caret down icon"/>*/}
					{/*<i className="caret left icon"/>*/}
					{/*</div>*/}
					{/*</div>*/}
					{/*<div className="filter-content">*/}
					{/*<div style={{marginBottom: 15}}>Price Range : <span*/}
					{/*className={"cart-total-price"}>{this.state.price.min} ฿ - {this.state.price.max} ฿</span>*/}
					{/*</div>*/}
					{/*<div style={{paddingLeft: 5, paddingRight: 5}}>*/}
					{/*<InputRange*/}
					{/*maxValue={1000}*/}
					{/*minValue={0}*/}
					{/*value={this.state.price}*/}
					{/*onChange={(value) => {*/}
					{/*this.setState({*/}
					{/*price: value*/}
					{/*})*/}
					{/*}}*/}
					{/*formatLabel={(e) => {*/}
					{/*return (*/}
					{/*<div className={"slider-font"}>{e + " ฿"}</div>*/}
					{/*)*/}
					{/*}}*/}
					{/*/>*/}
					{/*</div>*/}
					{/*</div>*/}
					{/*</div>*/}
				</div>
			</>
		);
	}
}