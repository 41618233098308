import React from 'react';
import Header from "../include/Header";
import Footer from "../include/Footer";
import ManageMenu from "../component/ManageMenu";
import {Global2} from "../component/Global";

window.moment.locale('th');
export default class ManageMyAccount extends React.Component {
	constructor() {
		super();
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			count: 0,
			sale_orders: [],
			is_loading_list: true
		}
	}

	componentDidMount() {
		if (!Global2.getMyProfile()) {
			window.location = window.location.origin;
		}
		this.fetch();
	}

	fetch() {
		let data = {...this.state.qs};
		data.status = this.state.qs.status || 'all';
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/sale_order_via_e_commerce',
			data: data,
			done: (response) => {
				// console.log(response);
				let sale_order_via_e_commerce = response.sale_order_via_e_commerce;
				window.$(this.$parent).find('#sale-order-displays').tablePagination({
					disableContent: 1,
					maxSize: window.Global.getAllQueryStrings().limit || 10,
					data: sale_order_via_e_commerce ? sale_order_via_e_commerce.length : 0,
					count: response.count,
				});

				this.setState({
					count: response.count,
					sale_orders: sale_order_via_e_commerce,
					is_loading_list: false,
				});
			}
		});
	}

	render() {
		return (
			<>
				<Header hide_cart={false} {...this.props}/>
				<div className="body-content" ref={(ref) => this.$parent = ref}>
					{/*BREADCRUMB*/}
					{/*<div className="header-bg computer-only">*/}
					{/*<h1>การซื้อของฉัน</h1>*/}
					{/*</div>*/}
					<div className="ui divider" style={{marginTop: 0, marginBottom: 0}}/>
					<div className="container ui">
						<div className={"pd-breadcrumb"}>
							<div className="ui breadcrumb">
								<a className="section" href={window.location.origin}>หน้าแรก</a>
								<i className="right chevron icon divider"/>
								<div className="active section">การซื้อของฉัน</div>
							</div>
						</div>
					</div>
					<div className="ui container">
						<div className="ui grid">
							<div className="row">
								<div className="sixteen wide mobile four wide tablet three wide computer column">
									<ManageMenu/>
								</div>
								<div className="sixteen wide mobile twelve wide tablet thirteen wide computer column">
									<div className="my-account">
										<div className="ui top attached six item menu pointing">
											<a className={this.state.qs.status === 'all' ? "active item" : "item"}
											   href={window.location.origin + '/my-purchase?status=all'}>ทั้งหมด</a>
											<a className={this.state.qs.status === 'waiting_for_payment' ? "active item" : "item"}
											   href={window.location.origin + '/my-purchase?status=waiting_for_payment'}>ที่ต้องชำระ</a>
											{/*<a className={this.state.qs.status === 'waiting_for_shipment' ? "active item" : "item"}*/}
											{/*   href={window.location.origin + '/my-purchase?status=waiting_for_shipment'}>ที่ต้องจัดส่ง</a>*/}
											<a className={this.state.qs.status === 'waiting_for_receive' ? "active item" : "item"}
											   href={window.location.origin + '/my-purchase?status=waiting_for_receive'}>ที่ต้องได้รับ</a>
											<a className={this.state.qs.status === 'received' ? "active item" : "item"}
											   href={window.location.origin + '/my-purchase?status=received'}>สำเร็จแล้ว</a>
											<a className={this.state.qs.status === 'order_canceled' ? "active item" : "item"}
											   href={window.location.origin + '/my-purchase?status=order_canceled'}>ยกเลิกแล้ว</a>
										</div>
										<div className="ui bottom attached segment">
											<h3 className="ui header dividing" style={{marginTop: 0, marginBottom: 0}}>
												รายการสั่งซื้อล่าสุด
											</h3>
											<div className={"cart"}>
												<div className="ui basic table can click Table_root"
												     style={{marginTop: 0, borderWidth: 0}}
												     id="sale-order-displays">
													<div className="thead" style={{borderBottom: '1px solid #ccc'}}>
														<div className="tr">
															<div className="th span4">
																คำสั่งซื้อ #
															</div>
															<div className="th span4 center-xs">
																วันที่
															</div>
															<div className="th span4 center-xs">
																ภาพสินค้า
															</div>
															<div className="th span4" style={{alignItems: 'flex-end'}}>
																รวมทั้งสิ้น
															</div>
														</div>
													</div>
													<div className="tbody">
														{
															this.state.is_loading_list ?
																<div className="tr">
																	<div className="td center aligned"
																	     data-info="nodata">
																		<div
																			className="ui active centered inline small loader"/>
																		กำลังโหลดข้อมูล
																	</div>
																</div>
																: null
														}

														{
															this.state.sale_orders
															&& !this.state.is_loading_list
															&& this.state.sale_orders.length > 0 ?
																this.state.sale_orders.map((sale_order, index) => {
																	return (
																		<div className='tr'
																		     key={index}
																		     onClick={() => {
																			     window.location = '/order-detail?sale_order_via_e_commerce_id=' + sale_order.id;
																		     }}
																		     style={{
																			     cursor: 'pointer',
																			     borderBottom: '1px solid #ccc'
																		     }}
																		>
																			<div className='td span4'>
																				<div>
																					{sale_order.sale_order.desc}
																				</div>
																			</div>
																			<div className='td span4 center-xs'>
																				<div>
																					{window.Global.getDateThai(sale_order.sale_order.doc_date) || null}
																				</div>
																			</div>
																			<div className='td span4 center-xs'>
																				<div>
																					<div className="cart-image">
																						<img
																							src={sale_order.sale_order.sale_order_item[0].product.product_image_file_upload.url}
																							alt={"Product"}/>
																					</div>
																				</div>
																			</div>
																			<div className='td span4'
																			     style={{alignItems: 'flex-end'}}>
																				<div>
																					฿{sale_order.sale_order.summary.total_net_price.format(2, 3, {pretty: false}).toString()}
																				</div>
																			</div>
																		</div>
																	);
																})
																: null
														}
														{
															this.state.sale_orders
															&& !this.state.is_loading_list
															&& this.state.sale_orders.length === 0 ?
																<div className='tr'>
																	<div className='td span16 center-xs'>
																		<div>
																			ไม่มีรายการ
																		</div>
																	</div>
																</div>
																: null
														}
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<Footer/>
			</>
		);
	}
}